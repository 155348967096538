import { v4 as uuidv4 } from 'uuid';
import {
    apptBillingEnums,
    apptEnums,
    scheduleBillingStatuses,
    scheduleStatuses,
} from '../../../../fragments/schedule/core/constants';

export const scheduleReport = () => {

    return {
        title: 'Appointment Detail Report',
        url: '/reports/appt/detail',
        modalType: 'appt',
        list:
            [
                {
                    type: 'date',
                    name: 'startDate',
                    label: 'Auth. Start Date ',
                },
                {
                    type: 'select',
                    name: 'status',
                    label: 'Appt. Status',
                    selectList: [
                        { id: apptEnums?.COMPLETED, name: 'Completed' },
                        { id: apptEnums?.PENDING, name: 'Pending' },
                        { id: apptEnums?.CANCELLED, name: 'Cancelled' },
                    ],
                    renderValue: (option) => option?.name,
                },
                {
                    type: 'select',
                    name: 'billStatus',
                    label: 'Billing Status',
                    selectList: [
                        { id: apptBillingEnums?.BILLED, name: 'Billed' },
                        { id: apptBillingEnums.NOT_BILLED, name: 'Not Billed' },
                    ],
                    renderValue: (option) => option?.name,
                },
                {
                    type: 'select',
                    name: 'staff',
                    label: 'Staff',
                    loadType: 'GET_ADMINS',
                    renderValue: (option) => `${option?.lastName} ${option?.firstName}`,
                },
                {
                    type: 'select',
                    name: 'client',
                    label: 'Client',
                    loadType: 'GET_CLIENTS',
                    renderValue: (option) => `${option?.lastName} ${option?.firstName}`,
                },
                {
                    type: 'select',
                    name: 'fundingSource',
                    label: 'Payer',
                    loadType: 'GET_FUNDING_SOURCE',
                    renderValue: (option) => option?.name,
                },
                {
                    type: 'select',
                    name: 'serviceType',
                    label: 'Service Type',
                    selectList: [
                        { name: 'Direct', id: 'DIRECT' },
                        { name: 'Indirect', id: 'INDIRECT' },
                    ],
                    renderValue: (option) => option?.name,
                },
                // { type: 'dos', startDate: 'start', endDate: 'end',  label: 'Auth. Start Date ', selectList: [] },
                // { type: 'date', name: 'authStartDate', label: 'Auth. Start Date ',  },
            ],
    }
        ;
};

export const payrollReportEnums = () => {

    return [
        {
            id: uuidv4(),
            name: 'Appointment Detail Report',
            description: 'Detailed appointment overview including staff, client, billing, and service information.',
            generateInfo: scheduleReport(),
        },
    ];
};