import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import { InputAdornment, TextField } from '@material-ui/core';
import { Svg } from 'assets';

export const InputMain = forwardRef(
    ({
         customDesign = true,
         label, labelHeader, placeholder, rules, control, name, type = 'text', maxLength, minLength,
         startAdornment, endAdornment, className, onClickIconStart, onClickIconEnd, showPassword, ...anotherProps
     }, ref) => {

        const EndIcon = showPassword ? Svg.EyeSvg  : Svg.EyeHideSvg ;

        const typePassword = showPassword ? 'text' : 'password';

        return (
            <div
                className={`input-main-wrapper ${customDesign ? 'input-custom-wrapper' : ''} ${className ? className : ''}`}
                ref={ref}>
                <Controller
                    control={control}
                    name={name}
                    rules={rules}
                    render={({ field, formState: { errors } }) => (
                        <>
                            {labelHeader
                                &&
                                <p className={`input-main-label-header ${errors[field?.name]?.message ? 'errorLabel' : ''}`}>
                                    {labelHeader}
                                    {rules?.required
                                        && <span className='input-main-label-header-required'>
                                        *
                                </span>
                                    }
                                </p>
                            }
                            <TextField
                                ref={ref}
                                {...anotherProps}
                                {...field}
                                autoComplete='on'
                                error={!!errors[field?.name]?.message}
                                type={showPassword ? typePassword : type}
                                value={field.value}
                                placeholder={placeholder}
                                label={label}
                                inputProps={{ maxLength: maxLength, minLength: minLength }}
                                InputProps={{
                                    startAdornment: startAdornment &&
                                        <InputAdornment
                                            onClick={onClickIconStart}
                                            className={`${errors[field?.name]?.message ? 'error-svg' : ''}`}
                                            position='start'
                                        >
                                            {startAdornment}
                                        </InputAdornment>,
                                    endAdornment:
                                        (endAdornment && <InputAdornment
                                            onClick={onClickIconEnd}
                                            position='end'
                                            className={`${errors[field?.name]?.message ? 'error-svg' : ''}`}
                                        >
                                            {endAdornment}
                                        </InputAdornment>)
                                        || (type === 'password'
                                            && <InputAdornment
                                                onClick={onClickIconEnd}
                                                position='end'
                                                className={`${errors[field?.name]?.message ? 'error-svg-password' : ''}`}
                                            >
                                                <EndIcon />
                                            </InputAdornment>),
                                }}
                            />
                            <p className='error-message'>{errors[field?.name]?.message}</p>
                        </>
                    )}
                />
            </div>
        );
    });

InputMain.displayName = 'InputMain';


