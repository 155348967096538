import { Images, LandingSvg, landingImages } from 'assets';


export const DESKTOP = 1280;
export const MOBILE = 735;

// home-first-box-content
export const homeFirstBoxContentTitle = "Make your healthcare management better.";

export const homeFirstBoxContentSubtitle =
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s";


// home-second-box-content
export const homeSecondBoxContentTitle = "Features for productive management";


export const featuresForProductiveMgmt = [
    {
        Icon: LandingSvg.EMRSvg,
        featureTitle: 'EMR',
        featureDescription:
            'Simplified data entry and automated reminders for efficient records management',
    },
    {
        Icon: LandingSvg.ScheduleSvg,
        featureTitle: 'Scheduling',
        featureDescription:
            'Automated staff assignments and appointments with real-time availability',
    },
    {
        Icon: LandingSvg.BillingSvg,
        featureTitle: 'Billing',
        featureDescription:
            'Seamless claims management and insurance billing, reducing manual errors',
    },
    {
        Icon: LandingSvg.PostingSvg,
        featureTitle: 'Posting',
        featureDescription:
            'Payment posting and ERA processing to minimize errors',
    },
    {
        Icon: LandingSvg.CredentialingSvg,
        featureTitle: 'Credentialing',
        featureDescription:
            'Simplified credentialing and contracting process to streamline compliance',
    },
    {
        Icon: LandingSvg.IntegrationsSvg,
        featureTitle: 'Integrations',
        featureDescription:
            'Effortless connections with Office Ally, clearinghouses, and other platforms',
    },

    {
        Icon: LandingSvg.AdvancedSvg,
        featureTitle: 'Advanced Reporting',
        featureDescription:
            'In-depth insights into practice performance with customizable reports',
    },

    {
        Icon: LandingSvg.MobileAppSvg,
        featureTitle: 'Mobile App',
        featureDescription:
            'Works online and offline, providing crucial support for therapists on the move',
    },
];


export const customersTestimonials = [
    // {
    //     // customerAvatar: landingImages.customerOne,
    //     customerComment:
    //         'Switching to TherapyLake has been a game-changer for our practice. Our billing is automated, scheduling is seamless, and our data is more organized than ever.',
    //     customerName: 'George J',
    //     customerProfession: 'Practice Manager',
    //     rating: 5,
    // },
    // {
    //     // customerAvatar: landingImages.customerTwo,
    //     customerComment:
    //         'TherapyLake has revolutionized how I manage my caseload as a BCBA. ' +
    //         'The advanced reporting and EMR features have streamlined data collection and analysis, allowing me to focus more on client progress than paperwork.',
    //     customerName: 'Robert S.R',
    //     customerProfession: 'Board Certified Behavioral Analyst',
    //     rating: 5,
    // },
    {
        customerComment:
            'TherapyLake has been incredibly easy to implement, and our efficiency in scheduling and billing has improved significantly. It’s truly become our go-to tool!',
        customerName: 'Aisha Khan',
        customerProfession: 'Clinic Administrator',
        rating: 5,
    },
    {
        customerComment:
            'The session tracking is very helpful, and it\'s so easy to pull up client notes whenever needed. Overall, a reliable and efficient system.',
        customerName: 'Carlos Rodríguez',
        customerProfession: 'Lead Therapist',
        rating: 5,
    },
    {
        customerComment:
            'TherapyLake has made our claims submission smoother than ever before. It integrates seamlessly with our insurance payers, making billing a breeze.',
        customerName: 'Mei Wong',
        customerProfession: 'Billing Specialist',
        rating: 5,
    },
    {
        customerComment:
            'The dashboard layout is clean and easy to navigate, and the reporting features provide exactly what we need to keep our clinic running smoothly.',
        customerName: 'Dmitry Ivanov',
        customerProfession: 'Operations Manager',
        rating: 5,
    },
    {
        customerComment:
            'Payroll tracking has become simpler for our staff, and we no longer have to worry about errors. The scheduling features are also very intuitive.',
        customerName: 'Priya Patel',
        customerProfession: 'ABA Clinic Director',
        rating: 5,
    },
    {
        customerComment:
            'TherapyLake’s role management features are fantastic. Setting up permissions is straightforward, making onboarding new staff much easier.',
        customerName: 'Maria Garcia',
        customerProfession: 'Staff Coordinator',
        rating: 5,
    },
    {
        customerComment:
            'The scheduling system is extremely user-friendly. I can see all my appointments in one place, and even rescheduling takes just a few clicks.',
        customerName: 'Yuki Tanaka',
        customerProfession: 'Therapist',
        rating: 5,
    },
    {
        customerComment:
            'The guardrails in TherapyLake’s processes have been a huge help in reducing staff errors. From invoicing to claims, every step is guided, making sure we do things right.',
        customerName: 'Ahmed El-Sayed',
        customerProfession: 'Financial Administrator',
        rating: 5,
    },
    {
        customerComment:
            'Keeping track of staff credentials is effortless with TherapyLake. Renewing licenses and certifications is now much easier to manage.',
        customerName: 'Anika Müller',
        customerProfession: 'HR Manager',
        rating: 5,
    },
    {
        customerComment:
            'TherapyLake has been a game-changer for our clinic. Whether it’s scheduling or managing client information, everything is more organized now.',
        customerName: 'Lucas Fernandes',
        customerProfession: 'Clinic Owner',
        rating: 5,
    },
    {
        customerComment:
            'TherapyLake has significantly reduced our administrative workload. From billing to scheduling, all our needs are met with one comprehensive system.',
        customerName: 'Saanvi Reddy',
        customerProfession: 'Office Manager',
        rating: 5,
    }
];


// home-fifth-box-content
export const homeFifthBoxContentTitle =
    'See for yourself how we are helping teams do their best work!';

export const homeFifthBoxContentSubtitle =
    'Imagine you have scheduled lore Epsom Here are many variations of passages of Lorem Ipsum available.';


export const cardsData = [
    {
        id: 1,
        title: 'Data Integrity',
        description: 'Checks and balances to avoid scheduling, billing, and data entry errors',
        image: landingImages.whyTherapyCard1,
    },
    {
        id: 2,
        title: 'State of the Art System',
        description: 'Modern, intuitive and robust technology that simplifies usage cuts training times and increases',
        image: landingImages.whyTherapyCard2,
    },
    {
        id: 3,
        title: '24/7 Support',
        description: 'Backed by experts in ABA and modern technology with 24/7 phone/email support',
        image: landingImages.whyTherapyCard3,
    },
    {
        id: 4,
        title: 'Automated Workflows',
        description: 'Reduce manual work and increase efficiency',
        image: landingImages.whyTherapyCard4,
    },
];

export const accessKeyLanding = [
    {
        title: 'Online & Offline Support:',
        description: 'Continue working even without an internet connection, and sync data automatically when reconnected',
    },

    {
        title: 'Real-Time Sync:',
        description: 'Ensure all client data, schedules, and reports are up-to-date across devices',
    },

    {
        title: 'On-the-Go Efficiency:',
        description: 'Access client data, scheduling, and reporting from anywhere',
    },

    {
        title: 'Appointment Scheduling:',
        description: 'Schedule, reschedule, and manage appointments directly from the mobile app',
    },
];
