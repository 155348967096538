import React from "react";
import { Link } from "react-router-dom";
import {Images} from "../../assets";

export const Logo = ({ logoClassName, onClickLogo }) => {
   return (
      <Link to={"/"} className={`logo-link ${logoClassName}`} onClick={onClickLogo}>
         {" "}
        <img src={Images.logo} alt='icon' style={{width:'150px', height:'30', objectFit:'cover'}}/>
      </Link>
   );
};
