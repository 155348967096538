import React from 'react';
import { useHistory } from 'react-router-dom';
import { SaveParams } from 'utils';

export const TabSwitcher = ({ list, defaultType }) => {
    const history = useHistory();
    const info = history?.location?.state;
    const checkActive = info?.tabType ? info?.tabType : defaultType;

    const changeType = (type) => {
        let params = {
            ...info,
            tabType: type,
        };
        SaveParams(history, { ...params });
    };

    return (
        <div className="buttons-tab-wrapper">
            {list?.map((item, index) => (
                <button
                    key={index}
                    onClick={() => changeType(item?.tabValue)}
                    className={
                        checkActive === item?.tabValue ? 'active-btn' : 'passive-btn'}
                >
                    {item?.title}
                </button>
            ))}
        </div>
    );
};