/** Actions */
import axios from 'axios';

export { store } from './store';
export { API_BASE } from './constants';
export { authActions, authService } from './auth';
export { permissionsActions } from './permissions';
export { roleActions } from './role';
export { adminActions } from './admin';
export { fundingSourceActions } from './fundingSource';
export { clientActions } from './client';
export { systemActions } from './system';
export { payrollActions } from './payroll';
export { noteActions } from './notes';
export { availabilityScheduleActions } from './availabilitySchedule';
export { appointmentActions } from './appointment';
export { claimActions, invoiceActions } from './billing';
export { claimPaymentActions, invoicePaymentActions } from './posting';
export { historyActions } from './history';
export { reportActions } from './reports';
export { staffPayrollActions } from './staffPayroll';
export { notificationActions } from './notification';
export { analyticActions } from './analytics';
export { httpRequestsOnSuccessActions } from './http_requests_on_success';
export { httpRequestsOnErrorsActions } from './http_requests_on_errors';
export { httpRequestsOnLoadActions } from './http_requests_on_load';


export const UploadServices = {

    UploadImage: (formData) => axios.post('/files/upload?includeThumbnail=false', formData, { auth: true, params: '' }),

    UploadManyImage: (formData) => axios.post('/files/uploadMany?includeThumbnail=false', formData, { auth: true, params: '' }),

    UploadImageThumb: (formData) => axios.post('/files/upload?includeThumbnail=true', formData, {
        auth: true,
        params: '',
    }),

};

export const CsvService = {

    getStaffCsv: () => axios.get('/reports/staff', { auth: true }),

    getClientCsv: () => axios.get('/reports/client', { auth: true }),

    getScheduleCsv: (params) => axios.get('/reports/appt', { auth: true, params: params }),

    getFundingSourceCsv: () => axios.get('/reports/fs', { auth: true }),

    getClaimPaymentCsv: (params) => axios.get('/reports/posting/claim', { auth: true, params: params}),

    getInvoicePaymentCsv: (params) => axios.get('/reports/posting/invoice', { auth: true, params: params }),

    activePassivePayrollCsv: (params) => axios.get('/reports/payroll', { auth: true, params: { ...params } }),

    pendingClaimCsv: (params) => axios.get('/reports/billing/claim', { auth: true, params: { ...params } }),

    billingInvoiceCsv: (params) => axios.get('/reports/billing/invoice', { auth: true, params: { ...params } }),

};

export const AddressService = {
    getAddress: (address) => axios.post('/address', { address: address }),
};

