import React, { useContext } from 'react';
import { navBarStyles } from './style';
import clsx from 'clsx';
import { CustomBreadcrumbs } from 'components';
import { UserInfo } from './userInfo';
import { AppBar, Toolbar } from '@material-ui/core';
import { DrawerContext, getMenuTitle } from 'utils';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SearchInput } from './common/searchInput';

export const TopBar = () => {
    const classes = navBarStyles();
    const url = window.location.pathname;
    const menuTittle = getMenuTitle(url);
    const { open } = useContext(DrawerContext);
    const location = useLocation();
    const originalPath = location?.pathname?.split('/')[1];
    const checkHelpCenter = location?.pathname === '/help-center';


    const { staffGeneral, fsData, client } = useSelector((state) => ({
        staffGeneral: state.admins.adminInfoById,
        fsData: state.fundingSource.fundingSourceItem,
        client: state.client.clientItemInfo,
    }));

    const renderLinks = () => {
        if (originalPath === 'fundingSource') {
            return { parent: 'Funding Source', child: fsData?.name, parentLink: '/fundingSource' };
        }
        if (originalPath === 'staff') {
            return {
                parent: 'Staff',
                child: staffGeneral?.firstName ? `${staffGeneral?.firstName} ${staffGeneral?.lastName}` : null,
                parentLink: '/staff',
            };
        }
        if (originalPath === 'client') {
            return {
                parent: 'Client',
                child: client?.firstName ? `${client?.firstName} ${client?.lastName}` : null,
                parentLink: '/client',
            };
        }
        if (originalPath === 'claim') {
            return { parent: 'Pending Claims', child: 'Claim Details', parentLink: '/pendingClaims' };
        }
        if (originalPath === 'billedClaim') {
            return { parent: 'Billed Claims', child: 'Claim Details', parentLink: '/billedClaims' };
        }
        if (originalPath === 'invoice') {
            return { parent: 'Invoices', child: 'Invoice Details', parentLink: '/invoices' };
        }
        if (originalPath === 'generateClaim') {
            return { parent: 'Pending Claims', child: 'Generate Claims', parentLink: '/pendingClaims' };
        }
        if (originalPath === 'generateInvoice') {
            return { parent: 'Invoices', child: 'Generate Invoice', parentLink: '/invoices' };
        }
        if (originalPath === 'timesheet') {
            return { parent: 'Active Payroll', child: 'Payroll Details', parentLink: '/activePayroll' };
        }
        if (originalPath === 'processed') {
            return { parent: 'Processed Payroll', child: 'Payroll Details', parentLink: '/processedPayroll' };
        }

        if (originalPath === 'help-center') {
            return { parent: 'App', child: 'Help Center', parentLink: '/' };
        }
    };


    const checkSearch = () => {
        return location?.pathname === '/' ||
        location?.pathname === '/schedule' ||
            location?.pathname === '/pendingClaims' ||
            location?.pathname === '/billedClaims' ||
            location?.pathname === '/invoices' ||
            location?.pathname === '/claimPayments';
    };



    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, { [classes.appBarShift]: open })}
        >
            <Toolbar className={classes.headerContent}>
                <div className={classes.Toolbar}>
                    <div className={
                        checkHelpCenter ? '' :
                        open === true ? classes.openToolbar : classes.closeToolbar}>
                        {menuTittle === 'detail' ?
                            renderLinks()?.child ?
                                <CustomBreadcrumbs
                                    parent={renderLinks()?.parent}
                                    child={renderLinks()?.child}
                                    parentLink={renderLinks()?.parentLink}
                                /> : null
                            :
                            <p className={classes.getMenuTitle}>{menuTittle}</p>
                        }
                    </div>

                    <div className={classes.userActionsBoxStyle}>
                        {checkSearch() && <SearchInput />}
                        <UserInfo />
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );
};
