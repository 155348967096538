import { call, put, takeLatest } from 'redux-saga/effects';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';
import {
    ADD_USER_KPI,
    DELETE_USER_KPI,
    GET_ALL_KPIS,
    GET_ALL_KPIS_SUCCESS, GET_CHART_DATA, GET_CHART_DATA_SUCCESS,
    GET_USER_FILTERED_KPIS, GET_USER_FILTERED_KPIS_SUCCESS,
    GET_USER_KPIS,
    GET_USER_KPIS_SUCCESS,
    REORDER_KPIS,
} from './analytics.types';
import { authService } from './analytics.service';

/** Analytics */
function updateOriginalKpis(userKpis, kpis) {
    const kpiIds = new Set(userKpis?.map(kpi => kpi.id));

    kpis.forEach(kpi => {
        kpi.added = kpiIds.has(kpi.id);
    });
    return kpis;
}

function* getUserFilteredKpis(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    try {
        const kpis = yield call(authService.getAllKpisService, action?.payload?.params);
        const userKpis = yield call(authService.getUserKpisService, action?.payload?.params);
        const resFiltered = updateOriginalKpis(userKpis?.data, kpis?.data);

        yield put({
            type: GET_USER_FILTERED_KPIS_SUCCESS,
            payload: resFiltered,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* getAllKpis(action) {
    if (action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    try {
        const res = yield call(authService.getAllKpisService, action?.payload?.params);
        yield put({
            type: GET_ALL_KPIS_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* getUserKpis(action) {
    if (action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    try {
        const res = yield call(authService.getUserKpisService, action?.payload?.params);
        yield put({
            type: GET_USER_KPIS_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* reorderKpis(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const idList = action?.payload?.body?.map(kpi => kpi.id);
        const res = yield call(authService.reorderKpisService, idList, action?.payload?.params);

        yield put({
            type: GET_USER_KPIS_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* deleteUserKpi(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.deleteUserKpiService, action?.payload?.id);
        const kpis = yield call(authService.getAllKpisService);
        const userKpis = yield call(authService.getUserKpisService, action?.payload?.params);
        yield put({
            type: GET_USER_FILTERED_KPIS_SUCCESS,
            payload: updateOriginalKpis(userKpis?.data, kpis?.data),
        });
        yield put({
            type: GET_USER_KPIS_SUCCESS,
            payload: userKpis?.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}
function* addUserKpi(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.addUserKpiService, action?.payload?.id);
        const kpis = yield call(authService.getAllKpisService);
        const userKpis = yield call(authService.getUserKpisService, action?.payload?.params);
        yield put({
            type: GET_USER_FILTERED_KPIS_SUCCESS,
            payload: updateOriginalKpis(userKpis?.data, kpis?.data),
        });
        yield put({
            type: GET_USER_KPIS_SUCCESS,
            payload: userKpis?.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}
function* getChartData(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    try {
        const res = yield call(authService.getChartDataService, action?.payload);
        yield put({
            type: GET_CHART_DATA_SUCCESS,
            payload: res?.data
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}


/** End */

export const watchAnalytics = function* watchAnalyticsSaga() {

    /** Analytics */
    yield takeLatest(GET_USER_FILTERED_KPIS, getUserFilteredKpis);
    yield takeLatest(GET_ALL_KPIS, getAllKpis);
    yield takeLatest(GET_USER_KPIS, getUserKpis);
    yield takeLatest(REORDER_KPIS, reorderKpis);
    yield takeLatest(DELETE_USER_KPI, deleteUserKpi);
    yield takeLatest(ADD_USER_KPI, addUserKpi);
    yield takeLatest(GET_CHART_DATA, getChartData);
    /** End */

};