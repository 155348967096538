import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ValidationInput, CreateChancel, MaskInput, SelectTypeAutocomplete } from 'components';
import {
    EmailValidator,
    ErrorText,
    FindErrorItem,
    FindLoad,
    FindSuccessItem,
    getPhoneErrorText, hooksForErrors,
    isNotEmpty,
    useModal,
} from 'utils';
import { clientActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';

export const ClientDetails = ({ info }) => {
    const ACTION_TYPE = !!info ? 'EDIT_CLIENT' : 'CREATE_CLIENT';
    const adminsList = useSelector((state) => state.admins.adminsList);
    const [error, setError] = useState('');
    const [inputs, setInputs] = useState({});
    const dispatch = useDispatch();
    const { close } = useModal();
    const success = FindSuccessItem(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const backError = FindErrorItem(ACTION_TYPE);
    const phoneErrorMsg = inputs?.phoneNumber && getPhoneErrorText(inputs?.phoneNumber);
    const emailErrorMsg = !EmailValidator.test(inputs.email) ? ErrorText.emailValid : '';
    const phoneErrorText = hooksForErrors.getPhoneError(error, backError, phoneErrorMsg);
    const emailErrorText = hooksForErrors.getEmailError(error, backError, emailErrorMsg);

    useEffect(() => {
        if (info?.id) {
            const clientInfo = {
                ...info,
                // birthday: moment(info?.birthday).format('YYYY-MM-DD'),
            };
            info?.renderingProviderId ? clientInfo.renderingProvider = info?.renderingProviderId : '';
            setInputs(clientInfo);
        }
    }, [info]);

    useEffect(() => {
        if (success?.type === ACTION_TYPE) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        error === e.target.name && setError('');
    };

    const handleCreate = () => {
        const emailIsValid = inputs.email ? isNotEmpty(inputs.email) && EmailValidator.test(inputs.email) : true;
        const firstStepIsValid = isNotEmpty(inputs.firstName) && isNotEmpty(inputs.lastName) && emailIsValid;

        if (firstStepIsValid) {
            const data = {
                firstName: inputs.firstName,
                middleName: inputs?.middleName ? inputs.middleName : null,
                lastName: inputs.lastName,
                gender: inputs.gender,
                birthday: inputs.birthday,
                ethnicity: inputs.ethnicity,
                languages:  info?.languages?.length ? info?.languages : null,
                familyLanguage:  info?.familyLanguage ? info?.familyLanguage : null,
                insuredInfo: info?.insuredInfo?.firstName ? { ...info?.insuredInfo } : null,
                status: 'ACTIVE',
            };

            data.phoneNumber = inputs?.phoneNumber ? parseInt(inputs?.phoneNumber.replace(/[^0-9]/g, '')) + '' : null;
            info?.insuredInfo?.address ? data.insuredInfo.address = info?.insuredInfo?.address?.formattedAddress : '';
            data.registrationDate = inputs?.registrationDate ? inputs?.registrationDate : null;
            data.email = inputs?.email ? inputs?.email : null;
            data.renderingProvider = inputs?.renderingProvider ? inputs?.renderingProvider : null;

            dispatch(clientActions.editClient(data, info?.id));
        } else {
            const firstStepErrorText =
                !isNotEmpty(inputs.firstName) ? 'firstName' :
                    !isNotEmpty(inputs.lastName) ? 'lastName' :
                        !emailIsValid ? emailErrorMsg :
                            '';
            setError(firstStepErrorText);
        }
    };

    useEffect(() => {
        return () => dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
    }, []);

    return (
        <div style={{ width: 463 }}>
            <p className='modal-header-title'>Edit General Info </p>
            <div className='modal-body-wrapper' >
                <ValidationInput
                    variant={'outlined'}
                    onChange={handleChange}
                    value={inputs.firstName}
                    type={'text'}
                    label={'First Name*'}
                    name='firstName'
                    typeError={error === 'firstName' ? `First name ${ErrorText.isRequired}` : ''}
                    Length={15}
                />
                <ValidationInput
                    variant={'outlined'}
                    onChange={handleChange}
                    value={inputs.middleName}
                    type={'text'}
                    label={'Middle Name'}
                    name='middleName'
                    typeError={error === 'middleName' ? ErrorText.field : ''}
                    Length={15}
                />
                <ValidationInput
                    variant={'outlined'}
                    onChange={handleChange}
                    value={inputs.lastName}
                    type={'text'}
                    label={'Last Name*'}
                    name='lastName'
                    typeError={error === 'lastName' ? `Last name ${ErrorText.isRequired}` : ''}
                    Length={15}
                />
                <ValidationInput
                    validator={EmailValidator}
                    variant={'outlined'}
                    name={'email'}
                    type={'email'}
                    label={'Email Address'}
                    typeError={emailErrorText}
                    value={inputs?.email}
                    onChange={handleChange}
                />
                <MaskInput
                    name='phoneNumber'
                    label='Phone Number'
                    value={inputs.phoneNumber}
                    handleChange={handleChange}
                    error={phoneErrorText}
                />
                <SelectTypeAutocomplete
                    loadType={"GET_ADMINS"}
                    title={'Rendering Provider'}
                    name={'renderingProvider'}
                    handleSelect={handleChange}
                    defaultValue={inputs?.renderingProvider}
                    list={adminsList?.staffs?.length ? adminsList?.staffs : []}
                    error={error}
                    typeError={error === 'staff' ? ErrorText.selectField : ''}
                    renderValue={(i) => `${i?.firstName} ${i?.lastName}`}
                />
            </div>
                <CreateChancel
                    loader={!!loader.length}
                    create={'Save'}
                    chancel={'Cancel'}
                    onCreate={handleCreate}
                    onClose={() => close()}
                    buttonWidth='224px'
                />
        </div>
    );
};
