import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apptEnums, checkEditPermission } from './constants';
import { billStatuses } from '../constants';
import { Images, PermissionList, RolePermission, useModal, useSecondaryModal } from 'utils';
import { CustomDelete, SimpleModal, SimpleTooltip } from 'components';
import { appointmentActions } from 'store';
import { Recur, CreateEvent } from './modals';

const CONNECT_RECONNECT = 'CONNECT_RECONNECT_TEMPLATE';
const DELETE_TYPE = 'DELETE_APPOINTMENT';
const LOCK_UNLOCK_ACTION_TYPE = 'CHANGE_APPOINTMENT_LOCK_UNLOCK';

export const ApptActionsWithModals = ({ item, afterSuccess }) => {
    const dispatch = useDispatch();
    const { open } = useModal();
    const { closeSecondary } = useSecondaryModal();
    const [editAppt, setEditAppt] = useState(null);
    const user = useSelector((state) => state.auth.userInfo);
    const userInfo = user || JSON.parse(localStorage.getItem('wellUserInfo'));
    const checkEdit =
        checkEditPermission(item) ? true :
            RolePermission([PermissionList.APPT_SELF_UPDATE?.code]) ? userInfo?.id === item?.staff?._id : false;

    const checkDelete = RolePermission([PermissionList.UNRENDERED_APPT_DELETE?.code]) ? true :
        RolePermission([PermissionList.APPT_SELF_DELETE?.code]) ? userInfo?.id === item?.staff?._id : false;

    const handleOpenRecur = (item) => {
        const tb = item?.staff?.credentials?.find((i) => i?.type === 'TB');
        open(<Recur date={item} staffTb={tb} currentService={item?.authorizedService} />);
    };

    const deleteAppt = () => {
        open(<CustomDelete
            text={`Are you sure you want to delete this appointment?`}
            info="Delete appointment"
            handleDel={() => dispatch(appointmentActions.deleteAppointment(item?.id))}
            handleClose={close}
            actionType={DELETE_TYPE}
            afterSuccess={() => {
                closeSecondary();
                afterSuccess();
            }}
        />);
    };

    const handleLockUnlock = (item) => {
        const subText = item?.locked ?
            'Are you sure you want to unlock this appointment? Once unlocked, it can be edited again.'
            :
            'Are you sure you want to lock this appointment? Once locked, it cannot be edited until unlocked.';

        open(
            <CustomDelete
                info={!item?.locked ? 'Confirm Lock Action' : 'Confirm Unlock Action'}
                innerText={!item?.locked ? 'Lock' : 'Unlock'}
                text={subText}
                color={'blue'}
                handleDel={() => dispatch(appointmentActions.changeApptLockUnlock({
                    'apptIds': [item?.id],
                    'all': false,
                }, !item?.locked, {}, item?.id))}
                handleClose={close}
                actionType={LOCK_UNLOCK_ACTION_TYPE}
                afterSuccess={afterSuccess}
            />,
        );
    };


    const connectReconnect = (item) => {
        const checkConnect = !item?.template;
        const subText = checkConnect ?
            'This appointment was previously disconnected from its recurring template. Reconnecting it will associate it back with the template and align it with the template\'s recurring pattern.'
            :
            'Are you sure you want to disconnect this appointment from its recurring template? Disconnection will make this appointment independent of the template. You can always reconnect it later if needed.';

        open(
            <CustomDelete
                info={checkConnect ? 'Reconnect Appointment to Template' : 'Disconnect Appointment from Template'}
                text={subText}
                innerText={checkConnect ? 'Reconnect' : 'Disconnect'}
                handleDel={() => dispatch(appointmentActions.connectReconnect(item?.id, { 'id': item?.template }))}
                handleClose={close}
                actionType={CONNECT_RECONNECT}
                color={'blue'}
                afterSuccess={afterSuccess}
            />,
        );
    };

    const handleOpenClose = () => {
        setEditAppt(null);
    };


    return (
        <div>
            <div className="appt-actions-box">

                <div className="appt-action-btn">
                    {item?.status !== apptEnums.CANCELLED && item?.billStatus !== billStatuses?.BILLED && checkEdit &&
                        <button
                            className="new-action-btn-box-small"
                            style={RolePermission([PermissionList.LOCKED_APPT_EDIT?.code]) ? {} : item?.locked ? { opacity: 0.5 } : {}}
                            disabled={RolePermission([PermissionList.LOCKED_APPT_EDIT?.code]) ? false : item?.locked}
                            onClick={() => setEditAppt(item)}
                        >
                            <img src={Images.editDark} alt="icon" />
                        </button>
                    }
                </div>

                <div className="appt-action-btn">
                    {RolePermission([PermissionList.APPT_RECURE?.code]) && !item?.template && item?.status !== apptEnums.CANCELLED ?
                        <SimpleTooltip
                            title={<p>{'Recur Event'}</p>}
                            placement="top-end"
                        >
                            <button
                                className="new-action-btn-box-small"
                                onClick={(e) => handleOpenRecur(item)}>
                                <img src={Images.recurDark} alt="icon" />
                            </button>
                        </SimpleTooltip>
                        :
                        item?.template && item?.status !== apptEnums.CANCELLED &&
                        <SimpleTooltip
                            title={
                                <p>{item?.template ? 'Disconnect from Template' : 'Reconnect from Template'}</p>
                            }
                            placement="top-end"
                        >
                            <button
                                className="new-action-btn-box-small"
                                onClick={() => connectReconnect(item)}>
                                <img
                                    src={item?.template ? Images.reConnectedTemplate : Images.connectedTemplate}
                                    alt="icon"
                                />
                            </button>
                        </SimpleTooltip>
                    }
                </div>

                <div className="appt-action-btn">
                    {RolePermission([PermissionList.APPT_LOCK?.code]) && item?.status !== apptEnums.CANCELLED &&
                        <SimpleTooltip
                            title={<p>{item?.locked ? 'Unlock Appointment' : 'Lock Appointment'}</p>}
                            placement="top-end"
                        >
                            <button className="new-action-btn-box-small" onClick={(e) => handleLockUnlock(item)}>
                                <img src={item?.locked ? Images.lock : Images.unlock} alt="icon" />
                            </button>
                        </SimpleTooltip>
                    }
                </div>

                <div className="appt-action-btn">
                    {(item?.status === apptEnums.PENDING || item?.status === apptEnums.CANCELLED) && checkDelete &&
                        <SimpleTooltip
                            title={<p>{'Delete Appointment'}</p>}
                            placement="top-end"
                        >
                            <button
                                className="new-action-btn-box-small"
                                onClick={(e) => deleteAppt(e)}>
                                <img src={Images.redTrash} alt="icon" />
                            </button>
                        </SimpleTooltip>
                    }
                </div>

            </div>

            <SimpleModal
                handleOpenClose={handleOpenClose}
                openDefault={editAppt}
                content={
                    <CreateEvent
                        afterSuccess={afterSuccess}
                        modalDate={editAppt}
                        handleOpenClose={handleOpenClose}
                    />
                }
            />

        </div>
    );
};