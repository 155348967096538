import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SimpleTabs } from 'components';
import { signatureTabsLabels } from './constants';
import { Uploader } from './uploader';
import { Draw } from './draw';
import { FindSuccess, FindSuccessItem, useModal } from 'utils';
import { httpRequestsOnSuccessActions } from 'store';

const SUCCESS_TYPE = 'APPEND_SIGNATURE_TO_APPMT';

export const SignatureUploader = ({ appt }) => {
    const [tab, setTab] = useState('Upload');
    const dispatch = useDispatch();
    const success = FindSuccessItem(SUCCESS_TYPE);
    const { close } = useModal();

    useEffect(() => {
        if (success?.type === SUCCESS_TYPE) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(SUCCESS_TYPE));
            close();
        }
    }, [success]);

    const tabsContent = [
        {
            tabComponent: <Uploader appt={appt} />,
            tab: 'Upload',
        },
        {
            tabComponent: <Draw appt={appt} />,
            tab: 'Draw',
        },
    ];

    return (
        <div style={{ width: 560, height: 475 }} className='signature-tabs-wrapper'>
            <p className="modal-header-title">Add Signature</p>
            <div className="modal-body-wrapper">
                <SimpleTabs
                    small={true}
                    getTab={setTab}
                    tab={tab}
                    defaultTab={'Upload'}
                    tabsLabels={signatureTabsLabels}
                    tabsContent={tabsContent}
                />
            </div>
        </div>
    );
};