import { makeStyles } from '@material-ui/core/styles';
import { Backgrounds, Colors, Shadow } from 'utils';

export const cardStyle = makeStyles(() => ({
    tableTheadStyle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: Shadow.theadRowBoxShadow,
        backgroundColor: Colors.BackgroundWater,
        borderRadius: '8px',
    },
    thStyle: {
        maxWidth: '242px',
        width: '100%',
        padding: '16px 16px',
        '@media(max-width: 1720px)': { padding: '16px 16px' },
    },
    tbodyContainerStyle: { width: '100%' },
    tbodyRowStyle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '8px',
        marginTop: '8px',
        backgroundColor: Colors.BackgroundWhite,
        boxShadow: Shadow.tbodyRowBoxShadow,
        cursor: 'pointer',
    },
    tdStyle: {
        maxWidth: '242px',
        width: '100%',
        padding: '16px 16px',
        '@media(max-width: 1720px)': { padding: '16px 16px' },
        fontSize: '14px',
        fontWeight: 400,
        color: Colors.TextSecondary,
    },

    noteDrawerStyle: {
        '& div.MuiBackdrop-root': {
            opacity: '0 !important',
        },
        '& div.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight.MuiPaper-elevation16':
            {
                top: '340px',
                right: '42px',
                width: '395px',
                maxHeight: '38vh',
                height: '100%',
                overflowY: 'auto',
                borderRadius: '8px',
                boxShadow: Shadow.noteModalShadow,
            },
    },

    card: {
        width: '100% !important',
        borderRadius: '8px',
        boxShadow: Shadow.changeShadow,
        padding: '18px 16px 32px 16px',
        minHeight: '500px',
    },
    headerBorder: {
        width: '100%',
        borderRight: '4px solid transparent',
        borderLeft: '4px solid transparent',
        margin: '-18px 0 18px',
    },
    cardHeader: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '26px',
        position: 'relative',
    },
    cardIcon: {
        width: '36px',
        height: '36px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        '&::after': {
            content: `''`,
            position: 'absolute',
            left: '1px',
            top: '1px',
            width: '34px',
            height: '34px',
            backgroundColor: 'transparent',
            borderRadius: '50%',
            border: '1px solid white',
        },
    },
    cardTitle: {
        fontSize: '16px',
        color: Colors.TextSecondary,
        fontWeight: '600',
        lineHeight: '22px',
        paddingLeft: '18px',
    },
    topLine: {
        position: 'absolute',
        top: '-18px',
        width: '100%',
        height: '4px',
        borderRadius: '0 0 8px 8px',
    },
    cardBody: {
        overflowY: 'scroll',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    historyCardStyle: {
        width: '100%',
        padding: '16px',
        background: '#F2F4F8',
        marginBottom: 16,
        borderRadius: 8,
    },
    historyCardDateStyle: {
        color: Colors.ThemeBlue,
        fontSize: 14,
        fontWeight: 600,
        marginBottom: 16,
    },
    historyCardBoxStyle: {
        width: '100%',
        background: Backgrounds.whiteModal,
        boxShadow: '0px 0px 6px #347AF033',
        borderRadius: 8,
        padding: '16px 32px',
        display: 'flex',
        alignItems: 'center',
        marginTop: 8,
        fontSize: 14,
        color: Colors.TextPrimary,
        '& p': {
            color: '#4B5C68',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
        },
    },
    historyCardBoxTimeAndName: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    circle:{
        width: '5px',
        height: '5px',
        borderRadius: '50px',
        background: '#A3B2BD',
    },
    historyTime:{
      width:'50px',
    },
    userInformation:{
      width:'150px'
    },
    historyCardBoxTimeStyle: {
        minWidth: 200,
    },
    cardItem: {
        width: '100%',
        background: Backgrounds.catskillWhite,
        display: 'flex',
        alignItems: 'center',
        padding: '15px 16px',
        borderRadius: '8px',
        marginBottom: '8px',
    },
    cardItemContainerStyle: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
    },
    titleStyle: {
        fontSize: '14px',
        color: Colors.TextSecondary,
        fontWeight: '600',
        lineHeight: '24px',
        '&.several:not(:first-of-type)': { marginTop: '16px' },
    },
    cardValue: {
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '19px',
        color: '#84949f',
        marginTop: '2px',
        marginLeft: '8px',
        '& .text-style': {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '19px',
            color: '#84949f',
        },
    },
    valueStyle: {
        fontSize: '14px',
        color: Colors.TextMiddleGray,
        marginLeft: '8px',
        lineHeight: '24px',
        maxHeight: '120px',
        overflowY: 'auto',
        '&.several:not(:first-of-type)': { marginTop: '16px' },
        '&.statusStyle': {
            display: 'flex',
            alignItems: 'center',
            '&::before': {
                content: '\'\'',
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                marginRight: '8px',
            },
            '&.ACTIVE::before': { backgroundColor: Colors.ThemeGreen },
            '&.INACTIVE::before': { backgroundColor: Colors.TextSecondary },
            '&.ON_HOLD::before': { backgroundColor: '#FFCA33' },
            '&.TERMINATED::before': { backgroundColor: '#FE7070' },
            '&.FBA::before': { backgroundColor: '#ADD8E6' },
            '&.WAIT_LIST::before': { backgroundColor: '#FE7B43' },
            '&.GRADUATED::before': { backgroundColor: '#51439E' },
        },
    },
    linkStyle: {
        display: 'inline-flex',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 400,
        color: Colors.BackgroundBlue,
        marginLeft: '8px',
        textDecoration: 'none',
        width: '100%',
    },
    editButton: {
        background: 'transparent',
        border: 'none',

    },
    rowCenterWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        marginTop: '100px',
        cursor: 'pointer',
    },
    addTitle: {
        marginLeft: '13px',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#347AF0',
    },
    blueText: {
        color: '#347AF0!important',
        marginTop: '3px',
        marginLeft: '8px',
        cursor: 'pointer',
    },
}));
