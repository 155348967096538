import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalHeader, TextRow, NoYet, TypeRow, DateRowStartEnd, CustomDelete } from 'components';
import { Images, PermissionList, RolePermission, useModal } from 'utils';
import { createClientStyle } from 'fragments/client';
import { adminActions } from 'store';
import { EmploymentModal } from './modals';

const DELETE_ACTION_TYPE = 'DELETE_EMPLOYMENT'

export const PastStaffs = ({ handleClose }) => {
    const classes = createClientStyle();
    const { employments,staffGeneral } = useSelector((state) => ({
        employments: state.admins.employments,
        staffGeneral: state.admins.adminInfoById,
    }));
    employments.sort((a, b) => new Date(b.endDate) - new Date(a.endDate));
    const { open } = useModal();
    const dispatch = useDispatch()


    return (
        <div className={classes.createFoundingSourceBig}>
            <ModalHeader
                className={classes.employmentModalStyle}
                handleClose={handleClose}
                title={
                    <div className={classes.alignItem}>
                        <img src={Images.bigEmployment} alt="" />
                        <p>Past Employments</p>
                    </div>
                }
            />
            <div className={classes.createFoundingSourceBody}>
                <div className={classes.tHead}>
                    <div>Employment</div>
                    <div style={{width:'90px'}}>Supervisor</div>
                    <div>Period</div>
                    <div>Department</div>
                    <div style={{width:'90px'}}>Type</div>
                    {RolePermission([PermissionList.STAFF_CREATE?.code]) &&
                        <div style={{width:'90px'}}>Action</div>
                    }
                </div>
                <div className={classes.tBody}>
                    {employments?.length ?
                        employments.map((i, k) => (
                            <div key={k} className={classes.tBodyItem}>
                                <div className={classes.item}>
                                    <TextRow name={i?.title?.name} textWidth={8} />
                                </div>
                                <div className={classes.item} style={{width:'90px'}}>
                                    <TextRow name={i?.supervisor?.firstName} textWidth={8} />
                                </div>
                                <div className={classes.item}>
                                    <DateRowStartEnd start={i?.startDate} end={i?.endDate} />
                                </div>
                                <div className={classes.item}>
                                    <TextRow name={i?.department?.name} textWidth={8} />
                                </div>
                                <div className={classes.item} style={{width:'90px'}}>
                                    <TypeRow text={i?.type} />
                                </div>
                                {RolePermission([PermissionList.STAFF_CREATE?.code]) &&
                                <div className={classes.item} style={{width:'90px', display:'flex'}}>
                                    <button
                                        onClick={() => open(<EmploymentModal edit={i} />)}
                                        className='reset-btn' style={{padding: 0}}
                                    >
                                        <img src={Images.edit} alt="icon" />
                                    </button>
                                    <button className='reset-btn'
                                    onClick={() => open(<CustomDelete
                                        text="This action cannot be undone."
                                        info="Are you sure you want to delete this past employment?"
                                        handleDel={() => dispatch(adminActions.deleteEmployment( i?.id, staffGeneral?.id,))}
                                        handleClose={() => close()}
                                        actionType={DELETE_ACTION_TYPE}
                                    />)}
                                    >
                                        <img src={Images.remove} alt="icon" />
                                    </button>
                                </div>
                                }
                            </div>
                        ))
                        :
                        <div style={{ marginTop: '10px' }}>
                            <NoYet text="No Past Employments Yet" />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};
