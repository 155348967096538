import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { APPT_FLAGGED_ENUMS } from 'utils';
import { clientActions, fundingSourceActions } from 'store';
import { DateTypeSelector, SelectTypeAutocomplete } from 'components';

export const GenerateClaimFilters = ({ setFilters, filters }) => {
    const dispatch = useDispatch();
    const fundingSourceList = useSelector((state) => state.fundingSource.fundingSourceList);
    const clientList = useSelector((state) => state.client.clientList);

    useEffect(() => {
        dispatch(fundingSourceActions.getFundingSource());
        dispatch(clientActions.getClients());
    }, []);

    const handleChange = (e) => {
        const pushInfo = {
            ...filters,
        };

        if (e.target.value === 'All' || !e.target.value) {
            delete pushInfo[e.target.name];
        } else {
            pushInfo[e.target.name] = e.target.value;
        }
        setFilters({ ...pushInfo });
    };

    const changeDate = (e) => {
        const params = {
            ...filters,
            start: e?.start,
            end: e?.end,
        };
        setFilters(params);
    };

    return (
        <div className="generate-claim-filter-section">
            <SelectTypeAutocomplete
                placeholder={filters?.fundingSource ? '' : 'All'}
                name={'fundingSource'}
                handleSelect={handleChange}
                defaultValue={filters?.fundingSource}
                list={fundingSourceList?.funders ?
                    [{ id: 'All', name: 'All' }, ...fundingSourceList?.funders]
                    :
                    [{ id: 'All', name: 'All' }]
                }
                renderValue={(i) => i?.name}
                uiType={'tableFilter'}
                outLabel={'Funding Source'}
                noError={true}
            />
            <SelectTypeAutocomplete
                placeholder={filters?.client ? '' : 'All'}
                name={'client'}
                handleSelect={handleChange}
                defaultValue={filters?.client}
                list={clientList?.clients ?
                    [{ id: 'All', firstName: 'All' }, ...clientList?.clients]
                    :
                    [{ id: 'All', firstName: 'All' }]
                }
                renderValue={(i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`}
                uiType={'tableFilter'}
                outLabel={'Client'}
                noError={true}
            />
            <SelectTypeAutocomplete
                placeholder={filters?.flag ? '' : 'All'}
                name={'flag'}
                handleSelect={handleChange}
                defaultValue={filters?.flag}
                list={APPT_FLAGGED_ENUMS}
                renderValue={(i) => i?.label}
                uiType={'tableFilter'}
                outLabel={'Flagged'}
                noError={true}
            />
            <DateTypeSelector
                startName={'start'}
                endName={'end'}
                outLabel={'DOS'}
                filters={filters}
                handleGetDates={changeDate}
            />
        </div>
    );
};
