import { v4 as uuidv4 } from 'uuid';

export const allClients = {
    title: 'Clients Report',
    actionType: 'GENERATE_CLIENT_REPORT',
    modalType: 'client',
    list: [
        { type: 'text', name: 'client', label: 'Client' },
        { type: 'dateRange' },
    ],
};

export const activeClients = {
    title: 'Clients Report',
    actionType: 'GENERATE_CLIENT_REPORT',
    modalType: 'client',
    list: [
        {
            type: 'select', name: 'status', label: 'Status',
            selectList: [
                { name: 'All', id: 'All' },
                { name: 'Active', id: 'ACTIVE' },
                { name: 'FBA', id: 'FBA' },
                { name: 'On Hold', id: 'ON_HOLD' },
                { name: 'Waitlist', id: 'WAIT_LIST' },
                { name: 'Terminated', id: 'TERMINATED' },
                { name: 'Graduated', id: 'GRADUATED' },
            ],
        },
    ],
};


export const clientReportEnums = [
    {
        id: uuidv4(),
        name: 'All Clients',
        description: 'The Monthly Client Progress Report provides a comprehensive overview of each client’s behavioral and developmental progress during the selected time frame. ',
        generateInfo: allClients,
    },
];