import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
    CreateChancel,
    PriceRow,
    NumericFormatCustom,
    SelectAutocompleteMultiple,
    TextareaCustom,
    DateCalendarForm,
} from 'components';
import { FindLoad, FindSuccessItem, SaveParams, useModal } from 'utils';
import {
    adminActions,
    httpRequestsOnErrorsActions,
    httpRequestsOnSuccessActions,
    staffPayrollActions,
} from 'store';
import { createClientStyle } from 'fragments/client';
import { staffModalsStyle } from './styles';
import { TIMESHEET_ENUMS } from './constants';


const PAY_CODE_ACTION_TYPE = 'GET_PAY_CODE';
export const CreateActiveTimesheet = ({ info, fromStaff, createType }) => {
    const { adminById, adminsList, payCodes } = useSelector((state) => ({
        adminById: state.admins.adminInfoById,
        adminsList: state.admins.adminsList,
        payCodes: state.admins.payCodes,
    }));
    const type = info ? info?.payCodeId ? TIMESHEET_ENUMS.PAYCODE : TIMESHEET_ENUMS.FIXED : createType;
    const classes = createClientStyle();
    const classes_v2 = staffModalsStyle();
    const ACTION_TYPE = info ? 'EDIT_TIMESHEET' : 'CREATE_TIMESHEET';
    const { close } = useModal();
    const dispatch = useDispatch();
    const success = FindSuccessItem(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const title = type === TIMESHEET_ENUMS.FIXED ? 'Add Fixed Pay' : 'Add Paycode Timesheet';
    const editTitle = type === TIMESHEET_ENUMS.FIXED ? 'Edit Fixed Pay' : 'Edit Paycode Timesheet';
    const filteredPayCodes = payCodes?.filter((item) => !item?.terminationDate);
    const { handleSubmit, control, clearErrors, reset, setValue, watch } = useForm({});
    const history = useHistory();
    const pushInfo = history?.location?.state;

    useEffect(() => {
        if (fromStaff) {
            setValue('staffId', adminById?.id);
        }
    }, []);

    const removePayCodes = () => {
        dispatch(adminActions.clearAllPayCodes());
    };

    useEffect(() => {
        removePayCodes();
        if (!fromStaff) {
            dispatch(adminActions.getAdmins({ status: 'ACTIVE' }));
        }
    }, []);

    useEffect(() => {
        if (watch('staffId')) {
            removePayCodes();
            dispatch(adminActions.getPayCode(watch('staffId')));
        }
    }, [watch('staffId')]);


    useEffect(() => {
        if (info) {
            const params = {
                timesheetDate: moment.utc(info.timesheetDate).format('YYYY-MM-DD'),
                staffId: info?.staffId,
                description: info?.description,
                amount: info?.amount,
                qty: info?.qty,
            };

            if (info?.qty) {
                const qty = info?.qty;
                const hours = Math.floor(qty);
                const minutes = Math.round((qty - hours) * 60);
                params.hours = hours;
                params.minutes = minutes;
            }
            reset(params);
        }
    }, [info]);

    useEffect(() => {
        if (success) {
            close();
            SaveParams(history, pushInfo);
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
        if (info && success) {
            close();
            if(fromStaff){
                SaveParams(history, pushInfo);
            }else{
                dispatch(staffPayrollActions.getPayrollById(info?.id));
            }
        }
    }, [success]);

    useEffect(() => {
        return () => {
            clearErrors();
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        };
    }, []);

    const handleCreate = (data) => {
        const sandedData = {
            ...data,
        };

        if (createType !== TIMESHEET_ENUMS.FIXED || type === TIMESHEET_ENUMS.PAYCODE) {
            const decimalMinutes = data?.minutes / 60;
            sandedData.qty = +data?.hours + +decimalMinutes.toFixed(3);
        }
        if(createType === TIMESHEET_ENUMS.FIXED){  
            sandedData.type = TIMESHEET_ENUMS.FIXED;
        }

        delete sandedData.hours;
        delete sandedData.minutes;

        if (info) {
            if (type === TIMESHEET_ENUMS.FIXED) {
                delete sandedData.payCode;
                delete sandedData.qty;
            }else{
                sandedData.payCode = sandedData.payCode?.id || sandedData.payCode?._id
                delete sandedData.amount;
            }

            const currentPayCode = payCodes?.find((i) => i?.id === data?.payCode?.id);
            if (!currentPayCode?.terminationDate) {
                dispatch(adminActions.editTimesheet(sandedData, info?.id));
            }
        } else {
            sandedData.payCode = sandedData.payCode?.id;
            dispatch(adminActions.createTimesheet(sandedData));
        }
    };

    useEffect(() => {
        if (info && payCodes) {
            const currentPayCode = payCodes?.find((i) => i?.id === info?.payCodeId);
            setValue('payCode', currentPayCode);
        }
    }, [payCodes]);

    return (
        <form
            style={{ width: 420 }}
            onSubmit={handleSubmit(handleCreate)}
        >
            <p className="modal-header-title">{info ? editTitle : title}</p>
            <div className="modal-body-wrapper">

                {!fromStaff &&
                    <SelectAutocompleteMultiple
                        name="staffId"
                        label={'Staff Member*'}
                        control={control}
                        options={adminsList?.staffs}
                        renderValue={((i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`)}
                        rules={{ required: 'Staff member is required.' }}
                        loadType={'GET_ADMINS'}
                    />
                }
                {type === TIMESHEET_ENUMS.FIXED ?
                    <>
                        <NumericFormatCustom
                            allowNegative={true}
                            name={'amount'}
                            label={'Amount*'}
                            prefix={'$'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            control={control}
                            rules={{
                                required: 'Amount is required. Please enter the charge amount.',
                            }}
                        />
                    </>
                    :
                    <>
                        <SelectAutocompleteMultiple
                            loadType={PAY_CODE_ACTION_TYPE}
                            name="payCode"
                            label={'Paycode*'}
                            control={control}
                            options={filteredPayCodes || []}
                            rules={{ required: 'Paycode is required.' }}
                            renderValue={(i) => `${i?.payCodeTypeId?.name ? i?.payCodeTypeId?.name : ''}`}
                            fullValue={true}
                        />
                        <div className={classes.displayCodeBlock}>
                            <div className={classes_v2.paycodeBox} style={{ width: '100%' }}>
                                <p className={classes_v2.paycodeBoxTitle}>Amount:</p>
                                <p className={classes_v2.paycodeBoxText} style={{ width: '100%' }}>
                                    <PriceRow info={watch('payCode')?.rate ? watch('payCode')?.rate : 0} />
                                </p>
                            </div>
                            <div className={classes_v2.paycodeBox} style={{ marginBottom: 0 }}>
                                <p className={classes_v2.paycodeBoxTitle}>Type:</p>
                                <p className={classes_v2.paycodeBoxText}>
                                    {watch('payCode') ? watch('payCode')?.payCodeTypeId?.type : 'N/A'}
                                </p>
                            </div>
                        </div>
                    </>
                }

                <TextareaCustom
                    control={control}
                    name="description"
                    label="Description"
                    variant={'outlined'}
                    allowCharacter={true}
                    maxLength={100}
                    // noError={true}
                />

                {type !== TIMESHEET_ENUMS.FIXED &&
                    <div className="flex-align-start" style={{ gap: 16 }}>
                        <NumericFormatCustom
                            name={'hours'}
                            label={'Hours*'}
                            thousandSeparator={false}
                            fixedDecimalScale={true}
                            decimalScale={0}
                            control={control}
                            rules={{
                                required: 'Hours is required.',
                            }}
                        />
                        <NumericFormatCustom
                            name={'minutes'}
                            label={'Minutes*'}
                            thousandSeparator={false}
                            fixedDecimalScale={true}
                            decimalScale={0}
                            control={control}
                            rules={{
                                required: 'Minutes is required.',
                            }}
                        />
                    </div>
                }
                <DateCalendarForm
                    name="timesheetDate"
                    label={'Timesheet Date*'}
                    max={moment.utc().format('YYYY-MM-DD')}
                    control={control}
                    rules={{
                        required: 'Created date is required.',
                    }}
                />
            </div>
            <CreateChancel
                type="submit"
                loader={!!loader.length}
                create={info ? 'Save' : 'Add'}
                chancel={'Cancel'}
                onClose={close}
                buttonWidth="100%"
            />
        </form>
    );
};
