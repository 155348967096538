import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { StaffItem } from "fragments";
import { adminActions, systemActions, noteActions } from "store";
import { Loader } from "components";
import {FindLoad, PermissionList, RolePermission} from "utils";

export const SingleStaff = () => {
   const dispatch = useDispatch();
   const params = useParams();
   const getAdminByIdLoader = FindLoad("GET_ADMIN_BY_ID");

   useEffect(() => {
      dispatch(adminActions.getAdminById(params.id));
      dispatch(adminActions.getEmployment(params.id));

      if(RolePermission([PermissionList.STAFF_PAYCODE_MANAGE?.code, PermissionList.STAFF_PAYCODE_READ?.code,])) {
         dispatch(adminActions.getAllPayCodes(params.id));
      }
      if(RolePermission([PermissionList.STAFF_CREDENTIAL_MANAGE?.code, PermissionList.STAFF_CREDENTIAL_READ?.code,])) {
         dispatch(systemActions.getCredentialGlobal());
      }
      if(RolePermission([PermissionList.STAFF_SERVICE_MANAGE?.code,  PermissionList.STAFF_SERVICE_READ?.code])) {
         dispatch(systemActions.getServices({}));
      }
      if(RolePermission([PermissionList.STAFF_NOTE_MANAGE?.code,  PermissionList.STAFF_NOTE_READ?.code])) {
         dispatch(noteActions.getGlobalNotes(params.id, "Staff"));
      }
      if( RolePermission([PermissionList.STAFF_FILE_MANAGE?.code, PermissionList.STAFF_FILE_READ?.code])) {
         dispatch(adminActions.getStaffDocument(params.id));
      }
   }, []);


   if (getAdminByIdLoader.length) {
      return (
          <div style={{height: "85vh"}}>
             <Loader/>
          </div>
      );
   }

   return (
      <StaffItem/>
   );
};
