import React, { useEffect } from 'react';
import { Charts, Widgets } from './fragments';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { SaveParams } from '../../../utils';

export const Analytic = () => {
    const history = useHistory();
    const info = history?.location?.state;

    useEffect(() => {
        if(!info?.startDate && !info?.endDate) {
            const startDate = moment().startOf('year');
            const endDate = moment().endOf('year');
            const prevStartDate = moment().subtract(1, 'year').startOf('year');
            const prevEndDate = moment().subtract(1, 'year').endOf('year');

            const params = {
                ...info
            }
            params.startDate = startDate.format('YYYY-MM-DD');
            params.endDate = endDate.format('YYYY-MM-DD');
            params.prevStartDate = prevStartDate.format('YYYY-MM-DD');
            params.prevEndDate = prevEndDate.format('YYYY-MM-DD');
            SaveParams(history, { ...params });
        }
    }, []);

    return (
        <div>
            <Widgets/>

            <Charts/>
        </div>
    );
};
