import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { enGB } from 'date-fns/locale';
import { DateInput } from 'rsuite';
import { Popper } from '@material-ui/core';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Images, SaveParams } from 'utils';
import { ErrMessage } from 'components';

export const WidgetDateSelector = ({
                                     outLabel,
                                     handleGetDates,
                                     filters,
                                     buttonStyles,
                                     startName,
                                     endName,
                                     type,
                                     error,
                                 }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    let history = useHistory();
    const info = filters ? filters : history?.location?.state;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const startDate = moment().startOf('year');
    const endDate = moment().endOf('year');
    const prevStartDate = moment().subtract(1, 'year').startOf('year');
    const prevEndDate = moment().subtract(1, 'year').endOf('year');

    const [state, setState] = useState([{
        startDate: startDate.format(),
        endDate: endDate.format(),
        key: 'selection',
    }]);

    const customLocale = {
        ...enGB,
        options: {
            ...enGB.options,
            weekStartsOn: 1,
        },
    };

    useEffect(() => {
        if (info?.[startName] || info?.[endName]) {
            const params = {
                startDate: info[startName] ? moment(info[startName]).format() : moment().format(),
                endDate: info[endName] ? moment(info[endName]).format() : moment().format(),
                key: 'selection',
            };

            info[startName] ? params[startName] = moment(info[startName]).format() : delete params[startName];
            info[endName] ? params[endName] = moment(info[endName]).format() : delete params[endName];
            setState([params]);
        }
    }, [info]);

    // const handleDateChange = (item) => {
    //     const startDate = moment(item?.selection?.startDate);
    //     const endDate = moment(item?.selection?.endDate);
    //
    //     const stateParams = {
    //         startDate: startDate.format(),
    //         endDate: endDate.format(),
    //         key: 'selection',
    //     };
    //     stateParams[startName] = startDate.format();
    //     stateParams[endName] = endDate.format();
    //     setState([stateParams]);
    //
    //     const params = {
    //         ...info,
    //     };
    //     params[startName] = startDate.format('YYYY-MM-DD');
    //     params[endName] = endDate.format('YYYY-MM-DD');
    //
    //
    //
    //     if (handleGetDates) {
    //         handleGetDates(params);
    //     } else {
    //         SaveParams(history, params);
    //     }
    // };

    const handleDateChange = (item) => {
        // Extract current dates
        const startDate = moment(item?.selection?.startDate);
        const endDate = moment(item?.selection?.endDate);

        // Calculate the duration of the selected range
        const duration = moment.duration(endDate.diff(startDate));

        // Calculate the previous range
        const prevEndDate = startDate.clone().subtract(1, 'day'); // Previous range ends the day before current start
        const prevStartDate = prevEndDate.clone().subtract(duration); // Subtract the same duration as the current range

        // Construct new state with the current and previous dates
        const stateParams = {
            startDate: startDate.format(),
            endDate: endDate.format(),
            prevStartDate: prevStartDate.format(),
            prevEndDate: prevEndDate.format(),
            key: 'selection',
        };
        stateParams[startName] = startDate.format();
        stateParams[endName] = endDate.format();
        setState([stateParams]);

        // Prepare parameters with the current and previous dates
        const params = {
            ...info,
            [startName]: startDate.format('YYYY-MM-DD'),
            [endName]: endDate.format('YYYY-MM-DD'),
            prevStartDate: prevStartDate.format('YYYY-MM-DD'),
            prevEndDate: prevEndDate.format('YYYY-MM-DD'),
        };

        // Call the appropriate function
        if (handleGetDates) {
            handleGetDates(params);
        } else {
            SaveParams(history, params);
        }
    };

    // const handleClear = () => {
    //     setState([{
    //         startDate: startDate.format(),
    //         endDate: endDate.format(),
    //         key: 'selection',
    //     }]);
    //
    //     const params = {
    //         ...info,
    //     };
    //     params['prevStartDate'] = prevStartDate.format('YYYY-MM-DD');
    //     params['prevEndDate'] = prevEndDate.format('YYYY-MM-DD');
    //
    //
    //     delete params[startName];
    //     delete params[endName];
    //
    //     if (handleGetDates) {
    //         handleGetDates(params);
    //     } else {
    //         SaveParams(history, params);
    //     }
    // };

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleTypeDate = (value, name, originalName) => {
        const date = value?.toString();

        if (isValidDate(date)) {
            const typedValue = moment.utc(value);

            const stateParams = {
                ...state[0],
            };
            stateParams[name] = typedValue.format();
            stateParams[originalName] = typedValue.format();
            setState([stateParams]);
        }
    };


    const handleBlur = (name) => {
        const value = state[0][name];
        const checkIsNotSame = (value && info?.[name]) ? moment(value).format('YYYY-MM-DD') !== moment(info?.[name]).format('YYYY-MM-DD') : true;

        if (value && checkIsNotSame) {
            if (handleGetDates) {
                const params = {
                    ...info,
                };
                params[name] = moment(value).format('YYYY-MM-DD');
                handleGetDates(params);
            } else {

                const params = {
                    ...info,
                };
                params[name] = moment(value).format('YYYY-MM-DD');
                SaveParams(history, params);
            }
        }
    };

    const isValidDate = (dateString) => {
        const date = new Date(dateString);
        return !isNaN(date.getTime());
    };

    return (
        <div className="date-selector-box">
            {outLabel &&
                <p
                    style={type === 'modalInput' ? { color: error ? '#FE7070' : (info[startName] || info[endName]) ? '#347AF0' : '#51566D' } : {}}
                    className={type === 'modalInput' ? 'modal-date-selector-label' : 'date-selector-label'}>
                    {outLabel}
                </p>
            }
            <button
                style={
                    error ? { borderColor: '#FE7070', color: '#FE7070', ...buttonStyles } :
                        type === 'modalInput' ? {} : { ...buttonStyles }
                }
                className={type === 'modalInput' ? 'modal-date-range-selector' : 'date-range-selector'}
                aria-describedby={id}
                type="button"
                onClick={handleClick}
            >
                <div className="date-range-inputs" style={{ gap: type === 'modalInput' ? 16 : 4 }}
                     onClick={(e) => {
                         e.stopPropagation();
                         e.preventDefault();
                     }}
                >
                    <DateInput
                        onBlur={() => handleBlur(startName)}
                        placeholder={'mm/dd/yyyy'}
                        format="MM/dd/yyyy"
                        value={state[0]?.[startName] ? moment.utc(state[0][startName]).toDate() : null}
                        onChange={(value) => handleTypeDate(value, startName, 'startDate')}
                    />
                    <img src={Images.arrowRight} alt="icon" />
                    <DateInput
                        onBlur={() => handleBlur(endName)}
                        placeholder={'mm/dd/yyyy'}
                        format="MM/dd/yyyy"
                        value={state[0]?.[endName] ? moment.utc(state[0][endName]).toDate() : null}
                        onChange={(value) => handleTypeDate(value, endName, 'endDate')}
                    />
                </div>
                <div className="flex-align-center">
                    {/*<div style={{ width: '21px' }}>*/}
                    {/*    {(info?.[startName] || info?.[endName]) &&*/}
                    {/*        <button className="remove-calendar-day" type={'button'} onClick={(e) => {*/}
                    {/*            e.preventDefault();*/}
                    {/*            e.stopPropagation();*/}
                    {/*            handleClear();*/}
                    {/*        }}>*/}
                    {/*            <img src={Images.removeBlack} alt="remove" />*/}
                    {/*        </button>*/}
                    {/*    }*/}
                    {/*</div>*/}
                    <img src={Images.calendarToday} alt="icon" />
                </div>
            </button>

            {error && <ErrMessage text={error}/>}

            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                className={'widget-date-selector-box'}
                placement="bottom-start"
                style={{ position: 'absolute', zIndex: 1400 }}
            >
                <DateRangePicker
                    showDateDisplay={false}
                    ranges={state}
                    onChange={handleDateChange}
                    locale={customLocale}
                />
                {/*<button className="clear-bnt" onClick={handleClear}>Clear</button>*/}
            </Popper>
            {open && <div className="beck-drop" onClick={() => setAnchorEl(null)} />}
        </div>
    );
};
