import moment from 'moment';

export const WidgetEnums = {
    CLIENT: 'CLIENT',
    PAYROLL: 'PAYROLL',
    STAFF: 'STAFF',
    FS_SOURCE: 'FS_SOURCE',
    APPT: 'APPT',
}

export const WidgetCategories = [
    {name: 'Client', id: WidgetEnums.CLIENT },
    {name: 'Payroll', id: WidgetEnums.PAYROLL },
    {name: 'Staff', id: WidgetEnums.STAFF },
    {name: 'FS Source', id: WidgetEnums.FS_SOURCE },
    {name: 'Appointment', id: WidgetEnums.APPT },
]

export const DataTypes = {
    SNAPSHOT: 'SNAPSHOT',
    PERIODIC: 'PERIODIC',
};

export const ValueTypes = {
    NUMBER: 'NUMBER',
    DOLLAR: 'DOLLAR',
    PERCENT: 'PERCENT',
};

export const checkFilter = (inputs, i) => {
    if (inputs?.category) {
        return i?.category === inputs?.category
    }
    return true
}


export const ValueFormatter = (value, valueType) => {
    switch (valueType) {
        case ValueTypes.NUMBER:
            return value;
        case ValueTypes.DOLLAR:
            return `$${value}`;
        case ValueTypes.PERCENT:
            return `%${value}`;
        default:
            return value;
    }
}


export const DefaultDates = ( info ) => {
    const startDate = moment().startOf('year');
    const endDate = moment().endOf('year');
    const prevStartDate = moment().subtract(1, 'year').startOf('year');
    const prevEndDate = moment().subtract(1, 'year').endOf('year');


    const allDates = {
        startDate: info?.startDate ? info?.startDate :  startDate.format('YYYY-MM-DD'),
        endDate: info?.endDate ?  info?.endDate : endDate.format('YYYY-MM-DD'),
        prevStartDate: info?.prevStartDate ?  info?.prevStartDate : prevStartDate.format('YYYY-MM-DD'),
        prevEndDate: info?.prevEndDate ?  info?.prevEndDate : prevEndDate.format('YYYY-MM-DD'),
    }
    return {
        allDates
    }
}
