import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment/moment';
import { scheduleStyle } from './styles';
import { DateTypeSelector, DownloadCsv, SelectTypeAutocomplete } from 'components';
import { APPT_FLAGGED_ENUMS, PermissionList, RolePermission, SaveParams } from 'utils';
import { Images } from 'utils';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { scheduleStatuses, scheduleTypes } from './constants';

export const Filters = ({ handleOpen, openCloseFullScreen, closeFullScreen, fullView }) => {
    const { clientList, adminsList } = useSelector((state) => ({
        clientList: state.client.clientList,
        adminsList: state.admins.adminsList,
    }));
    const currentDate = moment();
    const currentWeekStart = currentDate.clone().startOf('isoWeek');
    const currentWeekEnd = currentDate.clone().endOf('isoWeek');
    const classes = scheduleStyle();
    let history = useHistory();
    const info = history?.location?.state;
    const [showFilters, setShowFilters] = useState(true);

    moment.locale('es-es', {
        week: {
            dow: 1,
        },
    });

    const handleChange = (e) => {
        const newFilters = {
            ...info,
        };

        if (e.target.value === 'All') {
            delete newFilters[e.target.name];
        } else {
            newFilters[e.target.name] = e.target.value;
        }
        SaveParams(history, { ...newFilters });
    };

    const handleChangeScreen = (type) => {
        closeFullScreen && closeFullScreen();
        const pushInfo = {
            ...info,
            viewType: type,
        };
        delete pushInfo.screenType;
        SaveParams(history, { ...pushInfo });
    };

    const goToNextWeek = () => {
        const date1 = moment(info?.firstDate).format();
        const date2 = moment(info?.lastDate).format();
        const daysDifference = moment(date2).diff(moment(date1), 'days') + 1;
        const updatedFirstDate =
            info?.firstDate ?
                moment(info?.firstDate).add(daysDifference, 'days').format('LLLL') :
                moment(info?.firstDate ? info?.firstDate : currentWeekStart).clone().add(1, 'weeks').startOf('isoWeek').format('LLLL');
        const updatedLastDate =
            info?.lastDate ?
                moment(info?.lastDate).add(daysDifference, 'days').format('LLLL') :
                moment(info?.firstDate ? info?.firstDate : currentWeekStart).clone().add(1, 'weeks').endOf('isoWeek').format('LLLL');

        history.push({
            state: {
                ...info,
                firstDate: updatedFirstDate,
                lastDate: updatedLastDate,
            },
        });
    };

    const goToLastWeek = () => {
        const date1 = moment(info?.firstDate).format();
        const date2 = moment(info?.lastDate).format();
        const daysDifference = moment(date2).diff(moment(date1), 'days') + 1;
        const updatedFirstDate =
            info?.firstDate ?
                moment(info?.firstDate).subtract(daysDifference, 'days').format('LLLL') :
                moment(info?.firstDate ? info?.firstDate : currentWeekStart).clone().subtract(1, 'weeks').startOf('isoWeek').format('LLLL');
        const updatedLastDate =
            info?.lastDate ?
                moment(info?.lastDate).subtract(daysDifference, 'days').format('LLLL') :
                moment(info?.firstDate ? info?.firstDate : currentWeekStart).clone().subtract(1, 'weeks').endOf('isoWeek').format('LLLL');

        history.push({
            state: {
                ...info,
                firstDate: updatedFirstDate,
                lastDate: updatedLastDate,
            },
        });
    };


    // const currentWeek =
    //     `${moment(info?.firstDate ? info?.firstDate : currentWeekStart).format('MMMM D')}
    //    –
    //      ${moment(info?.lastDate ? info?.lastDate : currentWeekEnd).format('MMMM D')}`;
    //
    const renderTabBtns = () => {
        return (
            <div className={classes.scheduleViewBtnsBox}>
                <button
                    className={(info?.viewType === 'list' || !info?.viewType) ? classes.scheduleViewActiveBtn : classes.scheduleViewPassiveBtn}
                    onClick={() => handleChangeScreen('list')}
                >
                    <img src={Images.scheduleList} alt="icon" />
                </button>

                <button
                    className={info?.viewType === 'calendar' ? classes.scheduleViewActiveBtn : classes.scheduleViewPassiveBtn}
                    onClick={() => handleChangeScreen('calendar')}
                >
                    <img src={Images.scheduleGroup} alt="icon" />
                </button>
            </div>
        );
    };

    const createEventBtn = () => {
        return (
            <div className="export-csv-box">

                {RolePermission([PermissionList.REPORT_MANAGE?.code]) &&
                <DownloadCsv type={'schedule'} fromModal={true} />
                }
                {RolePermission([
                        PermissionList.APPT_SERVICE_CREATE?.code,
                        PermissionList.APPT_CONNECTED_CREATE?.code,
                        PermissionList.APPT_DRIVE_CREATE?.code,
                        PermissionList.APPT_BREAK_CREATE?.code,
                        PermissionList.APPT_PTO_CREATE?.code,
                        PermissionList.APPT_STAFF_CREATE?.code,
                        PermissionList.APPT_SELF_CREATE?.code,
                        PermissionList.APPT_SICK_TIME_CREATE?.code,
                    ]) &&
                    <div onClick={handleOpen} className={classes.addEvent}>
                        <img src={Images.addCircle} alt="icon" />
                        <p>Add Appointment</p>
                    </div>
                }
            </div>
        );
    };

    return (
        <div className="schedule-filters">
            <div className="schedule-filter-wrapper">
                <div className="schedule-filter-box">
                    {!fullView &&
                        <button className={`filter-passive-btn ${showFilters ? 'active-filter' : ''}`}
                                onClick={() => setShowFilters(!showFilters)}>
                            <img src={Images.filters} alt="icon" />
                            <p>Filters</p>
                        </button>
                    }
                    <div>
                        {renderTabBtns()}
                    </div>

                    {info?.viewType === 'calendar' &&
                        <button className="full-view" onClick={openCloseFullScreen}>
                            <img src={fullView ? Images.minimize : Images.expandFull} alt="icon" />
                        </button>
                    }

                    <div className={classes.calendarNextPrewButtons}>
                        <div className={classes.buttonsWrapper}>
                            <div className={classes.navigationButtons}>
                                <NavigateBefore
                                    style={{ color: '#387DFF', cursor: 'pointer' }}
                                    onClick={() => goToLastWeek()}
                                />
                                <NavigateNext
                                    style={{ color: '#387DFF', cursor: 'pointer' }}
                                    onClick={() => goToNextWeek()}
                                />
                            </div>
                            <div className={classes.dateStyle}>
                                <span>{
                                    `${moment(info?.firstDate ? info?.firstDate : currentWeekStart).format('MMMM D')}
                                    –
                                     ${moment(info?.lastDate ? info?.lastDate : currentWeekEnd).format('MMMM D')}`
                                }</span>
                            </div>
                        </div>
                    </div>
                </div>
                <>
                    {createEventBtn()}
                </>
            </div>

            <div className={info?.viewType !== 'calendar' ? 'calendar-filters-view' : ''}>
                {showFilters && !fullView &&
                    <div className={classes.filtersWrapper}>
                        <div className={classes.filtersWrapperRow}>
                            <div>
                                <p className={classes.label}>
                                    Staff Member
                                </p>
                                <SelectTypeAutocomplete
                                    placeholder={info?.staffId ? '' : 'All'}
                                    name={'staff'}
                                    handleSelect={handleChange}
                                    defaultValue={info?.staff}
                                    list={adminsList?.staffs ?
                                        [{ id: 'All', firstName: 'All', lastName: '' }, ...adminsList?.staffs]
                                        :
                                        [{ id: 'All', firstName: 'All', lastName: '' }]
                                    }
                                    renderValue={(i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`}
                                    uiType={'tableFilter'}
                                />
                            </div>
                            <div>
                                <p className={classes.label}>
                                    Client
                                </p>
                                <SelectTypeAutocomplete
                                    placeholder={info?.client ? '' : 'All'}
                                    name={'client'}
                                    handleSelect={handleChange}
                                    defaultValue={info?.client}
                                    list={clientList?.clients ?
                                        [{ id: 'All', firstName: 'All', lastName: '' }, ...clientList?.clients]
                                        :
                                        [{ id: 'All', firstName: 'All', lastName: '' }]
                                    }
                                    renderValue={(i) => `${i?.code ? `(${i?.code})` : ''} ${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`}
                                    uiType={'tableFilter'}
                                />
                            </div>
                            <div>
                                <p className={classes.label}>
                                    Event Type
                                </p>
                                <SelectTypeAutocomplete
                                    placeholder={info?.type ? '' : 'All'}
                                    name={'type'}
                                    handleSelect={handleChange}
                                    defaultValue={info?.type}
                                    list={scheduleTypes}
                                    renderValue={(i) => i?.name}
                                    uiType={'tableFilter'}
                                />
                            </div>
                            <div>
                                <p className={classes.label}>
                                    Event Status
                                </p>
                                <SelectTypeAutocomplete
                                    placeholder={info?.status ? '' : 'All'}
                                    name={'status'}
                                    handleSelect={handleChange}
                                    defaultValue={info?.status}
                                    list={scheduleStatuses}
                                    renderValue={(i) => i?.name}
                                    uiType={'tableFilter'}
                                />
                            </div>
                            <div>
                                <p className={classes.label}>
                                    Date of Service
                                </p>
                                <div className={classes.dosInputsBox}>
                                    <DateTypeSelector
                                        startName={'firstDate'}
                                        endName={'lastDate'}
                                    />
                                    {/*<CustomDateSelector/>*/}
                                </div>
                            </div>

                            <div>
                                <p className={classes.label}>
                                    Flagged
                                </p>
                            <SelectTypeAutocomplete
                                placeholder={info?.flag ? '' : 'All'}
                                name={'flag'}
                                handleSelect={handleChange}
                                defaultValue={info?.flag}
                                list={APPT_FLAGGED_ENUMS}
                                renderValue={(i) => i?.label}
                                uiType={'tableFilter'}
                                noError={true}
                            />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};
