import React from 'react';
import moment from 'moment/moment';
import { DownloadFile, Images } from 'utils';
import { MinLoader, TextRow } from 'components';

export const SignatureDetails = ({ item }) => {
    const { loadDownload, handleDownload } = DownloadFile();

    return (
        <>
            <div className="signature-process">
                <p className="schedule-details-title">Signature Details</p>
                <div className="flex-align-center" style={{ gap: 8 }}>
                    <div className="flex-align-center" style={{ gap: 8 }}>
                        <div className="signature-detail-box" style={{ maxWidth: '100%' }}>
                            <div className="flex-align-center" style={{ gap: 4 }}>
                                <img src={Images.signatureFile} alt="icon" />
                                <p className="signature-detail-box-title">File:</p>
                                <p className="signature-detail-name">
                                    <TextRow name={item?.signature?.originalName} textWidth={7} />
                                </p>
                            </div>
                        </div>
                        <div className="flex-align-center" style={{ gap: 8 }}>
                            <button
                                onClick={() => window.open(item?.signature?.url, '_blank')}
                                className="square-btn-box"
                            >
                                <img height={20} src={Images.showEye} alt="icon" />
                            </button>
                            <button
                                onClick={() => handleDownload(item?.signature)}
                                className="square-btn-box"
                            >
                                {loadDownload ?
                                    <MinLoader small={true} color={'#475467'} margin={'0'} position={'relative'} />
                                    :
                                    <img height={20} src={Images.downloadCloud} alt="icon" />
                                }
                            </button>
                        </div>
                    </div>

                    <div className="signature-detail-box" style={{ maxWidth: '30%' }}>
                        <div className="flex-align-center" style={{ gap: 4 }}>
                            <img src={Images.signatureDate} alt="icon" />
                            <p className="signature-detail-box-title">Date/Time:</p>
                        </div>
                        <p className="signature-detail-box-value">
                            {`${moment.utc(item?.signatureDate).format('MM/DD/YYYY')}, ${moment(item?.signatureDate).format('h:mm a')}`}

                        </p>
                    </div>

                    <div className="signature-detail-box" style={{ maxWidth: '100%' }}>
                        <div className="flex-align-center" style={{ gap: 4 }}>
                            <img src={Images.signatureLocation} alt="icon" />
                            <p className="signature-detail-box-title">Location:</p>
                        </div>
                        <p className="signature-detail-box-value">
                            {item?.signatureLocation?.formattedAddress ? item?.signatureLocation?.formattedAddress : 'Not Set'}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};