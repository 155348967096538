import React, { useEffect, useRef, useState } from 'react';
import { Images, SaveParams, useSecondaryModal } from 'utils';
import { ScheduleDetails } from 'fragments';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { MinLoader } from '../../../../components';
import { leftBarCommonStyle } from './styles';
import { appointmentService } from '../../../../store/appointment/appointment.service';

export const SearchInput = ({}) => {
    const { handleSubmit } = useForm({});
    const classes = leftBarCommonStyle();
    const location = useLocation();
    const checkIfSchedule = location?.pathname === '/schedule';
    const searchInputRef = useRef();
    const [load, setLoad] = useState(false);
    const { openSecondary } = useSecondaryModal();
    const [apptId, setApptId] = useState('');
    const [searchedId, setSearchedId] = useState('');
    const [backSearched, setBackSearched] = useState({ searchKey: '', searchValue: '' });
    const searchInputValue = checkIfSchedule ? searchedId : backSearched?.searchValue;
    const history = useHistory();
    const info = history?.location?.state;

    useEffect(() => {
        if (apptId) {
            openSecondary(<ScheduleDetails currentId={apptId} />);
            setApptId('');
        }
    }, [apptId]);

    useEffect(() => {
        if (info?.searchKey) {
            setBackSearched({
                searchKey: info?.searchKey,
                searchValue: info?.searchValue,
            });
        } else {
            setBackSearched({
                searchKey: '',
                searchValue: '',
            });
        }
    }, [info]);

    const debounced = useDebouncedCallback((name, value, searchKey) => {
            const params = {
                ...info,
            };
            params.limit = 100;
            params.skip = 0;
            params.page = 1;
            if (value) {
                params.searchKey = searchKey;
                params.searchValue = value;
            } else {
                delete params.searchKey;
                delete params.searchValue;
            }
            SaveParams(history, params);

        },
        500,
        { maxWait: 2000 },
    );

    const handleChange = (e, searchKey) => {
        if (e.target.value.startsWith(' ')) {
            return;
        }

        if (checkIfSchedule) {
            setSearchedId(e.target.value);
        } else {
            if (searchKey) {
                setSearchedId('');
                setBackSearched({
                    searchKey: searchKey,
                    searchValue: e.target.value,
                });
                debounced(e.target.name, e.target.value, searchKey);
            }
        }
    };

    const handleSearch = async () => {
        if (searchInputValue && checkIfSchedule) {
            setLoad(true);
            await appointmentService.getAppointmentService({
                searchKey: 'DISPLAY_ID',
                searchValue: searchedId,
            }).then((res) => {
                if (res?.data?.count === 1) {
                    setApptId(res?.data?.appts[0]?.id);
                }
            }).finally(() => {
                setLoad(false);
            });
        } else {
            searchInputRef.current.focus();
        }
    };


    const renderSearchPlaceholder = () => {
        if (location?.pathname === '/schedule' || location?.pathname === '/') {
            return 'Search by Appt ID';
        }
        if (location?.pathname === '/pendingClaims' || location?.pathname === '/claimPayments') {
            return 'Search by Claim, or Bill ID ';
        }
        if (location?.pathname === '/billedClaims') {
            return 'Search by Batch, Claim, or Bill ID ';
        }
        if (location?.pathname === '/invoices') {
            return 'Search by Invoice, or Bill ID';
        }
    };

    return (
        <form onSubmit={handleSubmit(handleSearch)}>
            <div className={classes.searchInputBoxStyle}>
                <input
                    ref={searchInputRef}
                    type={'search'}
                    value={searchInputValue}
                    onChange={(e) => handleChange(e, 'DISPLAY_ID')}
                    placeholder={renderSearchPlaceholder()}
                    autoComplete={'off'}
                />
                <button
                    className={classes.searchButtonStyle}
                    onClick={handleSearch}
                >
                    {load ?
                        <MinLoader
                            small={true}
                            margin={'0'} color={'#344054'} position="relative"
                        />
                        :
                        <img src={Images.searchIcon} alt="" />
                    }
                </button>
            </div>
        </form>
    );
};
