import { call, put, takeLatest } from 'redux-saga/effects';
import { authService } from './client.service';
import {
    CREATE_CLIENT,
    DELETE_CLIENT,
    EDIT_CLIENT,
    GET_CLIENT_BY_ID,
    GET_CLIENT_BY_ID_SUCCESS,
    GET_CLIENT_CONTACTS,
    GET_CLIENT_CONTACTS_SUCCESS,
    GET_CLIENT_ENROLLMENT,
    GET_CLIENT_ENROLLMENT_SUCCESS,
    GET_CLIENTS,
    GET_CLIENTS_SUCCESS,
    CREATE_CLIENT_CONTACT,
    EDIT_CLIENT_CONTACT,
    DELETE_CLIENT_CONTACT,
    GET_CLIENT_AUTHORIZATION,
    GET_CLIENT_AUTHORIZATION_SUCCESS,
    CREATE_CLIENT_ENROLLMENT,
    EDIT_CLIENT_ENROLLMENT,
    DELETE_CLIENT_ENROLLMENT,
    CREATE_CLIENT_AUTHORIZATION,
    DELETE_CLIENT_AUTHORIZATION,
    EDIT_CLIENT_AUTHORIZATION,
    GET_CLIENT_AUTHORIZATION_SERV_SUCCESS,
    GET_CLIENT_AUTHORIZATION_SERV,
    CREATE_CLIENT_AUTHORIZATION_SERV,
    EDIT_CLIENT_AUTHORIZATION_SERV,
    DELETE_CLIENT_AUTHORIZATION_SERV,
    CREATE_CLIENT_CONTACT_SUCCESS,
    GET_CLIENT_AUTHORIZATION_ERROR,
    GET_CLIENT_AUTHORIZATION_MOD_CHECK,
    GET_CLIENT_AUTHORIZATION_FILE_SUCCESS,
    CREATE_CLIENT_AUTHORIZATION_FILE_SUCCESS,
    EDIT_CLIENT_AUTHORIZATION_FILE_SUCCESS,
    DELETE_CLIENT_AUTHORIZATION_FILE_SUCCESS,
    GET_CLIENT_AUTHORIZATION_FILE,
    CREATE_CLIENT_AUTHORIZATION_FILE,
    EDIT_CLIENT_AUTHORIZATION_FILE,
    DELETE_CLIENT_AUTHORIZATION_FILE,
    ADD_FILES_TO_CLIENT_AUTH,
    REMOVE_FILES_FROM_CLIENT_AUTH,
    TERMINATE_CLIENT_ENROLLMENT,
    EDIT_FILE_NAME_OF_CLIENT_AUTH,
    CHANGE_ENROLLMENT_TYPE,
    UPLOAD_CLIENT_DOCUMENT,
    EDIT_CLIENT_DOCUMENT,
    DELETE_CLIENT_DOCUMENT,
    GET_CLIENT_DOCUMENT_SUCCESS,
    GET_CLIENT_DOCUMENT,
    ADD_CLIENT_ADDRESS,
    EDIT_CLIENT_ADDRESS,
    DELETE_CLIENT_ADDRESS,
    ASSIGN_STAFF,
    GET_ASSIGNED_STAFFS,
    GET_ASSIGNED_STAFFS_SUCCESS,
    REMOVE_STAFF,
    EDIT_CLIENT_STATUS,
} from './client.types';
import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';


function* getClients(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    try {
        const res = yield call(authService.getClientsService, action.payload);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: GET_CLIENTS_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

function* createClient(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.createClientService, action);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* deleteClient(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.deleteClientService, action);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put({
            type: GET_CLIENTS,
            payload: { status: 1, start: 0, end: 10 },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* editClient(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.editClientService, action);
        yield put({
            type: GET_CLIENT_BY_ID,
            payload: { id: action?.payload?.id, load: 'noload' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* getClientById(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    if (action.payload.load !== 'noload') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    try {
        const res = yield call(authService.getClientByIdService, action);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: GET_CLIENT_BY_ID_SUCCESS,
            payload: res.data,
        });
        // yield put({
        //    type: GET_AVAILABILITY_SCHEDULE_GLOBAL,
        //    payload: action.payload.id,
        // });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

function* getClientContacts(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(authService.getClientContactsService, action);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: GET_CLIENT_CONTACTS_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

function* createClientContact(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.createClientContactService, action);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put({
            type: CREATE_CLIENT_CONTACT_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* editClientContact(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.editClientContactService, action);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put({
            type: GET_CLIENT_CONTACTS,
            payload: { id: action.payload.paramsId },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* deleteClientContact(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.deleteClientContactService, action);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put({
            type: GET_CLIENT_CONTACTS,
            payload: { id: action.payload.paramsId },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* editClientStatus(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.setClientStatus, action?.payload?.id, action?.payload?.status);
        yield put(httpRequestsOnSuccessActions.appendSuccess(action?.type));
        const res = yield call(authService.getClientByIdService, action);
        yield put({
            type: GET_CLIENT_BY_ID_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** Client Enrollment */

function* getClientEnrollment(action) {
    if (action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(authService.getClientEnrollmentService, action);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: GET_CLIENT_ENROLLMENT_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

function* createClientEnrollment(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.createClientEnrollmentService, action);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put({
            type: GET_CLIENT_ENROLLMENT,
            payload: { id: action.payload.id },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* editClientEnrollment(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.editClientEnrollmentService, action);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put({
            type: GET_CLIENT_ENROLLMENT,
            payload: { id: action.payload.clientId, load: 'noLoad' },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* terminateClientEnrollment(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.terminateClientEnrollmentService, action?.payload?.enrollmentId, action?.payload?.body);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));

        yield put({
            type: GET_CLIENT_ENROLLMENT,
            payload: { id: action?.payload?.clientId, load: 'noLoad' },
        });
        yield put({
            type: GET_CLIENT_AUTHORIZATION,
            payload: { id: action?.payload?.clientId },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* deleteClientEnrollment(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.deleteClientEnrollmentService, action);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put({
            type: GET_CLIENT_ENROLLMENT,
            payload: { id: action.payload.clientId },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* changeEnrollmentType(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.changeEnrollmentTypeService, action?.payload);
        yield put({
            type: GET_CLIENT_ENROLLMENT,
            payload: { id: action.payload.clientId },
        });
        yield put({
            type: GET_CLIENT_AUTHORIZATION,
            payload: { id: action?.payload?.clientId },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** End */


function* getClientsAuthorizations(action) {
    if (action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(authService.getClientAuthorizationService, action.payload.id);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: GET_CLIENT_AUTHORIZATION_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        yield put({
            type: GET_CLIENT_AUTHORIZATION_ERROR,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

function* createClientsAuthorizations(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.createClientAuthorizationService, action);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put({
            type: GET_CLIENT_AUTHORIZATION,
            payload: { id: action.payload.id },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* editClientAuthorizations(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.editClientAuthorizationService, action);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));


        yield put({
            type: GET_CLIENT_AUTHORIZATION,
            payload: { id: action.payload.clientId, load: 'noLoad' },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* deleteClientAuthorizations(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.deleteClientAuthorizationService, action);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put({
            type: GET_CLIENT_AUTHORIZATION,
            payload: { id: action.payload.clientId },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

// authorization file

function* getClientsAuthorizationFile(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(
            authService.getClientAuthorizationFileService,
            action.payload.id,
        );
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: GET_CLIENT_AUTHORIZATION_FILE_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

function* createClientsAuthorizationFile(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    try {
        const res = yield call(authService.createClientAuthorizationFileService, action);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: CREATE_CLIENT_AUTHORIZATION_FILE_SUCCESS,
            payload: { id: action.payload.id },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* editClientAuthorizationFile(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    try {
        const res = yield call(authService.editClientAuthorizationFileService, action);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: EDIT_CLIENT_AUTHORIZATION_FILE_SUCCESS,
            payload: { id: action.payload.clientId },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* deleteClientAuthorizationFile(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    try {
        const res = yield call(authService.deleteClientAuthorizationFileService, action);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: DELETE_CLIENT_AUTHORIZATION_FILE_SUCCESS,
            payload: { id: action.payload.clientId },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

// end

function* getClientsAuthorizationsServ(action) {
    if (action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(authService.getClientAuthorizationServService, action);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: GET_CLIENT_AUTHORIZATION_SERV_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

function* createClientsAuthorizationsServ(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.createClientAuthorizationServService, action);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put({
            type: GET_CLIENT_AUTHORIZATION_SERV,
            payload: { id: action.payload.id },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* getClientsAuthorizationsModCheck(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.getClientAuthorizationServCheckModService, action);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* editClientAuthorizationsServ(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.editClientAuthorizationServService, action);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put({
            type: GET_CLIENT_AUTHORIZATION_SERV,
            payload: { id: action.payload.authID },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* deleteClientAuthorizationsServ(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.deleteClientAuthorizationServService, action);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put({
            type: GET_CLIENT_AUTHORIZATION_SERV,
            payload: { id: action.payload.authID, load:'noLoad' },
        });
        // const res = yield call(authService.getClientAuthorizationServService, action);
        // yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        // yield put({
        //     type: GET_CLIENT_AUTHORIZATION_SERV_SUCCESS,
        //     payload: res.data,
        // });

    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* addFilesToClientAuth(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.addFilesToClientAuthService, action.payload.authId, action.payload.files);
        const response = yield call(authService.getClientAuthorizationService, action?.payload?.clientId);
        yield put({
            type: GET_CLIENT_AUTHORIZATION_SUCCESS,
            payload: response?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* removeFilesFromClientAuth(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.removeFilesFromClientAuthService, action.payload.authId, action.payload.docId);
        const response = yield call(authService.getClientAuthorizationService, res.data?.clientId);
        yield put({
            type: GET_CLIENT_AUTHORIZATION_SUCCESS,
            payload: response?.data,
        });

        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        // yield put({
        //     type: GET_CLIENT_AUTHORIZATION,
        //     payload: { id: res.data?.clientId, load:'noLoad' },
        // });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* editFileNameOfClientAuth(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.editFileNameOfClientAuthService, action?.payload?.authId, action?.payload?.docId, action?.payload?.fileName);
        const response = yield call(authService.getClientAuthorizationService, res.data?.clientId);
        yield put({
            type: GET_CLIENT_AUTHORIZATION_SUCCESS,
            payload: response?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** Claim Documents */
function* uploadClientDocument(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.uploadService, action.payload.file, action.payload.id);
        yield put({
            type: GET_CLIENT_DOCUMENT,
            payload: { id: action?.payload?.id, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* editClientDocument(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.editService, action?.payload);
        yield put({
            type: GET_CLIENT_DOCUMENT,
            payload: { id: action?.payload?.id, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* getClientDocument(action) {
    if (action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(authService.getFilesService, action.payload.id);
        yield put({
            type: GET_CLIENT_DOCUMENT_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* deleteClientDocument(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.deleteFilesService, action.payload.clientId, action.payload.id);
        yield put({
            type: GET_CLIENT_DOCUMENT,
            payload: { id: action?.payload?.clientId, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** End */

/** Staff Documents */

function* addClientAddress(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.addClientAddressService, action.payload);
        yield put({
            type: GET_CLIENT_BY_ID,
            payload: { id: action?.payload?.id, load: 'noload' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* editClientAddress(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.editClientAddressService, action?.payload);
        yield put({
            type: GET_CLIENT_BY_ID,
            payload: { id: action?.payload?.id, load: 'noload' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* deleteClientAddress(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.deleteClientAddressService, action.payload);
        yield put({
            type: GET_CLIENT_BY_ID,
            payload: { id: action?.payload?.id, load: 'noload' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** End */

function* assignStaff(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.assignStaffService, action.payload?.body);
        yield put({
            type: GET_ASSIGNED_STAFFS,
            payload: { id: action?.payload?.body?.clientId, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* getAssignedStaff(action) {
    if (action.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.getAssignedStaffService, action.payload?.id);
        yield put({
            type: GET_ASSIGNED_STAFFS_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* removeClientStaff(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.removeClientStaffService, action?.payload?.staffId);
        yield put({
            type: GET_ASSIGNED_STAFFS,
            payload: { id: action?.payload?.clientId, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

export const watchClient = function* watchClientSaga() {
    yield takeLatest(GET_CLIENTS, getClients);
    yield takeLatest(CREATE_CLIENT, createClient);
    yield takeLatest(DELETE_CLIENT, deleteClient);
    yield takeLatest(EDIT_CLIENT, editClient);
    yield takeLatest(GET_CLIENT_BY_ID, getClientById);
    yield takeLatest(GET_CLIENT_CONTACTS, getClientContacts);
    yield takeLatest(CREATE_CLIENT_CONTACT, createClientContact);
    yield takeLatest(EDIT_CLIENT_CONTACT, editClientContact);
    yield takeLatest(DELETE_CLIENT_CONTACT, deleteClientContact);
    yield takeLatest(EDIT_CLIENT_STATUS, editClientStatus);

    /** Client Enrollment */
    yield takeLatest(GET_CLIENT_ENROLLMENT, getClientEnrollment);
    yield takeLatest(CREATE_CLIENT_ENROLLMENT, createClientEnrollment);
    yield takeLatest(EDIT_CLIENT_ENROLLMENT, editClientEnrollment);
    yield takeLatest(TERMINATE_CLIENT_ENROLLMENT, terminateClientEnrollment);
    yield takeLatest(DELETE_CLIENT_ENROLLMENT, deleteClientEnrollment);
    yield takeLatest(CHANGE_ENROLLMENT_TYPE, changeEnrollmentType);
    /** End */


    yield takeLatest(GET_CLIENT_AUTHORIZATION, getClientsAuthorizations);
    yield takeLatest(CREATE_CLIENT_AUTHORIZATION, createClientsAuthorizations);
    yield takeLatest(EDIT_CLIENT_AUTHORIZATION, editClientAuthorizations);
    yield takeLatest(DELETE_CLIENT_AUTHORIZATION, deleteClientAuthorizations);
    yield takeLatest(GET_CLIENT_AUTHORIZATION_SERV, getClientsAuthorizationsServ);
    yield takeLatest(CREATE_CLIENT_AUTHORIZATION_SERV, createClientsAuthorizationsServ);
    yield takeLatest(EDIT_CLIENT_AUTHORIZATION_SERV, editClientAuthorizationsServ);
    yield takeLatest(DELETE_CLIENT_AUTHORIZATION_SERV, deleteClientAuthorizationsServ);

    yield takeLatest(GET_CLIENT_AUTHORIZATION_FILE, getClientsAuthorizationFile);
    yield takeLatest(CREATE_CLIENT_AUTHORIZATION_FILE, createClientsAuthorizationFile);
    yield takeLatest(EDIT_CLIENT_AUTHORIZATION_FILE, editClientAuthorizationFile);
    yield takeLatest(DELETE_CLIENT_AUTHORIZATION_FILE, deleteClientAuthorizationFile);

    yield takeLatest(GET_CLIENT_AUTHORIZATION_MOD_CHECK, getClientsAuthorizationsModCheck);

    yield takeLatest(ADD_FILES_TO_CLIENT_AUTH, addFilesToClientAuth);
    yield takeLatest(REMOVE_FILES_FROM_CLIENT_AUTH, removeFilesFromClientAuth);
    yield takeLatest(EDIT_FILE_NAME_OF_CLIENT_AUTH, editFileNameOfClientAuth);

    /** Staff Documents */
    yield takeLatest(UPLOAD_CLIENT_DOCUMENT, uploadClientDocument);
    yield takeLatest(EDIT_CLIENT_DOCUMENT, editClientDocument);
    yield takeLatest(GET_CLIENT_DOCUMENT, getClientDocument);
    yield takeLatest(DELETE_CLIENT_DOCUMENT, deleteClientDocument);
    /** End */

    /** Staff Documents */
    yield takeLatest(ADD_CLIENT_ADDRESS, addClientAddress);
    yield takeLatest(EDIT_CLIENT_ADDRESS, editClientAddress);
    yield takeLatest(DELETE_CLIENT_ADDRESS, deleteClientAddress);
    /** End */

    /** Staff Assignment */
    yield takeLatest(ASSIGN_STAFF, assignStaff);
    yield takeLatest(GET_ASSIGNED_STAFFS, getAssignedStaff);
    yield takeLatest(REMOVE_STAFF, removeClientStaff);
    /** End */

};
