import axios from 'axios';

export const appointmentService = {
    /** Create, Edit Appointment */
    createAppointmentService: (body) => axios.post(`/appt`, body, { auth: true }),

    editAppointmentService: (body, id) => axios.patch(`/appt/${id}`, body, { auth: true }),

    editApptLockUnlockService: ({ list, type, params }) => axios.patch(`/appt/lock/${type}`, list, { auth: true, params:{...params} }),
    /** end */

    /** Get Appointment */
    getAppointmentService: (info) => axios.get(`/appt`, { auth: true, params: { ...info } }),

    getAppointmentByIdService: (id) => axios.get(`/appt/${id}`, { auth: true }),

    getAppointmentInformationService: (id) => axios.get(`/appt/${id}/accounting`, { auth: true }),

    changeCancelReasonReason: (id, reason) => axios.patch(`/appt/${id}/cancellationReason`, null,{ auth: true, params: reason }),
    /** end */

    /** Delete Appointment */
    deleteAppointmentService: (id) => axios.delete(`/appt/${id}`, { auth: true }),
    /** end */

    /** Appointment Status */
    setAppointmentStatusService: (id, statusName, reason) => {
        if (statusName === 'cancel') {
            return axios.patch(`/appt/${id}/cancel`, reason, { auth: true });
        } else {
            return axios.patch(`/appt/${id}/${statusName}`, null, { auth: true });
        }
    },

    removeApptFromBillService: (id, reason) => axios.delete(`/claim/appt/${id}`, { auth: true, params:{reason: reason} }),

    unRenderApptService: (id) => axios.patch(`/appt/${id}/unrender`, null, { auth: true }),
    /** end */

    /** Appointment Repeat */


    appointmentRepeatService: (body) => axios.post(`/appt/repeat`, body, { auth: true }),
    /** end */

    appendSignatureToAppmtService: (id, signature) => axios.post(`/appt/${id}/signature`, signature, { auth: true }),

    getClientStaffsService: (params) => axios.get(`/appt/staffs/suggestion`, {
        auth: true,
        params: { clientId: params?.clientId, serviceId: params?.serviceId, authServiceId: params?.authServiceId },
    }),

    manageNotesService: (id, params) => axios.post(`/appt/${id}/notes`, params, { auth: true }),

    connectReconnectService: (id, params) => axios.patch(`/appt/${id}/template/disconnect`, { }, { auth: true }),

    // connectReconnectService: (id, params) => axios.patch(`/appt/${id}/template/connect`, params, { auth: true }),

    checkApptOverlapping: (params, body) => axios.patch(`/appt/repeat/available`, body, { auth: true, params: params }),

    checkApptUpdateOverlapping: (params) => axios.get('/appt/check/update', { auth: true, params: params }),

    cancelApptService: (id) => axios.patch(`/appt/template/${id}/cancel`, null, { auth: true }),

    deleteAllApptService: (id) => axios.delete(`/appt/template/${id}`, { auth: true }),

    getApptStaffsService: ( ) => axios.get(`/appt/staffs/info`, { auth: true }),


    /** Appt Documents */

    uploadService: (file, id ) => axios.post(`/appt/${id}/documents`, {...file}, { auth: true }),

    editService: ({id, dockId, file} ) => axios.patch(`/appt/${id}/documents/${dockId}`, {...file}, { auth: true }),

    getFilesService: ( id ) => axios.get(`/appt/${id}/documents`, { auth: true }),

    deleteFilesService: (clientId, id ) => axios.delete(`/appt/${clientId}/documents/${id}`, { auth: true }),

    /** End */

    checkFrequencyService: (params) => axios.get(`/appt/frequency`, { auth: true, params: params }),

};
