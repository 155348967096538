import { SavePage } from 'utils';
import { HtmlTooltip } from './htmlTool';
import React from 'react';

export const handleSelectSort = (e, history, info) => {
    if (e.target.value === 'All') {
        const cleanList = {
            ...info
        }
        delete cleanList[e.target.name]
        SavePage(history, info, {...cleanList})
    } else {
        if (!e.target.value) {
            const filteredList = {
                ...info
            }
            delete filteredList[e.target.name]
            SavePage(history, info, {...filteredList})
        } else {
            const newObj = {
                ...info
            }
            newObj[e.target.name] = e.target.value
            SavePage(history, info, {...newObj})
        }
    }
}



export const handleSetName = (name, history, info) => {
    const anotherInfo = {...info}
    if (name) {
        anotherInfo.sortType = name
    } else {
        delete anotherInfo.sortType
    }
    SavePage(history, info, {...anotherInfo})
}


export const bigSizeText = (item) => {
    if (item?.bigSize) {
        return <HtmlTooltip
            title={<p>{item?.title}</p>}
            placement="top-end"
        >
            <p>
                {`${item?.title.slice(0, item?.bigSize)}...`}
            </p>
        </HtmlTooltip>;
    } else {
        return <p>{item?.title}</p>;
    }
};

export const smallSizeText = (item) => {
    if (item?.smallSize) {
        return <HtmlTooltip
            title={<p>{item?.title}</p>}
            placement="top-end"
        >
            <p>
                {`${item?.title.slice(0, item?.smallSize)}...`}
            </p>
        </HtmlTooltip>;
    } else {
        return item?.title;
    }
};