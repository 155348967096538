import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import { CreateChancel, DateTypeSelect, SelectTypeAutocomplete } from 'components';
import { useModal } from 'utils';
import { CsvService } from 'store';

export const DownloadClaimPaymentCsv = ({ status }) => {
    const [error, setError] = useState('');
    const [inputs, setInputs] = useState({});
    const [load, setLoad] = useState(false);
    const { close } = useModal();
    const fundingSourceList = useSelector((state) => state.fundingSource.fundingSourceList);
    const clientList = useSelector((state) => state.client.clientList);
    const history = useHistory();
    const info = history?.location?.state;

    useEffect(() => {
        if (info) {
            const params = {
                from: info?.from,
                to: info?.to,
                client : info?.client,
                payer : info?.payer,
            };
            setInputs(params);
        }
    }, [info]);
    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        error === e.target.name && setError('');
    };

    const handleExport = async () => {
        setLoad(true);
        let response = '';

        const params = {
            status: status,
            from: inputs?.from,
            to: inputs?.to,
            client: inputs?.client,
            payer: inputs?.payer,
        };

        try {
            response = await CsvService.getClaimPaymentCsv(params);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `claim-payment.csv`);
            document.body.appendChild(link);
            link.click();
            setLoad(false);
            close();
        } catch (e) {
            setLoad(false);
            close();
        }
    };

    return (
        <div className="download-payroll-csv-modal">
            <p className="download-modal-title">Download Claim Payment Data</p>
            <p className="download-modal-desc">
                Select filters for the claim payment data you wish to download. The fields will be pre-filled based on
                your
                current filters.
            </p>
            <div className="download-modal-body">
                <SelectTypeAutocomplete
                    title={'Funding Source'}
                    name={'payer'}
                    handleSelect={handleChange}
                    defaultValue={inputs?.payer}
                    list={fundingSourceList?.funders ? fundingSourceList?.funders : []}
                    renderValue={(i) => i?.name}
                />
                <SelectTypeAutocomplete
                    title={'Client'}
                    name={'client'}
                    handleSelect={handleChange}
                    defaultValue={inputs?.client}
                    list={clientList?.clients ? clientList?.clients : []}
                    renderValue={(i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`}
                />
                <div className="start-end-time-box">
                    <DateTypeSelect
                        type={'modalInput'}
                        name={'from'}
                        outLabel={'Start Date'}
                        handleGetDates={handleChange}
                        values={inputs}
                        max={inputs.to ? moment.utc(inputs.to).format('YYYY-MM-DD') : ''}
                    />
                    <DateTypeSelect
                        type={'modalInput'}
                        name={'to'}
                        outLabel={'End Date'}
                        handleGetDates={handleChange}
                        values={inputs}
                        min={inputs.from ? moment.utc(inputs.from).format('YYYY-MM-DD') : ''}
                    />
                </div>
            </div>

            <div>
                <CreateChancel
                    loader={load}
                    create={'Download CSV'}
                    chancel={'Cancel'}
                    onCreate={handleExport}
                    onClose={() => close()}
                    buttonWidth="300px"
                />
            </div>
        </div>
    );
};