export const ActiveInactiveStatus = (type) => {
    return type === 'Active' ? 'ACTIVE' :
        type === 'Inactive' ? 'INACTIVE' :
            type === 'On Hold' ? 'ON_HOLD' :
              type === 'On_hold' ? 'ON_HOLD' :
                type === 'Terminated' ? 'TERMINATED' :
                    type === 'FBA' ? 'FBA' :
                        type === 'FBS' ? 'FBS' :
                           type === 'Fba' ? 'FBS' :
                            type === 'Waitlist' ? 'WAIT_LIST' :
                            type === 'Wait_list' ? 'WAIT_LIST' :
                                type === 'Graduated' ? 'GRADUATED' :
                                    type;
};