import React from 'react';
import { Images } from 'utils';
import { IconAndTitle, PhoneRow, TextRow, StatusRow, FullNameRow } from 'components';
import { IconAndImage } from '../../components/table/tableRows';

export const staffHead = (deps) => {
    return [
        { name: 'fullName', title: 'Full Name', searchKey: 'NAME' },
        {
            name: 'department', title: 'Department', disabled: true,
            filterList: [
                { name: 'All', id: 'All' },
                { name: 'N/A', id: 'N/A' },
                ...deps,
            ],
        },
        { name: 'jobTitle', title: 'Job Title', disabled: true },
        { name: 'email', title: 'Email Address', searchKey: 'EMAIL', smallSize: 6, custom: false },
        { name: 'phone', title: 'Phone Number', searchKey: 'PHONE', custom: false, width: '150px' },
        {
            name: 'status', title: 'Status', disabled: true, width: '120px',
            filterList: [
                { name: 'All', id: 'All' },
                { name: 'Active', id: 'ACTIVE' },
                { name: 'Inactive', id: 'INACTIVE' },
            ],
        },
    ];
};
export const staffBody = [
    // { rowText: (item) =>
    //         <IconAndImage
    //             item={item}
    //             avatar={item?.avatar?.url ? item?.avatar.url : ''}
    //             title={`${item?.firstName} ${item?.lastName}${item?.middleName ? `, ${item?.middleName.slice(0,1)}.` : ''}`}
    //         />
    // },
    {
        rowText: (item) =>
            <div  className="info-and-avatar">
                <div className="avatar-box">
                    {item?.avatar?.url ?
                        <img src={item?.avatar?.url } alt={'avatar'} />
                        :
                        `${item?.firstName?.slice(0, 1)}${item?.lastName?.slice(0, 1)}`
                    }
                </div>
                <FullNameRow
                    wrapperStyle={{fontWeight: 600}}
                    firstName={item?.firstName} lastName={item?.lastName} middleName={item?.middleName}
                />
            </div>
    },
    {
        rowText: (item) => <TextRow
            name={item?.employment?.department?.name ? item?.employment?.department?.name : 'N/A'} textWidth={8} />,
    },
    { rowText: (item) => <TextRow name={item?.employment?.title?.name} textWidth={8} /> },
    { rowText: (item) => <TextRow name={item?.email} /> },
    { rowText: (item) => <PhoneRow phone={item?.phone} /> },
    { rowText: (item) => <StatusRow status={item?.status} /> },
];

export const ACTION_TYPE = 'GET_ADMINS';

export const staffNotYet = {
    title: 'No Staff Added Yet',
    subTitle: 'Start by adding staff members to manage your clients and services.',
    image: Images.noClientYet,
};
