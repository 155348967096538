import React from 'react';
import { useSelector } from 'react-redux';
import { CustomDateSelector, DateTypeSelector, SelectTypeAutocomplete } from 'components';

export const BillFiltersSelectors = ({ filters, setFilters }) => {
    const clientList = useSelector((state) => state.client.clientList);

    const handleChange = (e) => {
        const pushInfo = {
            ...filters,
        };

        if (e.target.value === 'All' || !e.target.value) {
            delete pushInfo[e.target.name];
        } else {
            pushInfo[e.target.name] = e.target.value;
        }
        setFilters({ ...pushInfo });
    };

    const changeDate = (e) => {
        const params = {
            ...filters,
            from: e?.from,
            to: e?.to,
        };
        setFilters(params);
    }

    return (
        <div className="generate-claim-filter-section">
            <SelectTypeAutocomplete
                placeholder={filters?.client ? '' : 'All'}
                name={'client'}
                handleSelect={handleChange}
                defaultValue={filters?.client}
                list={clientList?.clients ?
                    [{ id: 'All', firstName: 'All' }, ...clientList?.clients]
                    :
                    [{ id: 'All', firstName: 'All' }]
                }
                renderValue={(i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`}
                uiType={'tableFilter'}
                outLabel={'Client'}
                noError={true}
            />

            <DateTypeSelector
                startName={'from'}
                endName={'to'}
                outLabel={'DOS'}
                filters={filters}
                handleGetDates={changeDate}
            />
            {/*<CustomDateSelector  outLabel={'DOS'} handleGetDates={changeDate} filters={filters} />*/}
        </div>
    );
};
