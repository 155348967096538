import moment from 'moment/moment';

export const ChartTypes = {
    BAR: 'BAR',
    LINE: 'LINE',
    PIE: 'PIE',
};

export function getMonthsBetween(startDateStr, endDateStr) {
    const start = moment(startDateStr, 'YYYY-MM-DD');
    const end = moment(endDateStr, 'YYYY-MM-DD');
    const months = [];

    while (start.isSameOrBefore(end, 'month')) {
        months.push(start.format('YYYY-MM-DD'));
        start.add(1, 'month');
    }
    return months;
}

export function groupAndSumByYearAndMonth(data) {
    if (data) {
        const grouped = data?.reduce((acc, item) => {
            // Extract year and month from the date
            const date = new Date(item.date);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');

            const key = `${year}-${month}`;

            if (!acc[key]) {
                acc[key] = { count: 0, date: `${year}-${month}-01` };
            }

            acc[key].count += item.count;

            return acc;
        }, {});

        return Object.values(grouped);
    }
}


export function mapCountsToDateRange(dateRangeList, groupedCurrent) {
    const groupedMap = groupedCurrent.reduce((acc, item) => {
        acc[item.date] = item.count;
        return acc;
    }, {});

    return dateRangeList.map((date) => groupedMap[date] || null);
}


export function BarChartData(info, currentPeriod, prevPeriod) {
    if (info?.startDate && info?.endDate && currentPeriod && prevPeriod) {
        // Dates
        const monthsBetween = getMonthsBetween(info?.startDate, info?.endDate);
        const dates = monthsBetween?.map((date) => moment(date).format('MMM YY'));

        // Current data
        const groupedCurrent = groupAndSumByYearAndMonth(currentPeriod);
        const current = mapCountsToDateRange(monthsBetween, groupedCurrent);

        // Previous data
        const prevMonthsBetween = getMonthsBetween(info?.prevStartDate, info?.prevEndDate);
        const groupedPrev = groupAndSumByYearAndMonth(prevPeriod);
        const prev = mapCountsToDateRange(prevMonthsBetween, groupedPrev);

        return {
            dates, current, prev,
        };
    } else {
        return {
            dates: [], current: [], prev: [],
        };
    }
}


const getValueForDate = (date, data) => {
    const item = data.find(d => d.date === date);
    return item ? item.count : 0;
};

export function LineChartData(info, currentPeriod, prevPeriod) {
    if (info?.startDate && info?.endDate && currentPeriod && prevPeriod) {
        // Dates
        const monthsBetween = getMonthsBetween(info?.startDate, info?.endDate);

        // Current data
        const groupedCurrent = groupAndSumByYearAndMonth(currentPeriod);
        const groupedPrev = groupAndSumByYearAndMonth(prevPeriod);

        const newArray = monthsBetween?.map(date => {
            const current = getValueForDate(date, groupedCurrent);
            const previous = getValueForDate(date, groupedPrev);

            const newDate = new Date(date );
            newDate.setDate(newDate.getDate() + 2);

            return { date: newDate, Current: current, Previous: previous };
        });

        const lineChartDates = newArray
        return { lineChartDates };
    } else {
        return {
            lineChartDates: [],
        };
    }
}


