import React from 'react';
import { motion } from 'framer-motion';
import { LandingSvg, landingImages } from 'assets';
import { accessKeyLanding } from '../constants';
import { useWidth } from 'utils';
import { animationParams, animationVariants } from 'fragments';

export const AccessKey = () => {
    const width = useWidth();

    const accessKeyImage =
        width > 1280
            ? landingImages.accessKayImage
            : width > 374
                ? landingImages.accessKayImageTablet
                : landingImages.accessKayImageMobile;
    return (
        <div className='access-key-wrapper'>
            <div className='container'>
                <div className='access-key-block-box'>
                    <div className='access-key-left-box'>
                        <div className='mobile-app'>
                            Mobile App
                        </div>

                        <div style={{ overflow: 'hidden' }}>
                            <motion.h2
                                className='access-key-title'
                                {...animationParams}
                                variants={animationVariants.titleVariant}
                            >
                                Access Key Features Anytime, Anywhere key
                            </motion.h2>
                        </div>

                        <div style={{overflow:'hidden'}}>
                            <motion.p
                                className='access-key-description'
                                {...animationParams}
                                variants={animationVariants.cardsVariant}
                            >
                                Provide crucial support for therapists on the go with TherapyLake’s fully functional
                                mobile
                                app.
                            </motion.p>
                        </div>

                        <div className='access-key-content-wrapper'>
                            {accessKeyLanding.map((item, index) => (
                                <motion.div
                                    key={index} className='access-key-content'
                                    {...animationParams}
                                    variants={animationVariants.cardsVariant}
                                >
                                    <LandingSvg.CheckMarkSvg className='access-key-svg' />
                                    <p className='access-key-content-text'>
                                        <span style={{ fontWeight: '600' }}>{item.title} </span> {item.description}
                                    </p>
                                </motion.div>
                            ))}
                        </div>

                        <div className='gPlay-aStore-block'>
                            <a href='https://apps.apple.com/us/app/therapylake/id6450555754' target='_blank'>
                                <div className='google-play'>
                                    <img src={landingImages.googlePlay} alt='Google Play' />
                                </div>
                            </a>

                            <a href='https://play.google.com/store/apps/details?id=com.well.mo&hl=en_US&pli=1' target='_blank'>
                                <div className='app-store'>
                                    <img src={landingImages.appStore} alt='App Store' />
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className='access-key-imgBlock'>
                        <motion.img
                            src={accessKeyImage} alt='Access Image'
                            {...animationParams}
                            variants={animationVariants.cardsVariant}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

