import {
    createCredentialGlobal,
    getCredentialGlobal,
    editCredentialByIdGlobal,
    deleteCredentialByIdGlobal,
    createServiceGlobal,
    getServices,
    editServiceByIdGlobal,
    deleteServiceByIdGlobal,
    createDepartmentGlobal,
    getDepartments,
    editDepartmentByIdGlobal,
    deleteDepartmentByIdGlobal,
    createJobGlobal,
    getJobs,
    editJobByIdGlobal,
    deleteJobByIdGlobal,
    createPlaceGlobal,
    getPlaces,
    editPlaceByIdGlobal,
    deletePlaceByIdGlobal,
    editCoveredCredential,
    createCoveredCredential,
    selectCredential,
    removeCredential,
    createCompany,
    getCompany,
    editCompany,
    deleteCompany,
    getConnectCalendars,
    disconnectCalendar,
    calendarStatus,
} from './system.action';

export {systemReducer} from './system.reducer';
export {watchSystem} from './system.saga';

export const systemActions = {
    /** Service Type */
    createServiceGlobal,
    getServices,
    editServiceByIdGlobal,
    deleteServiceByIdGlobal,
    /** End */

    /** Credentials */
    createCredentialGlobal,
    getCredentialGlobal,
    editCredentialByIdGlobal,
    deleteCredentialByIdGlobal,
    createCoveredCredential,
    editCoveredCredential,
    selectCredential,
    removeCredential,
    /** End */

    /** Departments */
    createDepartmentGlobal,
    getDepartments,
    editDepartmentByIdGlobal,
    deleteDepartmentByIdGlobal,
    /** End */

    /** Job */
    createJobGlobal,
    getJobs,
    editJobByIdGlobal,
    deleteJobByIdGlobal,
    /** End */

    /** Place */
    createPlaceGlobal,
    getPlaces,
    editPlaceByIdGlobal,
    deletePlaceByIdGlobal,
    /** End */

    /** Company */
    createCompany,
    getCompany,
    editCompany,
    deleteCompany,
    /** End */

    /** Calendars */
    getConnectCalendars,
    disconnectCalendar,
    calendarStatus,
    /** End */
}