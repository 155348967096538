import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ValidationInput,
    CreateChancel,
    MaskInput,
    SelectTypeAutocomplete,
    CheckboxesTags, DateTypeSelect,
} from 'components';
import {
    EmailValidator,
    ErrorText,
    FindErrorItem,
    FindLoad,
    FindSuccessItem,
    getPhoneErrorText,
    hooksForErrors,
    isNotEmpty,
    languagesById, useModal,
} from 'utils';
import { adminActions, clientActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import moment from 'moment/moment';
import { ModalsTabs } from '../../../components/tabs';

const list = [
    { name: 'Male', id: 'MALE' },
    { name: 'Female', id: 'FEMALE' },
    { name: 'Other', id: 'OTHER' },
];

export const CreateClient = () => {
    const ACTION_TYPE = 'CREATE_CLIENT';
    const adminsList = useSelector((state) => state.admins.adminsList);
    const [error, setError] = useState('');
    const [inputs, setInputs] = useState({});
    const [step, setStep] = useState('first');
    const dispatch = useDispatch();
    const success = FindSuccessItem(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const backError = FindErrorItem(ACTION_TYPE);
    const phoneErrorMsg = getPhoneErrorText(inputs.phoneNumber);
    const emailErrorMsg = !EmailValidator.test(inputs.email) ? ErrorText.emailValid : '';
    const phoneErrorText = hooksForErrors.getPhoneError(error, backError, phoneErrorMsg);
    const emailErrorText = hooksForErrors.getEmailError(error, backError, emailErrorMsg);
    const { close } = useModal();

    useEffect(() => {
        dispatch(adminActions.getAdmins({}));
    }, []);

    useEffect(() => {
        if (success?.type === ACTION_TYPE) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
        if (backError && phoneErrorText && step === 'second') {
            setStep('first');
            setError(phoneErrorText);
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE))
        }
    }, [success, backError]);

    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        error === e.target.name && setError('');
    };

    const handleChangeLanguages = (e) => {
        setInputs((prevState) => ({ ...prevState, ['languages']: e }));
    };

    const handleCreate = () => {
        if (step === 'first') {
            const emailIsValid = inputs.email ? isNotEmpty(inputs.email) && EmailValidator.test(inputs.email) : true;
            const firstStepIsValid = isNotEmpty(inputs.firstName) && isNotEmpty(inputs.lastName) && emailIsValid;

            if (firstStepIsValid) {
                setStep('second');
            } else {
                const firstStepErrorText =
                    !isNotEmpty(inputs.firstName) ? 'firstName' :
                        !isNotEmpty(inputs.lastName) ? 'lastName' :
                            !emailIsValid ? emailErrorMsg :
                                '';
                setError(firstStepErrorText);
            }
        } else if (step === 'second') {
            const data = {
                firstName: inputs.firstName,
                middleName: inputs?.middleName ? inputs.middleName : null,
                lastName: inputs.lastName,
                birthday: inputs?.birthday,
                registrationDate: inputs?.registrationDate,
                status: 'ACTIVE',
            };
            inputs?.languages?.length ? data.languages = inputs?.languages?.map((i) => i?.id) : delete data.languages;
            data.phoneNumber = inputs?.phoneNumber ? parseInt(inputs?.phoneNumber.replace(/[^0-9]/g, '')) + '' : null;
            data.ethnicity = inputs?.ethnicity ? inputs?.ethnicity : null;
            data.familyLanguage = inputs?.familyLanguage ? inputs?.familyLanguage : null;
            data.email = inputs?.email ? inputs?.email : null;
            data.renderingProvider = inputs?.renderingProvider ? inputs?.renderingProvider : null;
            inputs.gender ? data.gender = inputs.gender : delete data.gender;
            data.renderingProvider = inputs?.renderingProvider ? inputs?.renderingProvider : null;
            dispatch(clientActions.createClient(data));
        }
    };

    return (
        <div style={{ width: 463 }}>
            <p style={{ textAlign: 'center' }} className="modal-header-title"> Add Client </p>
            {step && (
                <ModalsTabs steps={step} setStep={setStep} />
            )}
            <div className="modal-body-wrapper">
                {step === 'first' ? (
                    <div>
                        <ValidationInput
                            variant={'outlined'}
                            onChange={handleChange}
                            value={inputs.firstName}
                            type={'text'}
                            label={'First Name*'}
                            name="firstName"
                            typeError={error === 'firstName' ? `First name ${ErrorText.isRequired}` : ''}
                            Length={15}
                        />
                        <ValidationInput
                            variant={'outlined'}
                            onChange={handleChange}
                            value={inputs.middleName}
                            type={'text'}
                            label={'Middle Name'}
                            name="middleName"
                            typeError={error === 'middleName' ? ErrorText.field : ''}
                            Length={15}
                        />
                        <ValidationInput
                            variant={'outlined'}
                            onChange={handleChange}
                            value={inputs.lastName}
                            type={'text'}
                            label={'Last Name*'}
                            name="lastName"
                            typeError={error === 'lastName' ? `Last name ${ErrorText.isRequired}` : ''}
                            Length={15}
                        />
                        <ValidationInput
                            validator={EmailValidator}
                            variant={'outlined'}
                            name={'email'}
                            type={'email'}
                            label={'Email Address'}
                            typeError={emailErrorText}
                            value={inputs?.email}
                            onChange={handleChange}
                        />
                        <MaskInput
                            name="phoneNumber"
                            label="Phone Number"
                            value={inputs.phoneNumber}
                            handleChange={handleChange}
                            error={phoneErrorText}
                        />
                        <SelectTypeAutocomplete
                            loadType={'GET_ADMINS'}
                            title={'Rendering Provider'}
                            name={'renderingProvider'}
                            handleSelect={handleChange}
                            defaultValue={inputs?.renderingProvider}
                            list={adminsList?.staffs?.length ? adminsList?.staffs : []}
                            error={error}
                            typeError={error === 'staff' ? ErrorText.selectField : ''}
                            renderValue={(i) => `${i?.firstName} ${i?.lastName}`}
                        />
                    </div>
                ) : (
                    <div>
                        <SelectTypeAutocomplete
                            title={'Gender'}
                            name={'gender'}
                            handleSelect={handleChange}
                            defaultValue={inputs?.gender}
                            list={list}
                            error={error}
                            typeError={error === 'gender' ? ErrorText.selectField : ''}
                            renderValue={(i) => i?.name}
                        />
                        <DateTypeSelect
                            type={'modalInput'}
                            name={'birthday'}
                            outLabel={'Date of Birth'}
                            handleGetDates={handleChange}
                            values={inputs}
                            max={moment.utc().format('YYYY-MM-DD')}
                        />
                        <ValidationInput
                            variant={'outlined'}
                            onChange={handleChange}
                            value={inputs.ethnicity}
                            label={'Ethnicity'}
                            name="ethnicity"
                            typeError={error === 'ethnicity' ? ErrorText.field : ''}
                        />
                        <CheckboxesTags
                            name="languages"
                            handleChange={handleChangeLanguages}
                            renderValue={(i) => i?.label}
                            value={inputs?.languages || []}
                            permissionsList={languagesById || []}
                            label={'Language'}
                            uiType={'modalCheckboxSelect'}
                        />
                        <SelectTypeAutocomplete
                            title={'Family Language'}
                            name={'familyLanguage'}
                            handleSelect={handleChange}
                            defaultValue={inputs?.familyLanguage}
                            list={languagesById || []}
                            error={error}
                            renderValue={(i) => i?.label}
                        />
                        <DateTypeSelect
                            type={'modalInput'}
                            name={'registrationDate'}
                            outLabel={'Registration Date'}
                            handleGetDates={handleChange}
                            values={inputs}
                        />
                    </div>
                )}
            </div>
            <CreateChancel
                loader={!!loader.length}
                create={step === 'first' ? 'Next' : 'Add'}
                chancel={'Cancel'}
                onCreate={handleCreate}
                onClose={close}
                buttonWidth="224px"
            />
        </div>
    );
};
