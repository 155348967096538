import React from 'react';
import { useHistory } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { agingEnums, SaveParams } from 'utils';

export const Aging = ({ aging }) => {
    const history = useHistory();
    const info = history?.location?.state;

    const handlePush = (path) => {
        const params = {
            ...info,
        };

        if (path === info?.agingType) {
            delete params.agingType;
        } else {
            params.agingType = path;
        }
        SaveParams(history, { ...params });
    };

    return (
        <div className="aging-section-box">

            <button
                style={info?.agingType === agingEnums.current ? { boxShadow: '0px 0px 0px 4px #D1FADF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)' } : {}}
                className="green-zone"
                onClick={() => handlePush(agingEnums.current)}
            >
                <span>0-30 days: </span>
                <NumericFormat
                    className="zone-amount"
                    value={aging?.currentAmount ? aging?.currentAmount : 0}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                />
                <div className="badge">{aging?.currentCount ? aging?.currentCount : 0}</div>
            </button>
            <button
                style={info?.agingType === agingEnums.due ? { boxShadow: '0px 0px 0px 4px #FEF0C7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)' } : {}}
                className="orange-zone"
                onClick={() => handlePush(agingEnums.due)}
            >
                <span>31-60 days:</span>
                <NumericFormat
                    className="zone-amount"
                    value={aging?.dueAmount ? aging?.dueAmount : 0}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                />
                <div className="badge">{aging?.dueCount ? aging?.dueCount : 0}</div>
            </button>
            <button
                style={info?.agingType === agingEnums.pastDue ? { boxShadow: '0px 0px 0px 4px #FEE4E2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)' } : {}}
                className="red-zone"
                onClick={() => handlePush(agingEnums.pastDue)}
            >
                <span>61+ days:</span>
                <NumericFormat
                    className="zone-amount"
                    value={aging?.pastDueAmount ? aging?.pastDueAmount : 0}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                />
                <div className="badge">{aging?.pastDueCount ? aging?.pastDueCount : 0}</div>
            </button>
        </div>
    );
};