import {
    DateRow,
    InactivateBtb,
    InactivatedInfo,
    LinkRow,
    MinLoader,
    PhoneRow,
    SimpleTooltip,
    TextRow,
    UnitsRow,
} from 'components';
import {
    ActiveInactiveStatusReverse, Colors,
    hooksForTable,
    Images,
    makeCapitalize,
    PermissionList,
    RolePermission,
} from 'utils';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { clientCommonCoreStyle } from './common/core/styles';
import { UnitsRowByHour } from '../../../../components/table/tableRows';
import toolInfoIcon from '../../../../assets/images/icons/toolInfoIcon.svg';

export function getGeneralInfo(info) {
    return [
        { title: 'First Name', value: makeCapitalize(info?.firstName) },
        { title: 'Middle Name', value: makeCapitalize(info?.middleName) },
        { title: 'Last Name', value: makeCapitalize(info?.lastName) },
        { title: 'Code', value: info?.code },
        { title: 'Rendering Provider', value: info?.renderingProviderName ? info?.renderingProviderName : 'Not Set' },

        // {title: "Status", value: ActiveInactiveStatusReverse(info?.status)},
        // {title: "Status Date", value: moment(info?.statusDate).format("MM/DD/YYYY")},
        {
            title: { first: 'Status', second: '' },
            value: {
                first: ActiveInactiveStatusReverse(info?.status),
                second: '',
                // second: moment(info?.statusDate).format("MM/DD/YYYY") ,
            },
        },
        { title: 'Status Date', value: info?.statusDate ? moment.utc(info?.statusDate).format('MM/DD/YYYY') : '' },
        { title: 'Reason', value: info?.statusReason },

    ].filter((item) => !!item.value);
}

export function getOtherDetails(info) {
    return [
        { title: 'Gender', value: info?.gender },
        {
            title: 'Date of Birth',
            value: info?.birthday && moment.utc(info?.birthday).format('MM/DD/YYYY'),
        },
        {
            title: 'Phone Number',
            value: info?.phoneNumber,
        },
        {
            title: 'Age',
            value:
                info?.birthday &&
                new Date().getFullYear() - new Date(info.birthday).getFullYear(),
        },
        { title: 'Ethnicity', value: info?.ethnicity },
        { title: 'Language', value: info?.language },
        { title: 'Family Language', value: info?.familyLanguage },
        {
            title: 'Registration Date:',
            value: info?.registrationDate && moment.utc(info?.registrationDate).format('MM/DD/YYYY'),
        },
        // { title: "Enrollment Date", value: info?.enrolmentDate ? moment(info?.enrolmentDate).format('MM/DD/YYYY') : 'N/A'},

    ].filter((item) => !!item.value);
}

// Contact Table config

export const contactHead = [
    { name: 'firstName', title: 'First Name', smallSize: 5, custom: false },
    { name: 'lastName', title: 'Last Name', smallSize: 4, custom: false },
    { name: 'relationship', title: 'Relationship', custom: false, smallSize: 4 },
    { name: 'address', rowName: 'formattedAddress', title: 'Address', custom: false },
    { name: 'phoneNumber', title: 'Phone Number', custom: false, smallSize: 4, width: '150px' },
    { name: 'email', title: 'Email Address', custom: false, smallSize: 4 },
    RolePermission([PermissionList.CLIENT_CONTACT_MANAGE?.code]) &&
    { name: 'action', title: 'Action', custom: false, disabled: true, width: '100px' },
];

export const contactBody = [
    { rowText: (item) => <TextRow name={item?.firstName} /> },
    { rowText: (item) => <TextRow name={item?.lastName} /> },
    { rowText: (item) => <TextRow name={item?.relationship} /> },
    { rowText: (item) => <TextRow name={item?.address?.formattedAddress} /> },
    { rowText: (item) => <PhoneRow phone={item?.phoneNumber} /> },
    { rowText: (item) => <TextRow name={item?.email} /> },
    RolePermission([PermissionList.CLIENT_CONTACT_MANAGE?.code]) &&
    {
        button: (cb) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    style={{ cursor: 'pointer', marginRight: '16px' }}
                    src={Images.edit}
                    alt="edit"
                    onClick={() => cb('editContact')}
                />
                <img
                    style={{ cursor: 'pointer' }}
                    src={Images.remove}
                    alt="remove"
                    onClick={() => cb('deleteContact')}
                />
            </div>
        ),
        notClickable: true,
    },
];

export const ACTION_TYPE = 'GET_CLIENT_CONTACTS';

export const contactNotYet = {
    title: 'No Contact Information',
    subTitle: 'Add a contact person to stay connected with your client.',
    image: Images.noContact,
};


export const authorizationHead = [
    { name: 'service', rowName: 'cptCode', title: 'Service Code', smallSize: 7, custom: false },
    { name: '', title: 'Charge Rates', smallSize: 6, custom: false, disabled: true },
    { name: 'totalUnits', title: 'Total Units', smallSize: 5, custom: false },
    { name: 'completedUnits', title: 'Completed ', smallSize: 5, custom: false },
    { name: 'reservedUnits', title: 'Reserved', custom: false, smallSize: 7 },
    { name: 'availableUnits', title: 'Available', smallSize: 5, custom: false },
    { name: 'availableUnits', title: 'Billed', smallSize: 5, custom: false },
    { name: '', title: '% Utilization', smallSize: 5, bigSize: 10, custom: false, disabled: true },
    RolePermission([PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code]) &&
    { name: '', title: 'Action', custom: false, disabled: true, width: '100px' },
];

const renderModifiers = (authService) => {
    const { showDashIfEmpty } = hooksForTable;

    const authModifiers =
        authService?.chargeRates?.length > 1
            ? authService?.chargeRates
                ?.filter((item) => !!item)
                .map((item, index, arr) => {
                    if (index === arr.length - 1) {
                        return item.name;
                    }
                    return item.name;
                }).join(', ')
            : authService?.chargeRates?.[0]?.name;

    const authModifiersDisplay = showDashIfEmpty(authModifiers);
    // !!authService?.default ? !!authModifiers ? showDashIfEmpty(`default, ${authModifiers}`) : showDashIfEmpty("default") : showDashIfEmpty(authModifiers);
    return authModifiersDisplay;
};


export const authorizationBody = [
    { rowText: (item) => <TextRow name={item?.service?.cptCode} textWidth={10} /> },
    { rowText: (item) => <TextRow name={renderModifiers(item)} textWidth={10} /> },
    { rowText: (item) => <UnitsRowByHour info={item?.totalUnits} size={item?.service?.unitSize} textWidth={10} /> },
    { rowText: (item) => <UnitsRowByHour info={item?.completedUnits} size={item?.service?.unitSize} textWidth={10} /> },
    { rowText: (item) => <UnitsRowByHour info={item?.reservedUnits} size={item?.service?.unitSize} textWidth={10} /> },
    { rowText: (item) => <UnitsRowByHour info={item?.availableUnits} size={item?.service?.unitSize} textWidth={10} /> },
    { rowText: (item) => <UnitsRowByHour info={item?.billedUnits} size={item?.service?.unitSize} textWidth={10} /> },

    {
        rowText: (item) => {
            const { getValueByFixedNumber } = hooksForTable;
            const percentUtilization = getValueByFixedNumber(
                (item?.completedUnits / item?.totalUnits) * 100,
            );
            return <div className="percentage-box-style" style={{ marginLeft: '' }}>
                <p className="percent-text-style">{`${percentUtilization}%`}</p>
                <CircularProgress variant="determinate" value={percentUtilization} size={24} />
            </div>;
        },
    },
    RolePermission([PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code]) &&
    {
        button: (cb, item) => (
            item?.deactivatedDate ?
                <InactivatedInfo date={item?.deactivatedDate} title={item?.deactivatedReason} />
                :
                <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                     <InactivateBtb handleClick={() => cb('inactivate')} />
                    <img
                        style={{ cursor: 'pointer', marginRight: '16px' }}
                        src={Images.edit}
                        alt="edit"
                        onClick={() => cb('edit')}
                    />
             </div>
        ),
        notClickable: true,
    },
];

export const AUTH_ACTION_TYPE = 'GET_CLIENT_AUTHORIZATION_SERV';


export const assignmentHead = [
    { name: 'staffFullName', title: 'Staff member', custom: false },
    { name: 'staffRole', title: 'Role', custom: false },
    { name: '', title: 'Assignment Date', custom: false },
    { name: 'action', title: 'Action', custom: false, disabled: true, width: '100px' },
];

export const assignmentBody = [
    {
        rowText: (item) => <LinkRow
            name={item?.staffFullName ? item.staffFullName : 'N/A'}
            href={RolePermission([PermissionList.STAFF_READ.code]) ? `/staff/${item?.staffId}` : ''}
            checkPermission={item?.staffId ? RolePermission([PermissionList.STAFF_READ.code]) : null}
        />,
    },
    { rowText: (item) => <TextRow name={item?.staffRole} textWidth={10} /> },
    { rowText: (item) => <DateRow date={item?.assignmentDate}  /> },
    {
        button: (cb) => (
            <div onClick={() => cb('delete')} style={{ height: '22px' }}>
                <img src={Images.remove} alt="edit" />
            </div>
        ),
        notClickable: true,
    },
];

export const staffAssignmentNotYet = {
    title: 'No Staff Assigned',
    subTitle: 'Assign staff to get started.',
    image: Images.noContact,
};

export const ASSIGN_ACTION_TYPE = 'GET_ASSIGNED_STAFFS';

export const CLIENT_STATUSES = [
    { label: 'Active', id: 'ACTIVE' },
    { label: 'Inactive', id: 'INACTIVE' },
    { label: 'FBA', id: 'FBA' },
    { label: 'On Hold', id: 'ON_HOLD' },
    { label: 'Waitlist', id: 'WAIT_LIST' },
    { label: 'Terminated', id: 'TERMINATED' },
    { label: 'Graduated', id: 'GRADUATED' },
];

export const authServiceNotYet = {
    title: 'No Authorized Services',
    subTitle: 'Add services to specify what is approved for this client.',
    image: Images.noNote,
    height: '300px',
};
