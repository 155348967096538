import {
    DateRowStartEnd,
    TextRow,
    PriceRow,
    LinkRow,
    ApptFlags,
    SimpleTooltip, DisplayIdRow, DateRow, Aging,
} from 'components';
import { Checkbox } from '@material-ui/core';
import { agingEnumsSelector, Images, PermissionList, RolePermission } from 'utils';
import React from 'react';
import { AgingRow, AgingRowBackground } from '../../../components/table/tableRows';

const label = { inputProps: { 'aria-label': 'Color switch demo' } };

export const pendingClaimHead = (handleSelect, fullList, selected) => {
    return [
        RolePermission([PermissionList.CLAIMS_UPDATE?.code]) &&
        {
            renderView: <SimpleTooltip
                title={<p>Select All</p>}
                placement="top-start"
            >
                <Checkbox
                    {...label}
                    onClick={(e) => handleSelect(e, '', 'all')}
                    name={'checkBox'}
                    style={{ padding: 0, color: '#347AF0' }}
                    checked={fullList?.length === selected?.length}
                />
            </SimpleTooltip>,
            width: '50px',
        },
        { name: 'displayId', searchKey: 'DISPLAY_ID', title: 'Claim ID', custom: false, width: '220px' },
        { name: 'dateRange', title: 'DOS', icon: 'date', disabled: true, custom: false, width: '200px' },
        { name: 'funder', title: 'Funding Source' },
        { name: 'client', title: 'Client Code' },
        { name: 'totalAmount', title: 'Billed Amount', custom: false },
    ];
};

export const pendingClaimBody = (handleSelect, fullList, selected) => {
    return [
        RolePermission([PermissionList.CLAIMS_UPDATE?.code]) &&
        {
            button: (handle, item) => (
                <SimpleTooltip
                    title={<p>Select</p>}
                    placement="top-start"
                >
                    <Checkbox
                        {...label}
                        onClick={(e) => handleSelect(e, item)}
                        disabled={item?.incompleteUnitFlag}
                        // disabled={item?.incompleteUnitFlag || item?.updateFlag}
                        name={'checkBox'}
                        style={{
                            padding: 0,
                            color: '#347AF0',
                            opacity: item?.incompleteUnitFlag ? 0.5 : 1,
                            // opacity: item?.incompleteUnitFlag || item?.updateFlag ? 0.5 : 1,
                        }}
                        checked={selected?.includes(item?.id)}
                    />
                </SimpleTooltip>
            ), notClickable: true,
        },
        {
            rowText: (item) =>
                <ApptFlags
                    id={item?.displayId}
                    updateFlag={item?.updateFlag}
                    incomplete={item?.incompleteUnitFlag}
                    removed={item?.removedFromClaimFlag}
                />,
        },
        { rowText: (item) => <DateRowStartEnd start={item?.dos?.early} end={item?.dos?.latest} /> },
        {
            rowText: (item) => <LinkRow
                name={item?.funderName ? item.funderName : 'N/A'}
                href={RolePermission([PermissionList.FS_READ.code]) ? `/fundingSource/${item?.funderId}` : ''}
                checkPermission={item?.funderId ? RolePermission([PermissionList.FS_READ.code]) : null}
            />,
        },
        {
            rowText: (item) => <LinkRow
                name={item?.clientCode ? item?.clientCode : 'N/A'}
                href={RolePermission([PermissionList.CLIENT_READ?.code]) ? `/client/${item?.clientId}` : ''}
                checkPermission={item?.clientCode ? RolePermission([PermissionList.CLIENT_READ?.code]) : null}
            />, notClickable: true,
        },
        { rowText: (item) => <PriceRow info={item?.billedAmount ? item?.billedAmount : 0} /> },
    ];
};

export const ACTION_TYPE = 'GET_CLAIMS';

export const pendingClaimNotYet = {
    title: 'No Pending Claims',
    subTitle: 'There are no claims waiting for submission or processing at this time.',
    image: Images.noPendingClaim,
};

export const billedClaimNotYet = {
    title: 'No Billed Claims',
    subTitle: 'There are no claims that have been billed. Once claims are submitted, they will be listed here.',
    image: Images.noPendingClaim,
};

export const claimTabEnums = {
    BY_BATCH: 'BY_BATCH',
    BY_CLAIM: 'BY_CLAIM',
};
export const claimTabTypes = [
    { title: 'By Batch', tabValue: claimTabEnums.BY_BATCH },
    { title: 'By Claim', tabValue: claimTabEnums.BY_CLAIM },
];


export const submittedByClaimHead =
    [
        { name: 'displayId', searchKey: 'DISPLAY_ID', title: 'Claim ID', custom: false },
        { name: 'dateRange', title: 'DOS', iconType: 'date', disabled: true, width: '200px' },
        { name: '', title: 'Sub. Date', disabled: true, custom: false,  width: '100px'  },
        // { name: '', title: 'Submitted Date', disabled: true, custom: false,  width: '100px'  },
        { name: 'funder', rowName: 'name', title: 'Funding Source', disabled: true },
        { name: 'client', rowName: 'code', title: 'Client Code', disabled: true,  width: '140px'  },
        { name: '', title: 'Billed AMT', custom: false },
        { name: '', title: 'Allowed AMT', custom: false },
        { name: '', title: 'Paid AMT', custom: false },
        { name: 'aging', title: 'Aging', width: '100px', iconType: 'date',
            // filterList: agingEnumsSelector,
        },
    ];

export const submittedByClaimBody = [
    { rowText: (item) => <DisplayIdRow id={item?.displayId} /> },
    { rowText: (item) => <DateRowStartEnd start={item?.dos?.early} end={item?.dos?.latest} /> },
    { rowText: (item) => <DateRow date={item?.submittedDate} /> },
    {
        rowText: (item) => <LinkRow
            name={item?.funderName ? item.funderName : 'N/A'}
            href={RolePermission([PermissionList.FS_READ.code]) ? `/fundingSource/${item?.funderId}` : ''}
            checkPermission={item?.funderId ? RolePermission([PermissionList.FS_READ.code]) : null}
        />,
    },
    {
        rowText: (item) => <LinkRow
            name={item?.clientCode ? item?.clientCode : 'N/A'}
            href={RolePermission([PermissionList.CLIENT_READ?.code]) ? `/client/${item?.clientId}` : ''}
            checkPermission={item?.clientCode ? RolePermission([PermissionList.CLIENT_READ?.code]) : null}
        />, notClickable: true,
    },
    { rowText: (item) => <PriceRow info={item?.billedAmount ? item?.billedAmount : 0} /> },
    { rowText: (item) => <PriceRow info={item?.allowedAmount ? item?.allowedAmount : 0} /> },
    { rowText: (item) => <PriceRow info={item?.paidAmount ? item?.paidAmount : 0} /> },

    { rowText: (item) => <AgingRow count={item?.aging }/>, padding: '0' },
    // { rowText: (item) => <div>{item?.aging ? item?.aging  : 0}</div>, background: AgingRowBackground(item?.aging) },
];


export const submittedHeader = [
    { title: 'Batch ID', name: 'displayId', searchKey: 'DISPLAY_ID', custom: false, width: '15%' },
    { title: 'Submitted Date', name: 'submittedDate', custom: false, disabled: true, width: '20%' },
    { title: 'Submitted By', name: 'submittedByFullName', disabled: true, custom: false, width: '25%' },
    { title: 'DOS', name: 'dos', custom: false, disabled: true, width: '20%' },
    { title: 'Claims Form', name: 'claimsFormsFile', disabled: true, custom: false, width: '20%' },
];