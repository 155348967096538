import { ReactComponent as FooterLogo } from './images/icons/FooterLogo.svg';
import { ReactComponent as SmsSvg } from './images/icons/sms.svg';
import { ReactComponent as LockPassSvg } from './images/icons/LockPass.svg';
import { ReactComponent as CheckSvg } from './images/icons/CheckSvg.svg';
import { ReactComponent as CloseSvg } from './images/icons/CloseSvg.svg';
import { ReactComponent as EyeSvg } from './images/icons/EyeSvg.svg';
import { ReactComponent as EyeHideSvg } from './images/icons/EyeHideSvg.svg';
import { ReactComponent as CheckEmailSvg } from './images/icons/CheckEmailSvg.svg';
import { ReactComponent as BookHelpSvg } from './images/icons/BookHelpSvg.svg';
import { ReactComponent as ArrowRight2Svg } from './images/icons/ArrowRight2Svg.svg';
import { ReactComponent as NotificationsActiveSvg } from './images/icons/NotificationsActiveSvg.svg';

import MenuIcon from './images/landingPage/topBar/menuIcon.svg';
import MenuCloseIcon from './images/landingPage/topBar/menuCloseIcon.svg';
import ClientMgmt from './images/landingPage/home/clientMgmt.svg';
import ClientMgmtMark from './images/landingPage/home/clientMgmtMark.svg';
import ClientMgmtScreen from './images/landingPage/home/clientMgmtScreen.png';
import StaffMgmt from './images/landingPage/home/staffMgmt.png';
import StaffMgmtMark from './images/landingPage/home/staffMgmtMark.png';
import StaffMgmtScreen from './images/landingPage/home/staffMgmtScreen.png';
import FSMgmt from './images/landingPage/home/fsMgmt.png';
import FSMgmtMark from './images/landingPage/home/fsMgmtMark.png';
import FSMgmtScreen from './images/landingPage/home/fsMgmtScreen.png';
import AccessMgmt from './images/landingPage/home/accessMgmt.png';
import AccessMgmtMark from './images/landingPage/home/accessMgmtMark.png';
import AccessMgmtScreen from './images/landingPage/home/accessMgmtScreen.png';
import Scheduling from './images/landingPage/home/scheduling.svg';
import SchedulingMark from './images/landingPage/home/schedulingMark.png';
import SchedulingScreen from './images/landingPage/home/schedulingScreen.png';
import Billing from './images/landingPage/home/billing.svg';
import BillingMark from './images/landingPage/home/billingMark.svg';
import BillingScreen from './images/landingPage/home/billingScreen.png';
import CustomerOne from './images/landingPage/home/customerOne.png';
import CustomerTwo from './images/landingPage/home/customerTwo.png';
import Quotes from './images/landingPage/home/quotes.svg';
import LeftArrow from './images/landingPage/home/leftArrow.svg';
import RightArrow from './images/landingPage/home/rightArrow.svg';
import empowering from './images/landingPage/landingImages/empowering.png';
import whyTherapyCard1 from './images/landingPage/landingImages/whyTherapyCard1.png';
import whyTherapyCard2 from './images/landingPage/landingImages/whyTherapyCard2.png';
import whyTherapyCard3 from './images/landingPage/landingImages/whyTherapyCard3.png';
import whyTherapyCard4 from './images/landingPage/landingImages/whyTherapyCard4.png';
import technologicalImg from './images/landingPage/landingImages/technologicalImg.png';
import accessKayImage from './images/landingPage/landingImages/accessKayImage.png';
import accessKayImageTablet from './images/landingPage/landingImages/accessKayImageTablet.png';
import accessKayImageMobile from './images/landingPage/landingImages/accessKayImageMobile.png';
import appStore from './images/landingPage/landingImages/appStore.png';
import googlePlay from './images/landingPage/landingImages/googlePlay.png';
import customerOne from './images/landingPage/landingImages/customerOne.png';
import customerTwo from './images/landingPage/landingImages/customerTwo.png';
import backInfinityFeatures from './images/landingPage/landingImages/backInfinityFeatures.png';
import { ReactComponent as ArrowRightSvg } from './images/icons/arrowRight.svg';
import { ReactComponent as PlaySvg } from './images/icons/PlaySvg.svg';
import { ReactComponent as EMRSvg } from './images/landingPage/landingImages/EMRSvg.svg';
import { ReactComponent as ScheduleSvg } from './images/landingPage/landingImages/ScheduleSvg.svg';
import { ReactComponent as BillingSvg } from './images/landingPage/landingImages/BillingSvg.svg';
import { ReactComponent as PostingSvg } from './images/landingPage/landingImages/PostingSvg.svg';
import { ReactComponent as CredentialingSvg } from './images/landingPage/landingImages/CredentialingSvg.svg';
import { ReactComponent as IntegrationsSvg } from './images/landingPage/landingImages/IntegrationsSvg.svg';
import { ReactComponent as AdvancedSvg } from './images/landingPage/landingImages/AdvancedSvg.svg';
import { ReactComponent as MobileAppSvg } from './images/landingPage/landingImages/MobileAppSvg.svg';
import { ReactComponent as InfinitySmallSvg } from './images/landingPage/landingImages/InfinitySmallSvg.svg';
import { ReactComponent as StarOrangeSvg } from './images/landingPage/landingImages/StarOrangeSvg.svg';
import { ReactComponent as ArrowRightCarousel } from './images/landingPage/landingImages/ArrowRightCarousel.svg';
import { ReactComponent as ArrowLeftCarousel } from './images/landingPage/landingImages/ArrowLeftCarousel.svg';
import { ReactComponent as Checkmark } from './images/landingPage/landingImages/Checkmark.svg';
import { ReactComponent as CheckMarkSvg } from './images/landingPage/landingImages/CheckmarkSvg.svg';
import { ReactComponent as FacebookFooter } from './images/landingPage/footer/FacebookFooter.svg';
import { ReactComponent as LinkedinFooter } from './images/landingPage/footer/LinkedinFooter.svg';
import { ReactComponent as YoutubeFooter } from './images/landingPage/footer/YoutubeFooter.svg';
import { ReactComponent as InstagramFooter } from './images/landingPage/footer/InstagramFooter.svg';
import { ReactComponent as TwitterFooter } from './images/landingPage/footer/TwitterFooter.svg';


// ABOUT_US_PAGE_IMAGES
import AboutUsBanner from './images/landingPage/aboutUs/AboutUsBanner.png';
import banner1 from './images/landingPage/aboutUs/banner1.jpg';
import banner1hovered from './images/landingPage/aboutUs/banner1hovered.jpg';
import theMissionImg from './images/landingPage/aboutUs/theMissionImg.png';
import getInTouchBg from './images/landingPage/aboutUs/getInTouch-bg.png';

// ABOUT_US_PAGE_SVG
import { ReactComponent as OurBeginningsSvg } from './images/landingPage/aboutUs/OurBeginningsSvg.svg';
import { ReactComponent as GrowthSvg } from './images/landingPage/aboutUs/GrowthSvg.svg';
import { ReactComponent as NetworkSvg } from './images/landingPage/aboutUs/NetworkSvg.svg';
import { ReactComponent as InnovationSvg } from './images/landingPage/aboutUs/InnovationSvg.svg';
import { ReactComponent as IntegritySvg } from './images/landingPage/aboutUs/IntegritySvg.svg';
import { ReactComponent as CollaborationSvg } from './images/landingPage/aboutUs/CollaborationSvg.svg';

// CONTACT_US_PAGE_IMAGES
import { ReactComponent as ArrowDownSvg } from './images/landingPage/contactUs/ArrowDownSvg.svg';
import { ReactComponent as callIconSvg } from './images/landingPage/contactUs/callIconSvg.svg';
import { ReactComponent as SmsStarSvg } from './images/landingPage/contactUs/SmsStarSvg.svg';
import { ReactComponent as AddressSvg } from './images/landingPage/contactUs/AddressSvg.svg';
import { ReactComponent as FacebookContactSvg } from './images/landingPage/contactUs/FacebookContactSvg.svg';
import { ReactComponent as LinkedinContactSvg } from './images/landingPage/contactUs/LinkedinContactSvg.svg';
import { ReactComponent as TwitterContactSvg } from './images/landingPage/contactUs/TwitterContactSvg.svg';
import { ReactComponent as InstagramContact } from './images/landingPage/contactUs/InstagramContact.svg';

// FEATURES_PAGE_SVG
import { ReactComponent as FundingSourceSvg } from './images/landingPage/features/FundingSourceSvg.svg';
import { ReactComponent as ClientManagementSvg } from './images/landingPage/features/ClientManagementSvg.svg';
import { ReactComponent as StaffManagementSvg } from './images/landingPage/features/StaffManagementSvg.svg';
import { ReactComponent as GooglePlaySvg } from './images/landingPage/features/GooglePlaySvg.svg';
import { ReactComponent as GooglePlay } from './images/landingPage/features/GooglePlay.svg';
import { ReactComponent as GetItOnSvg } from './images/landingPage/features/GetItOnSvg.svg';
import { ReactComponent as AppleSvg } from './images/landingPage/features/AppleSvg.svg';
import { ReactComponent as DownloadOnThe } from './images/landingPage/features/DownloadOnThe.svg';
import { ReactComponent as AppStoreSvg } from './images/landingPage/features/AppStoreSvg.svg';

// FEATURES_PAGE_IMAGES

import electronic from './images/landingPage/features/electronic.png';
import electronicWeb from './images/landingPage/features/electronicWeb.png';
import schedulingImg from './images/landingPage/features/schedulingImg.png';
import aiPowered from './images/landingPage/features/aiPowered.png';
import autoClaims from './images/landingPage/features/autoClaims.png';
import payrollManag from './images/landingPage/features/payrollManag.png';
import billingDegree from './images/landingPage/features/billingDegree.png';
import powerfulIntegrations from './images/landingPage/features/powerfulIntegrations.png';
import customizableReports from './images/landingPage/features/customizableReports.png';
import customizableReportsTablet from './images/landingPage/features/customizableReportsTablet.png';
import accessKeyFeatures from './images/landingPage/features/accessKeyFeatures.png';
import accessKeyFeaturesTablet from './images/landingPage/features/accessKeyFeaturesTablet.png';
import dataSecurity from './images/landingPage/features/dataSecurity.png';
import credentialing from './images/landingPage/features/credentialing.png';
import billingDirect from './images/landingPage/features/billingDirect.png';
import billingConsulting from './images/landingPage/features/billingConsulting.png';
import billingPosting from './images/landingPage/features/billingPosting.png';

// HELP_CENTER_PAGE_IMAGES
import Knowledge from './images/landingPage/helpCenter/knowledge.png';
import ScheduleScreen from './images/landingPage/helpCenter/scheduleScreen.png';

// NOT_FOUND_PAGE_IMAGES
import Warning from './images/landingPage/notFound/warning.png';

// COMMON_IMAGES_AND_ICONS
import CloseIcon from './images/landingPage/common/closeIcon.svg';
import MenuMobileIcon from './images/landingPage/common/menuMobileIcon.png';

import logo from './images/landingPage/topBar/logo.svg';
import ScheduleTab from './images/icons/scheduleTab.svg';
import ScheduleTabDone from './images/icons/scheduleTabDone.svg';

import { ReactComponent as BlackLogo } from './images/landingPage/landingImages/blackLogo.svg';
import ButtonArrow from './images/landingPage/landingImages/buttonArrow.svg';
import { ReactComponent as Play } from './images/landingPage/landingImages/play.svg';
import HomeClient from './images/landingPage/landingImages/homeClient.png';

import authImage from './images/authImage.png';

//HELP-CENTER-IMAGES

import createRoleDesktop from './images/helpCenter/createRoleDesktop.png';
import addPermDesktop from './images/helpCenter/addPermDesktop.png';
import deletingPermDesktop from './images/helpCenter/deletingPermDesktop.png';
import deletingRoleDesktop from './images/helpCenter/deletingRoleDesktop.png';
import renamingRolesSelect from './images/helpCenter/renamingRolesSelect.png';
import renamingRolesEdit from './images/helpCenter/renamingRolesEdit.png';
import saveCompanyProfDesktop from './images/helpCenter/saveCompanyProfDesktop.png';
import editingCompPropDesktop from './images/helpCenter/editingCompPropDesktop.png';
import saveChangesDesktop from './images/helpCenter/saveChangesDesktop.png';
import saveServiceTypeDesktop from './images/helpCenter/saveServiceTypeDesktop.png';
import editingServiceDesktop1 from './images/helpCenter/editingServiceDesktop1.png';
import editingServiceDesktop2 from './images/helpCenter/editingServiceDesktop2.png';
import creatingCredDesktop1 from './images/helpCenter/creatingCredDesktop1.png';
import creatingCredDesktop2 from './images/helpCenter/creatingCredDesktop2.png';
import addingCovCredDesktop from './images/helpCenter/addingCovCredDesktop.png';
import editCredNameDesktop1 from './images/helpCenter/editCredNameDesktop1.png';
import editCredNameDesktop2 from './images/helpCenter/editCredNameDesktop2.png';
import deleteCovCredDesktop from './images/helpCenter/deleteCovCredDesktop.png';
import addingNewDepDesktop from './images/helpCenter/addingNewDepDesktop.png';
import editingDepDesktop1 from './images/helpCenter/editingDepDesktop1.png';
import editingDepDesktop2 from './images/helpCenter/editingDepDesktop2.png';
import addingNewJobDesktop from './images/helpCenter/addingNewJobDesktop.png';
import editingJobTitleDesktop1 from './images/helpCenter/editingJobTitleDesktop1.png';
import editingJobTitleDesktop2 from './images/helpCenter/editingJobTitleDesktop2.png';
import addingPayTypesDesktop from './images/helpCenter/addingPayTypesDesktop.png';
import editPayTypeDesktop1 from './images/helpCenter/editPayTypeDesktop1.png';
import editPayTypeDesktop2 from './images/helpCenter/editPayTypeDesktop2.png';
import addingMileageDesktop from './images/helpCenter/addingMileageDesktop.png';
import editingMilDesktop1 from './images/helpCenter/editingMilDesktop1.png';
import editingMilDesktop2 from './images/helpCenter/editingMilDesktop2.png';
import inactivatingMilDesktop from './images/helpCenter/inactivatingMilDesktop.png';
import referenceCommonDesktop from './images/helpCenter/referenceCommonDesktop.png';
import editingPlaceDesktop1 from './images/helpCenter/editingPlaceDesktop1.png';
import editingPlaceDesktop2 from './images/helpCenter/editingPlaceDesktop2.png';
import createFundingDesktop1 from './images/helpCenter/createFundingDesktop1.png';
import createFundingDesktop2 from './images/helpCenter/createFundingDesktop2.png';
import activeInactiveFundDesktop from './images/helpCenter/activeInactiveFundDesktop.png';
import addServFundDesktop from './images/helpCenter/addServFundDesktop.png';
import mergingAppointmentsDesktop from './images/helpCenter/mergingAppointmentsDesktop.png';
import downloadingCsvDesktop from './images/helpCenter/downloadingCsvDesktop.png';
import editingFundingDesktop1 from './images/helpCenter/editingFundingDesktop1.png';
import editingFundingDesktop2 from './images/helpCenter/editingFundingDesktop2.png';
import editingFundServiceDesktop1 from './images/helpCenter/editingFundServiceDesktop1.png';
import editingFundServiceDesktop2 from './images/helpCenter/editingFundServiceDesktop2.png';
import addNotFundDesktop from './images/helpCenter/addNotFundDesktop.png';
import editingFundNotesDesktop from './images/helpCenter/editingFundNotesDesktop.png';
import deleteFundNotesDesktop from './images/helpCenter/deleteFundNotesDesktop.png';
import viewFundChangeHistoryDesktop from './images/helpCenter/viewFundChangeHistoryDesktop.png';
import uploadFundFilesDesktop1 from './images/helpCenter/uploadFundFilesDesktop1.png';
import uploadFundFilesDesktop2 from './images/helpCenter/uploadFundFilesDesktop2.png';
import editFundFilesDesktop from './images/helpCenter/editFundFilesDesktop.png';
import deletingFundFilesDesktop from './images/helpCenter/deletingFundFilesDesktop.png';
import downFundFilesDesktop from './images/helpCenter/downFundFilesDesktop.png';
import viewFundFilesDesktop from './images/helpCenter/viewFundFilesDesktop.png';
import changeProfDesktop from './images/helpCenter/changeProfDesktop.png';
import userChangePassDesktop from './images/helpCenter/userChangePassDesktop.png';
import addClientDesktop from './images/helpCenter/addClientDesktop.png';
import addContactClientDesktop1 from './images/helpCenter/addContactClientDesktop1.png';
import addContactClientDesktop2 from './images/helpCenter/addContactClientDesktop2.png';
import addEnrolClientDesktop1 from './images/helpCenter/addEnrolClientDesktop1.png';
import addEnrolClientDesktop2 from './images/helpCenter/addEnrolClientDesktop2.png';
import addNoteClientDesktop from './images/helpCenter/addNoteClientDesktop.png';
import editClientFilesDesktop from './images/helpCenter/editClientFilesDesktop.png';
import editClientNoteDesktop from './images/helpCenter/editClientNoteDesktop.png';
import deleteClientNoteDesktop from './images/helpCenter/deleteClientNoteDesktop.png';
import addFileClientDesktop from './images/helpCenter/addFileClientDesktop.png';
import viewClientFileDesktop from './images/helpCenter/viewClientFileDesktop.png';
import editClientFileDesktop from './images/helpCenter/editClientFileDesktop.png';
import downClientFilesDesktop from './images/helpCenter/downClientFilesDesktop.png';
import deleteClientFilesDesktop from './images/helpCenter/deleteClientFilesDesktop.png';
import assignStaffClientDesktop from './images/helpCenter/assignStaffClientDesktop.png';
import deletAssignStaffClientDesktop from './images/helpCenter/deletAssignStaffClientDesktop.png';
import viwAssignStaffClientDesktop from './images/helpCenter/viwAssignStaffClientDesktop.png';
import addAuthClientDesktop1 from './images/helpCenter/addAuthClientDesktop1.png';
import addAuthClientDesktop2 from './images/helpCenter/addAuthClientDesktop2.png';
import addAuthServClientDesktop1 from './images/helpCenter/addAuthServClientDesktop1.png';
import addAuthServClientDesktop2 from './images/helpCenter/addAuthServClientDesktop2.png';
import editClientAuthDesktop1 from './images/helpCenter/editClientAuthDesktop1.png';
import editClientAuthDesktop2 from './images/helpCenter/editClientAuthDesktop2.png';
import editAuthServiceDesktop from './images/helpCenter/editAuthServiceDesktop.png';
import viewPastAuthDesktop1 from './images/helpCenter/viewPastAuthDesktop1.png';
import viewPastAuthDesktop2 from './images/helpCenter/viewPastAuthDesktop2.png';
import manageApptAuthDesktop1 from './images/helpCenter/manageApptAuthDesktop1.png';
import manageApptAuthDesktop2 from './images/helpCenter/manageApptAuthDesktop2.png';
import editClientContDesktop1 from './images/helpCenter/editClientContDesktop1.png';
import editClientContDesktop2 from './images/helpCenter/editClientContDesktop2.png';
import deletClientContDesktop from './images/helpCenter/deletClientContDesktop.png';
import editGenInfoClientDesktop1 from './images/helpCenter/editGenInfoClientDesktop1.png';
import editGenInfoClientDesktop2 from './images/helpCenter/editGenInfoClientDesktop2.png';
import editOtherInfoClientDesktop1 from './images/helpCenter/editOtherInfoClientDesktop1.png';
import editOtherInfoClientDesktop2 from './images/helpCenter/editOtherInfoClientDesktop2.png';
import deleteAddressClientDesktop from './images/helpCenter/deleteAddressClientDesktop.png';
import editAddressClientDesktop1 from './images/helpCenter/editAddressClientDesktop1.png';
import editAddressClientDesktop2 from './images/helpCenter/editAddressClientDesktop2.png';
import settingAddressAsDefDesktop1 from './images/helpCenter/settingAddressAsDefDesktop1.png';
import settingAddressAsDefDesktop2 from './images/helpCenter/settingAddressAsDefDesktop2.png';
import downClientCsvDesktop from './images/helpCenter/downClientCsvDesktop.png';
import viewClientPprofChangeDesktop from './images/helpCenter/viewClientPprofChangeDesktop.png';
import clientActiveInActiveDesktop from './images/helpCenter/clientActiveInActiveDesktop.png';
import editPrimSecondEnrollDesktop1 from './images/helpCenter/editPrimSecondEnrollDesktop1.png';
import editPrimSecondEnrollDesktop2 from './images/helpCenter/editPrimSecondEnrollDesktop2.png';
import termPrimSecondEnrolDesktop1 from './images/helpCenter/termPrimSecondEnrolDesktop1.png';
import termPrimSecondEnrolDesktop2 from './images/helpCenter/termPrimSecondEnrolDesktop2.png';
import settingSecondEnrollPrimDesktop1 from './images/helpCenter/settingSecondEnrollPrimDesktop1.png';
import settingSecondEnrollPrimDesktop2 from './images/helpCenter/settingSecondEnrollPrimDesktop2.png';
import addAddresClientDesktop1 from './images/helpCenter/addAddresClientDesktop1.png';
import addAddresClientDesktop2 from './images/helpCenter/addAddresClientDesktop2.png';
import downCsvStaffDesktop from './images/helpCenter/downCsvStaffDesktop.png';
import addStaffDesktop1 from './images/helpCenter/addStaffDesktop1.png';
import addStaffDesktop2 from './images/helpCenter/addStaffDesktop2.png';
import addStaffPayDesktop1 from './images/helpCenter/addStaffPayDesktop1.png';
import addStaffPayDesktop2 from './images/helpCenter/addStaffPayDesktop2.png';
import addStaffPayDesktop3 from './images/helpCenter/addStaffPayDesktop3.png';
import addStaffTimePayDesktop1 from './images/helpCenter/addStaffTimePayDesktop1.png';
import addStaffTimePayDesktop2 from './images/helpCenter/addStaffTimePayDesktop2.png';
import addStaffTimeFixedPayDesktop1 from './images/helpCenter/addStaffTimeFixedPayDesktop1.png';
import addStaffTimeFixedPayDesktop2 from './images/helpCenter/addStaffTimeFixedPayDesktop2.png';
import addStaffCredDesktop1 from './images/helpCenter/addStaffCredDesktop1.png';
import addStaffCredDesktop2 from './images/helpCenter/addStaffCredDesktop2.png';
import addStaffNotesDesktop1 from './images/helpCenter/addStaffNotesDesktop1.png';
import addStaffNotesDesktop2 from './images/helpCenter/addStaffNotesDesktop2.png';
import viewStaffProfChangeHistoryDesktop from './images/helpCenter/viewStaffProfChangeHistoryDesktop.png';
import addStaffFilesDesktop1 from './images/helpCenter/addStaffFilesDesktop1.png';
import addStaffFilesDesktop2 from './images/helpCenter/addStaffFilesDesktop2.png';
import addStaffFilesDesktop3 from './images/helpCenter/addStaffFilesDesktop3.png';
import addEditStaffGenInfoDesktoop1 from './images/helpCenter/addEditStaffGenInfoDesktoop1.png';
import addEditStaffGenInfoDesktoop2 from './images/helpCenter/addEditStaffGenInfoDesktoop2.png';
import addEditStaffGenInfoDesktoop3 from './images/helpCenter/addEditStaffGenInfoDesktoop3.png';
import addStaffFiles2Desktop1 from './images/helpCenter/addStaffFiles2Desktop1.png';
import addStaffFiles2Desktop2 from './images/helpCenter/addStaffFiles2Desktop2.png';
import addStaffFiles2Desktop3 from './images/helpCenter/addStaffFiles2Desktop3.png';
import addStaffCurrPastEmplDesktop1 from './images/helpCenter/addStaffCurrPastEmplDesktop1.png';
import addStaffCurrPastEmplDesktop2 from './images/helpCenter/addStaffCurrPastEmplDesktop2.png';
import addStaffCurrPastEmplDesktop3 from './images/helpCenter/addStaffCurrPastEmplDesktop3.png';
import viewStaffCurrentEmpDesktop1 from './images/helpCenter/viewStaffCurrentEmpDesktop1.png';
import viewStaffCurrentEmpDesktop2 from './images/helpCenter/viewStaffCurrentEmpDesktop2.png';
import viewStaffPastEmplDesktop from './images/helpCenter/viewStaffPastEmplDesktop.png';
import inactiveStaffPayCodeDesktop1 from './images/helpCenter/inactiveStaffPayCodeDesktop1.png';
import inactiveStaffPayCodeDesktop2 from './images/helpCenter/inactiveStaffPayCodeDesktop2.png';
import inactiveStaffPayCodeDesktop3 from './images/helpCenter/inactiveStaffPayCodeDesktop3.png';
import editStaffTimesheetDesktop1 from './images/helpCenter/editStaffTimesheetDesktop1.png';
import editStaffTimesheetDesktop2 from './images/helpCenter/editStaffTimesheetDesktop2.png';
import editStaffCredentialDesktop1 from './images/helpCenter/editStaffCredentialDesktop1.png';
import editStaffCredentialDesktop2 from './images/helpCenter/editStaffCredentialDesktop2.png';
import viewStaffCredDesktop from './images/helpCenter/viewStaffCredDesktop.png';
import deleteStaffCredDesktop1 from './images/helpCenter/deleteStaffCredDesktop1.png';
import deleteStaffCredDesktop2 from './images/helpCenter/deleteStaffCredDesktop2.png';
import deleteStaffServiceDesktop1 from './images/helpCenter/deleteStaffServiceDesktop1.png';
import deleteStaffServiceDesktop2 from './images/helpCenter/deleteStaffServiceDesktop2.png';
import editStaffNotesDesktop1 from './images/helpCenter/editStaffNotesDesktop1.png';
import editStaffNotesDesktop2 from './images/helpCenter/editStaffNotesDesktop2.png';
import deleteStaffNotesDesktop1 from './images/helpCenter/deleteStaffNotesDesktop1.png';
import deleteStaffNotesDesktop2 from './images/helpCenter/deleteStaffNotesDesktop2.png';
import deleteStaffDileDesktop1 from './images/helpCenter/deleteStaffDileDesktop1.png';
import deleteStaffDileDesktop2 from './images/helpCenter/deleteStaffDileDesktop2.png';
import editStaffPastMemberDesktop1 from './images/helpCenter/editStaffPastMemberDesktop1.png';
import editStaffPastMemberDesktop2 from './images/helpCenter/editStaffPastMemberDesktop2.png';
import editStaffPastMemberDesktop3 from './images/helpCenter/editStaffPastMemberDesktop3.png';
import deleteStaffMemberEmployDesktop1 from './images/helpCenter/deleteStaffMemberEmployDesktop1.png';
import deleteStaffMemberEmployDesktop2 from './images/helpCenter/deleteStaffMemberEmployDesktop2.png';
import deleteStaffMemberEmployDesktop3 from './images/helpCenter/deleteStaffMemberEmployDesktop3.png';
import activeInactiveStaffDesktop1 from './images/helpCenter/activeInactiveStaffDesktop1.png';
import activeInactiveStaffDesktop2 from './images/helpCenter/activeInactiveStaffDesktop2.png';
import viewPendClaimDesktop from './images/helpCenter/viewPendClaimDesktop.png';
import generateClaimDesktop1 from './images/helpCenter/generateClaimDesktop1.png';
import deleteStaffAccessDesktop1 from './images/helpCenter/deleteStaffAccessDesktop1.png';
import deleteStaffAccessDesktop2 from './images/helpCenter/deleteStaffAccessDesktop2.png';


import addChargeRateDesktop from './images/helpCenter/videos/addChargeRateDesktop.mov';
import inactiveActiveFundDesktop from './images/helpCenter/videos/inactiveActiveFundDesktop.mov';
import activeInactiveFundingDesktop from './images/helpCenter/videos/activeInactiveFundingDesktop.mov';

export const Videos = {
    addChargeRateDesktop,
    inactiveActiveFundDesktop,
    activeInactiveFundingDesktop,
};


export const Images = {
    // HELP-CENTER
    createRoleDesktop,
    addPermDesktop,
    deletingPermDesktop,
    deletingRoleDesktop,
    renamingRolesSelect,
    renamingRolesEdit,
    saveCompanyProfDesktop,
    editingCompPropDesktop,
    saveChangesDesktop,
    saveServiceTypeDesktop,
    editingServiceDesktop1,
    editingServiceDesktop2,
    creatingCredDesktop1,
    creatingCredDesktop2,
    addingCovCredDesktop,
    editCredNameDesktop1,
    editCredNameDesktop2,
    deleteCovCredDesktop,
    addingNewDepDesktop,
    editingDepDesktop1,
    editingDepDesktop2,
    addingNewJobDesktop,
    editingJobTitleDesktop1,
    editingJobTitleDesktop2,
    addingPayTypesDesktop,
    editPayTypeDesktop1,
    editPayTypeDesktop2,
    addingMileageDesktop,
    editingMilDesktop1,
    editingMilDesktop2,
    inactivatingMilDesktop,
    referenceCommonDesktop,
    editingPlaceDesktop1,
    editingPlaceDesktop2,
    createFundingDesktop1,
    createFundingDesktop2,
    activeInactiveFundDesktop,
    addServFundDesktop,
    mergingAppointmentsDesktop,
    downloadingCsvDesktop,
    editingFundingDesktop1,
    editingFundingDesktop2,
    editingFundServiceDesktop1,
    editingFundServiceDesktop2,
    addNotFundDesktop,
    editingFundNotesDesktop,
    deleteFundNotesDesktop,
    viewFundChangeHistoryDesktop,
    uploadFundFilesDesktop1,
    uploadFundFilesDesktop2,
    editFundFilesDesktop,
    deletingFundFilesDesktop,
    downFundFilesDesktop,
    viewFundFilesDesktop,
    changeProfDesktop,
    userChangePassDesktop,
    addClientDesktop,
    addContactClientDesktop1,
    addContactClientDesktop2,
    addEnrolClientDesktop1,
    addEnrolClientDesktop2,
    addNoteClientDesktop,
    editClientFilesDesktop,
    editClientNoteDesktop,
    deleteClientNoteDesktop,
    addFileClientDesktop,
    viewClientFileDesktop,
    editClientFileDesktop,
    downClientFilesDesktop,
    deleteClientFilesDesktop,
    assignStaffClientDesktop,
    deletAssignStaffClientDesktop,
    viwAssignStaffClientDesktop,
    addAuthClientDesktop1,
    addAuthClientDesktop2,
    addAuthServClientDesktop1,
    addAuthServClientDesktop2,
    editClientAuthDesktop1,
    editClientAuthDesktop2,
    editAuthServiceDesktop,
    viewPastAuthDesktop1,
    viewPastAuthDesktop2,
    manageApptAuthDesktop1,
    manageApptAuthDesktop2,
    editClientContDesktop1,
    editClientContDesktop2,
    deletClientContDesktop,
    editGenInfoClientDesktop1,
    editGenInfoClientDesktop2,
    editOtherInfoClientDesktop1,
    editOtherInfoClientDesktop2,
    deleteAddressClientDesktop,
    editAddressClientDesktop1,
    editAddressClientDesktop2,
    settingAddressAsDefDesktop1,
    settingAddressAsDefDesktop2,
    downClientCsvDesktop,
    viewClientPprofChangeDesktop,
    clientActiveInActiveDesktop,
    editPrimSecondEnrollDesktop1,
    editPrimSecondEnrollDesktop2,
    termPrimSecondEnrolDesktop1,
    termPrimSecondEnrolDesktop2,
    settingSecondEnrollPrimDesktop1,
    settingSecondEnrollPrimDesktop2,
    addAddresClientDesktop1,
    addAddresClientDesktop2,
    downCsvStaffDesktop,
    addStaffDesktop1,
    addStaffDesktop2,
    addStaffPayDesktop1,
    addStaffPayDesktop2,
    addStaffPayDesktop3,
    addStaffTimePayDesktop1,
    addStaffTimePayDesktop2,
    addStaffTimeFixedPayDesktop1,
    addStaffTimeFixedPayDesktop2,
    addStaffCredDesktop1,
    addStaffCredDesktop2,
    addStaffNotesDesktop1,
    addStaffNotesDesktop2,
    viewStaffProfChangeHistoryDesktop,
    addStaffFilesDesktop1,
    addStaffFilesDesktop2,
    addStaffFilesDesktop3,
    addEditStaffGenInfoDesktoop1,
    addEditStaffGenInfoDesktoop2,
    addEditStaffGenInfoDesktoop3,
    addStaffFiles2Desktop1,
    addStaffFiles2Desktop2,
    addStaffFiles2Desktop3,
    addStaffCurrPastEmplDesktop1,
    addStaffCurrPastEmplDesktop2,
    addStaffCurrPastEmplDesktop3,
    viewStaffCurrentEmpDesktop1,
    viewStaffCurrentEmpDesktop2,
    viewStaffPastEmplDesktop,
    inactiveStaffPayCodeDesktop1,
    inactiveStaffPayCodeDesktop2,
    inactiveStaffPayCodeDesktop3,
    editStaffTimesheetDesktop1,
    editStaffTimesheetDesktop2,
    editStaffCredentialDesktop1,
    editStaffCredentialDesktop2,
    viewStaffCredDesktop,
    deleteStaffCredDesktop1,
    deleteStaffCredDesktop2,
    deleteStaffServiceDesktop1,
    deleteStaffServiceDesktop2,
    editStaffNotesDesktop1,
    editStaffNotesDesktop2,
    deleteStaffNotesDesktop1,
    deleteStaffNotesDesktop2,
    deleteStaffDileDesktop1,
    deleteStaffDileDesktop2,
    editStaffPastMemberDesktop1,
    editStaffPastMemberDesktop2,
    editStaffPastMemberDesktop3,
    deleteStaffMemberEmployDesktop1,
    deleteStaffMemberEmployDesktop2,
    deleteStaffMemberEmployDesktop3,
    activeInactiveStaffDesktop1,
    activeInactiveStaffDesktop2,
    viewPendClaimDesktop,
    generateClaimDesktop1,
    deleteStaffAccessDesktop1,
    deleteStaffAccessDesktop2,

    authImage,
    ScheduleTabDone,
    ScheduleTab,
    logo,
    MenuIcon,
    MenuCloseIcon,
    ClientMgmt,
    ClientMgmtMark,
    ClientMgmtScreen,
    StaffMgmt,
    StaffMgmtMark,
    StaffMgmtScreen,
    FSMgmt,
    FSMgmtMark,
    FSMgmtScreen,
    AccessMgmt,
    AccessMgmtMark,
    AccessMgmtScreen,
    Scheduling,
    SchedulingMark,
    SchedulingScreen,
    Billing,
    BillingMark,
    BillingScreen,
    CustomerOne,
    CustomerTwo,
    Quotes,
    LeftArrow,
    RightArrow,
    Warning,
    CloseIcon,
    Knowledge,
    ScheduleScreen,
    MenuMobileIcon,
};

export const Svg = {
    NotificationsActiveSvg,
    BookHelpSvg,
    FooterLogo,
    FacebookFooter,
    LinkedinFooter,
    YoutubeFooter,
    InstagramFooter,
    TwitterFooter,
    SmsSvg,
    LockPassSvg,
    CheckSvg,
    CloseSvg,
    EyeSvg,
    EyeHideSvg,
    CheckEmailSvg,
    ArrowRight2Svg,
};

export const landingImages = {
    BlackLogo,
    ButtonArrow,
    Play,
    HomeClient,
    empowering,
    whyTherapyCard1,
    whyTherapyCard2,
    whyTherapyCard3,
    whyTherapyCard4,
    technologicalImg,
    accessKayImage,
    appStore,
    googlePlay,
    accessKayImageTablet,
    accessKayImageMobile,
    customerOne,
    customerTwo,
    backInfinityFeatures,
    AboutUsBanner,
    banner1,
    banner1hovered,
    theMissionImg,
    electronic,
    electronicWeb,
    schedulingImg,
    payrollManag,
    autoClaims,
    aiPowered,
    billingDegree,
    dataSecurity,
    customizableReports,
    customizableReportsTablet,
    accessKeyFeatures,
    accessKeyFeaturesTablet,
    billingDirect,
    billingConsulting,
    credentialing,
    powerfulIntegrations,
    billingPosting,
};


export const LandingSvg = {
    InstagramContact,
    GooglePlaySvg,
    GooglePlay,
    GetItOnSvg,
    AppleSvg,
    DownloadOnThe,
    AppStoreSvg,
    ScheduleSvg,
    BillingSvg,
    PostingSvg,
    MobileAppSvg,
    AdvancedSvg,
    IntegrationsSvg,
    CredentialingSvg,
    ArrowRightSvg,
    PlaySvg,
    EMRSvg,
    InfinitySmallSvg,
    StarOrangeSvg,
    ArrowLeftCarousel,
    ArrowRightCarousel,
    Checkmark,
    CheckMarkSvg,
    ArrowDownSvg,
    callIconSvg,
    SmsStarSvg,
    AddressSvg,
    FacebookContactSvg,
    LinkedinContactSvg,
    TwitterContactSvg,
    OurBeginningsSvg,
    GrowthSvg,
    NetworkSvg,
    InnovationSvg,
    IntegritySvg,
    CollaborationSvg,
    getInTouchBg,
    FundingSourceSvg,
    ClientManagementSvg,
    StaffManagementSvg,
};

