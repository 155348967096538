import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";

export const useWeekInterval = () => {
   const [firstDayOfWeek, setFirstDayOfWeek] = React.useState(new Date().getDate());
   const [lastDayOfWeek, setLastDayOfWeek] = React.useState(new Date().getDate() + 7);
   let history = useHistory();
   const info = history?.location?.state

   const firstDate = new Date();
   firstDate.setDate(firstDayOfWeek);

   const lastDate = new Date();
   lastDate.setDate(lastDayOfWeek);


   const goToNextWeek = () => {
      setFirstDayOfWeek((prevState) => prevState + 7);
      setLastDayOfWeek((prevState) => prevState + 7);

      const startDate = new Date();
      startDate.setDate(firstDayOfWeek + 7);

      const endDate = new Date();
      endDate.setDate(lastDayOfWeek + 7);

      history.push({
         state: {
            ...info,
            firstDate: startDate,
            lastDate: endDate,
            firstDayOfWeek:firstDayOfWeek + 7,
            lastDayOfWeek:lastDayOfWeek + 7
         }
      });
   };

   const goToLastWeek = () => {
      setFirstDayOfWeek((prevState) => prevState - 7);
      setLastDayOfWeek((prevState) => prevState - 7);

      const startDate = new Date();
      startDate.setDate(firstDayOfWeek - 7);

      const endDate = new Date();
      endDate.setDate(lastDayOfWeek - 7);

      history.push({
         state: {
            ...info,
            firstDate: startDate,
            lastDate: endDate,
            firstDayOfWeek:firstDayOfWeek - 7,
            lastDayOfWeek:lastDayOfWeek - 7
         }
      });
   };



   const firstDateMonth = firstDate.toLocaleString("default", { month: "long" });
   const firstDateDay = firstDate.getDate() < 10 ? `0${firstDate.getDate()}` : firstDate.getDate();
   const lastDateMonth = lastDate.toLocaleString("default", { month: "long" });
   const lastDateDay = lastDate.getDate() < 10 ? `0${lastDate.getDate()}` : lastDate.getDate();
   const monthsAreNotTheSame = firstDateMonth !== lastDateMonth;

   const firstDateLabel = `${firstDateMonth} ${firstDateDay}`;
   const lastDateLabel = `${monthsAreNotTheSame ? lastDateMonth : ""} ${lastDateDay}`;
   const dateLabel = `${firstDateLabel} - ${lastDateLabel}, ${lastDate.getFullYear()}`;

   useEffect(() => {
      if(info?.firstDayOfWeek && info?.lastDayOfWeek){
         setFirstDayOfWeek(info?.firstDayOfWeek)
         setLastDayOfWeek(info?.lastDayOfWeek)
      }
   }, [info])

   // useEffect(() => {
   //    if(firstDate && lastDate){
   //       history.push({
   //          state: {
   //             ...info,
   //             firstDate: firstDate,
   //             lastDate: lastDate,
   //             firstDayOfWeek: firstDayOfWeek,
   //             lastDayOfWeek: lastDayOfWeek,
   //          }
   //       });
   //    }
   // }, [firstDate, lastDate])

   return {
      firstDate,
      lastDate,
      dateLabel,
      goToNextWeek,
      goToLastWeek,
      firstDayOfWeek,
      lastDayOfWeek,
   };
};
