import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Main } from 'fragments';
import { MyCalendlyComponent, GetInTouch, SocialContactUs, FaqSocial } from './fragments';
import { animationParams, animationVariants } from 'fragments';


export const ContactUs = () => {
    const [currentTab, setCurrentTab] = useState('first-tab');


    const handleClickOnTab = (title) => {
        setCurrentTab(title);
    };


    return (
        <Main>
            <div className='contact-us-page'>
                <div className='container'>
                    <p className='contact-us-sub-title'>
                        CONTACT US
                    </p>

                    <div style={{ overflow: 'hidden' }}>
                        <motion.h2
                            className='contact-us-title'
                            {...animationParams}
                            variants={animationVariants.titleVariantMobile}
                        >
                            Get in touch with TherapyLake!
                        </motion.h2>
                    </div>

                    <div style={{ overflow: 'hidden' }}>
                        <motion.p
                            className='contact-us-description'
                            {...animationParams}
                            variants={animationVariants.titleVariantMobile}
                        >
                            We’re here to help. Whether you have questions about our services, need support, or want to
                            provide feedback, feel free to reach out to us!
                        </motion.p>
                    </div>

                    <div className='tabs'>
                        <div className={`tab ${currentTab === 'first-tab' ? 'active' : ''}`}
                             onClick={() => handleClickOnTab('first-tab')}
                        >
                            <p className='first-text'>
                                Request Demo
                            </p>

                            <p className='second-text'>
                                Schedule Appointment
                            </p>
                        </div>

                        <div className={`tab ${currentTab === 'second-tab' ? 'active' : ''}`}
                             onClick={() => handleClickOnTab('second-tab')}
                        >
                            <p className='first-text'>
                                Get In Touch
                            </p>

                            <p className='second-text'>
                                Your questions and requests are always welcome
                            </p>
                        </div>
                    </div>

                    <div className={currentTab === 'first-tab' ? 'inputs-wrapper' : 'inputs-wrapper-with-paddings'}>
                        {currentTab === 'first-tab' ? <MyCalendlyComponent /> : <GetInTouch />}
                    </div>

                    <SocialContactUs />

                    <FaqSocial />
                </div>
            </div>
        </Main>
    );
};
