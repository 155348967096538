import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ButtonLanding, LandingModal, YoutubeEmbed } from 'components';
import { useWidth } from 'utils';
import { LandingSvg } from 'assets';
import { animationParams, animationVariants } from 'fragments';


export const FeatureBanner = () => {
    const [videoModalIsOpen, setVideoModalIsOpen] = useState(false);
    const width = useWidth();
    const history = useHistory();
    return (
        <div className='feature-banner-wrapper'>
            <div className='container'>
                <div className='feature-banner-box'>
                    <div style={{ overflow: 'hidden' }}>
                        <motion.h1
                            className='feature-banner_title'
                            {...animationParams}
                            variants={width > 768 ? animationVariants.titleVariant : animationVariants.titleVariantMobile}
                        >
                            All-in-One Practice Management Software for ABA Practices
                        </motion.h1>
                    </div>

                    <LandingSvg.InfinitySmallSvg className='feature-banner_svg' />

                    <motion.p
                        className='feature-banner_description'
                        {...animationParams}
                        variants={animationVariants.titleVariantMobile}
                    >
                        Explore TherapyLake’s comprehensive features designed to streamline your practice.
                    </motion.p>

                    <div className='feature-banner-buttons-block'>
                        <ButtonLanding
                            className='request-demo-btn'
                            text='Request a Demo'
                            onClick={() => history.push('/contact-us')}
                            big={width <= 768}
                        />

                        <ButtonLanding
                            className='learn-more-button'
                            text='Learn more'
                            contained={false}
                            arrowIcon={true}
                            big={width <= 768}
                            startAdornment={<LandingSvg.PlaySvg className='play-svg' />}
                            onClick={() => setVideoModalIsOpen(true)}
                        />
                    </div>
                </div>
            </div>

            <LandingModal
                modalClassName={'home-video-modal'}
                modalOpen={videoModalIsOpen}
                closeModal={() => setVideoModalIsOpen(false)}
            >
                <YoutubeEmbed embedId={'4ju2G3KtKNA'} allowFullScreen autoPlay />
            </LandingModal>
        </div>
    );
};

