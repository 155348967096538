import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CreateChancel } from 'components';
import { appointmentActions, UploadServices } from 'store';
import { base64ToFile, FindLoad, Images, useModal } from 'utils';
import SignatureCanvas from 'react-signature-canvas';
import { scheduleModalsStyle } from '../modals';

const ACTION_TYPE = 'APPEND_SIGNATURE_TO_APPMT';
export const Draw = ({ appt }) => {
    const classes = scheduleModalsStyle();
    const dispatch = useDispatch();
    const [uploadLoader, setUploadLoader] = useState(false);
    const loader = FindLoad(ACTION_TYPE);
    const { close } = useModal();
    const [position, setPosition] = useState(true);
    const [geoPos, setCords] = useState({});
    const [isDrawing, setIsDrawing] = useState(false);
    const sigCanvas = useRef(null);

    useEffect(() => {
        navigator.geolocation.watchPosition(function(position) {
                setPosition(true);
                setCords({
                    'lat': position.coords.latitude,
                    'lng': position.coords.longitude,
                });
            },
            function(error) {
                if (error.code === error.PERMISSION_DENIED)
                    setPosition(false);
            });
    }, []);

    const getSignature = async () => {
        const signatureData = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        if (sigCanvas?.current && signatureData && isDrawing) {
            const convertedFile = base64ToFile(signatureData, 'signature.png');
            if (convertedFile) {
                let formData = new FormData();
                formData.append('files', convertedFile);
                try {
                    setUploadLoader(true);
                    const res = await UploadServices.UploadImageThumb(formData);
                    const info = {
                        file: {
                            ...res?.data,
                            name: 'signature',
                        },
                    };
                    dispatch(appointmentActions.appendSignatureToAppmt(appt.id, { ...info, signatureLocation: { ...geoPos }, }));
                    setUploadLoader(false);
                } catch (e) {
                    setUploadLoader(false);
                }
            }
        }
    };

    const clearSignature = () => {
        if (sigCanvas.current) {
            sigCanvas.current.clear();
            setIsDrawing(false);
        }
    };

    const handleMouseDown = () => {
        setIsDrawing(true);
    };

    return (
        <div>
            <div>
                {position === false ?
                    <div className={classes.warningBox}>
                        <div className={classes.warning}>
                            <img src={Images.warning} alt="icon" />
                            <div>
                                <p>
                                    To draw a signature, please allow access to your location
                                </p>
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{ height: 268 }}>
                        <div className="canvas-wrapper">
                            <SignatureCanvas
                                ref={sigCanvas}
                                penColor="black"
                                canvasProps={{ width: 480, height: 100, className: 'sigCanvas' }}
                                onBegin={handleMouseDown}
                            />
                            <div className="canvas-line" />
                            <div className="clear-canvas-btn">
                                <button onClick={clearSignature}>
                                    <img src={Images.clearSignature} alt="icon" />
                                    <p>Clear</p>
                                </button>
                            </div>
                        </div>
                        <p className="draw-info-text">
                            By signing this document with an electronic signature, I agree to
                            the terms and conditions outlined by the clinic.
                        </p>
                    </div>
                }
            </div>
            {isDrawing &&
                <CreateChancel
                    loader={uploadLoader || !!loader?.length}
                    create={'Submit Signature'}
                    chancel={'Cancel'}
                    onCreate={getSignature}
                    onClose={close}
                    buttonWidth="100%"
                />
            }
        </div>
    );
};