import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dropdownsStyle } from './styles';
import { ActiveInactiveStatus, makeCapitalize, useModal } from 'utils';
import { clientActions } from 'store';
import { InactiveModal } from 'components';
import { EDIT_CLIENT_STATUS } from '../../store/client/client.types';

export const UserInputsDropdown = ({ dropdownOptions = [], onPass, selected, type, client }) => {
    const classes = dropdownsStyle();
    const [dropdownIsShown, setDropdownIsShown] = useState(false);
    const [current, setCurrent] = useState({});
    const dispatch = useDispatch();
    const { open, close } = useModal();

    const renderClass = (item) => {
        return ActiveInactiveStatus(item);
    };

    useEffect(() => {
        if (selected) {
            const currentSelected = dropdownOptions?.find((i) => i?.id === selected);
            setCurrent(currentSelected);
        }
    }, [selected]);

    function chooseOptionHandler(option) {
        if (type === 'client') {
            const params = {
                status: option?.id,
            };
            if (option?.id === 'ACTIVE' || option?.id === 'INACTIVE') {
                dispatch(clientActions.editClientStatus(client?.id, params));
                setCurrent(option);
                setDropdownIsShown(false);
            } else {
                open(
                    <InactiveModal
                        actionType={"EDIT_CLIENT_STATUS"}
                               handleSave={(item) => {
                                dispatch(clientActions.editClientStatus(client?.id, { ...item, status: option?.id }));
                                setCurrent(option);
                                setDropdownIsShown(false);
                            }
                        }
                        name={makeCapitalize(`${client?.firstName} ${client?.lastName ? client?.lastName : ''}`)}
                    />,
                );
            }
        } else {
            onPass && onPass(option);
            setCurrent(option);
            setDropdownIsShown(false);
        }
    }

    return (
        <>
            {dropdownIsShown && (
                <div
                    className={classes.dropOverlayStyle}
                    onClick={() => setDropdownIsShown(false)}
                />
            )}
            <div className={`${classes.userDropStyle}`}>
                <div className={`dropdown-box ${dropdownIsShown ? 'focused' : ''}`}>
                    <div
                        className="show-dropdown-box"
                        onClick={() => setDropdownIsShown((prevState) => !prevState)}
                    >
                        <h6 className={`dropdown-selected ${classes.optionStyle} ${renderClass(current?.label)}`}>
                            {current ? current?.label : 'Not Set'}
                        </h6>
                        <i className={`${dropdownIsShown ? 'active' : ''}`} />
                    </div>
                    {dropdownIsShown && (
                        <ul className="dropdown-options">
                            {dropdownOptions?.map((option, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={`${classes.optionStyle} ${renderClass(option?.label)}`}
                                        onClick={() => chooseOptionHandler(option)}
                                    >
                              <span
                                  className={`dropdown-option-title ${option?.id === selected?.id ? 'selected-title' : ''}`}>
                                 {option ? option?.label : 'Not Set'}
                              </span>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            </div>
        </>
    );
};
