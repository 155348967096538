import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ButtonLanding, LandingModal, YoutubeEmbed } from 'components';
import { useWidth } from 'utils';
import { LandingSvg, landingImages } from 'assets';
import { animationParams, animationVariants } from 'fragments';

export const EmpoweringPractices = () => {
    const [videoModalIsOpen, setVideoModalIsOpen] = useState(false);
    const width = useWidth();
    const history = useHistory();

    return (
        <div className='empowering-practices-wrapper'>
            <div className='empowering-practices-block'>
                <div className='empowering-practices-container'>
                    <div className='empowering-practices-box'>
                        <div className='empowering-practices-content'>
                            <div style={{ overflow: 'hidden' }}>
                                <motion.h1
                                    className='content-title'
                                    {...animationParams}
                                    variants={animationVariants.textVariant}
                                >
                                    Hyper-Focused on Usability: <br/>
                                    Streamlining ABA Practice Management with Intuitive, Clutter-Free Solutions
                                </motion.h1>
                            </div>

                            <div className='content-subtitle_margin' style={{overflow:'hidden'}}>
                                <motion.h6
                                    className='content-subtitle'
                                    {...animationParams}
                                    variants={animationVariants.textVariant}
                                >
                                    Streamline your practice management with TherapyLake's innovative EMR, Billing, Scheduling,
                                    and Credentialing features, all designed to ensure unparalleled data integrity and seamless integration.
                                </motion.h6>
                            </div>

                            <div className='content-buttons-block'>
                                <ButtonLanding
                                    className='request-demo-btn'
                                    text='Request a Demo'
                                    onClick={() => history.push('/contact-us')}
                                    big={width <= 768}
                                />

                                <ButtonLanding
                                    className='learn-more-button'
                                    text='Learn more'
                                    contained={false}
                                    arrowIcon={true}
                                    big={width <= 768}
                                    startAdornment={<LandingSvg.PlaySvg className='play-svg' />}
                                    onClick={() => setVideoModalIsOpen(true)}
                                />
                            </div>
                        </div>

                        <div
                            className='empowering-practices-image-block'
                        >
                            <motion.img
                                {...animationParams}
                                variants={animationVariants.imgVariant}
                                src={landingImages.empowering}
                                alt='Empowering Practices'
                            />
                        </div>
                    </div>
                </div>
            </div>

            <LandingModal
                modalClassName={'home-video-modal'}
                modalOpen={videoModalIsOpen}
                closeModal={() => setVideoModalIsOpen(false)}
            >
                <YoutubeEmbed embedId={'4ju2G3KtKNA'} allowFullScreen autoPlay />
            </LandingModal>
        </div>
    );
};


