const permissions = localStorage.getItem('permissions') && JSON.parse(localStorage.getItem('permissions'));

export const RolePermission = (neededPermissions, type) => {
    const userPermission = [];
    permissions && permissions?.map((i) => userPermission.push({ code: i }));

    if (neededPermissions?.length) {
        const uniqSet = new Set();
        neededPermissions?.forEach((permissions) => uniqSet.add(permissions));

        for (let i of userPermission) {
            if (i?.code === 0) {
                return true;
            } else if (uniqSet.has(i?.code)) {
                return true;
            }
        }
    } else {
        return false;
    }
};

export const RolePermissionNoAdmin = (neededPermissions, type) => {
    const userPermission = [];
    permissions && permissions?.map((i) => userPermission.push({ code: i }));

    if (neededPermissions?.length) {
        const uniqSet = new Set();
        neededPermissions?.forEach((permissions) => uniqSet.add(permissions));

        for (let i of userPermission) {
            if (uniqSet.has(i?.code)) {
                return true;
            } else {
                return false;
            }
        }
    } else {
        return false;
    }
};


export const PermissionList = {
    ALL_ACCESS: {
        title: 'Super admin',
        description: 'Super admin can do everything',
        code: 0,
    },

    // Founding Source
    FS_READ: {
        title: 'Funding Source: View information',
        description: 'Allows to view funding source details',
        code: 1.0,
    },
    FS_LIST_READ: {
        title: 'Funding Source: View list',
        description: 'Allows to view list of funding sources',
        code: 1.11,
    },
    FS_DELETE: {
        title: 'Funding Source: Delete',
        description: 'Allows to delete funding sources',
        code: 1.12,
    },
    FS_CREATE: {
        title: 'Funding Source: Create/Edit',
        description: 'Create and edit funding sources ',
        code: 1.1,
    },
    FS_STATUS: {
        title: 'Funding Source: Update Status',
        description: 'Allows to activate or deactivate funding sources',
        code: 1.2,
    },
    FS_SERVICE_READ: {
        title: 'Funding Source: view funding source services',
        description: 'Allows to view funding source services',
        code: 1.3,
    },
    FS_SERVICE_CREATE: {
        title: 'Funding Source: Add/edit funding source services',
        description: 'Allows to add, edit and inactivate funding source services',
        code: 1.4,
    },
    FS_NOTE_READ: {
        title: 'Funding Source: View funding source notes',
        description: 'Allows to view funding source notes',
        code: 1.6,
    },
    FS_NOTE_MANAGE: {
        title: 'Funding Source: Add/edit/delete funding source notes',
        description: 'Allows to add, edit and delete funding source notes',
        code: 1.7,
    },
    FS_HISTORY_READ: {
        title: 'Funding Source: View funding source history',
        description: 'Allows to view funding source history',
        code: 1.5,
    },
    FS_FILE_READ: {
        title: 'Funding Source: View funding source files',
        description: 'Allows to view funding source files',
        code: 1.8,
    },
    FS_FILE_MANAGE: {
        title: 'Funding Source: Add/edit/delete funding source files',
        description: 'Allows to add, edit and delete funding source files',
        code: 1.9,
    },
    // Founding Source End

    // Staff
    STAFF_READ: {
        title: 'Staff: View',
        description: 'Allows to view staff information',
        code: 3,
    },

    STAFF_LIST_READ: {
        title: 'Staff: View List',
        description: 'Allows to view staff list',
        code: 3.11,
    },

    STAFF_CREATE: {
        title: 'Staff: Create',
        description: 'Allows to add and edit staff information',
        code: 3.1,
    },

    STAFF_STATUS: {
        title: 'Staff: Update Status',
        description: 'Allows to activate or inactivate staff',
        code: 3.2,
    },

    STAFF_TIMESHEET_READ: {
        title: 'Staff: View Timesheet',
        description: 'Allows to view the staff timesheet',
        code: 3.3,
    },
    STAFF_PAYCODE_READ: {
        title: 'Staff: View paycodes',
        description: 'Allows to view staff paycodes',
        code: 3.4,
    },
    STAFF_PAYCODE_MANAGE: {
        title: 'Staff: Manage paycodes',
        description: 'Allows to add and update staff paycodes',
        code: 3.44,
    },
    STAFF_CREDENTIAL_READ: {
        title: 'Staff: View credentials',
        description: 'allows to view staff credentials',
        code: 3.5,
    },
    STAFF_CREDENTIAL_MANAGE: {
        title: 'Staff: Manage credentials',
        description: 'allows to add, edit and delete staff credentials',
        code: 3.55,
    },
    STAFF_SERVICE_READ: {
        title: 'Staff: View services',
        description: 'allows to view staff services',
        code: 3.6,
    },
    STAFF_SERVICE_MANAGE: {
        title: 'Staff: Manage services',
        description: 'allows to add, edit and delete staff services',
        code: 3.66,
    },

    STAFF_ROLES_MANAGE: {
        title: 'Add/edit/delete system roles',
        description: 'Allows to add, edit and delete staff roles',
        code: 3.8,
    },

    STAFF_TIMESHEET_CREATE: {
        title: 'Staff: Create Timesheets',
        description: 'Allows to create staff timesheets',
        code: 3.9,
    },
    STAFF_HISTORY_READ: {
        title: 'Staff: View staff history',
        description: 'Allows to view staff history',
        code: 3.17,
    },

    STAFF_NOTE_READ: {
        title: 'Funding Source: View staff notes',
        description: 'Allows to view staff notes',
        code: 3.12,
    },

    STAFF_NOTE_MANAGE: {
        title: 'Staff: Add/edit/delete staff notes',
        description: 'Allows to add, edit and delete staff notes',
        code: 3.13,
    },

    STAFF_FILE_READ: {
        title: 'Staff: View staff files',
        description: 'Allows to view staff files',
        code: 3.7,
    },

    STAFF_FILE_MANAGE: {
        title: 'Staff: Add/edit/delete staff files',
        description: 'Allows to add, edit and delete staff files',
        code: 3.15,
    },

    STAFF_SSN_VIEW: {
        title: 'Staff: View Staff Social Security Number',
        description: 'Allows to view staff Social Security Number',
        code: 3.18,
    },

    // Staff End


    // Role
    ROLES_READ: {
        title: 'Roles: View',
        description: 'Allows to view the list of roles in the system',
        code: 2,
    },

    ROLES_CREATE: {
        title: 'Roles: Add/edit/delete system roles',
        description: 'Allows to add, edit and delete system roles',
        code: 2.1,
    },
    // Staff End


    // Client

    CLIENT_READ: {
        title: 'Client: View',
        description: 'Allows to read client information',
        code: 4.0,
    },
    CLIENT_LIST_READ: {
        title: 'Client: View  List',
        description: 'Allows to view client list',
        code: 4.12,
    },
    CLIENT_CREATE: {
        title: 'Client: Create/update',
        description: 'Allows to create new clients in the system and update existing ones',
        code: 4.3,
    },
    CLIENT_STATUS: {
        title: 'Client: Change status',
        description: 'Allows to create new clients in the system',
        code: 4.4,
    },
    CLIENT_ENROLLMENT_READ: {
        title: 'Client: View enrollment',
        description: 'Allows to read client enrollment information',
        code: 4.1,
    },
    CLIENT_ENROLLMENT_MANAGE: {
        title: 'Client: Manage enrollments',
        description: 'Allows to manage the enrollment information of the clients',
        code: 4.5,
    },
    CLIENT_AUTHORIZATION_READ: {
        title: 'Client: View authorization',
        description: 'Allows to read client authorization',
        code: 4.2,
    },
    CLIENT_AUTHORIZATION_MANAGE: {
        title: 'Client: Manage authorizations',
        description: 'Allows to manage the client authorizatons',
        code: 4.6,
    },

    CLIENT_CONTACT_READ: {
        title: 'Client: View contacts',
        description: 'Allows to view client contacts',
        code: 4.8,
    },

    CLIENT_CONTACT_MANAGE: {
        title: 'Client: Add/edit/delete contacts',
        description: 'Allows to add, edit and delete client contacts',
        code: 4.88,
    },

    CONNECTED_CLIENT_READ: {
        title: 'Client: Read connected clients',
        description: 'Allows to read connected clients',
        code: 4.11,
    },
    CLIENT_HISTORY_READ: {
        title: 'Client: view client history',
        description: 'Allows to view client history',
        code: 4.13,
    },

    CLIENT_NOTE_READ: {
        title: 'Client: View client notes',
        description: 'Allows to view client notes',
        code: 4.14,
    },

    CLIENT_NOTE_MANAGE: {
        title: 'Client: Add/edit/delete client notes',
        description: 'Allows to add, edit and delete client notes',
        code: 4.15,
    },
    CLIENT_FILE_READ: {
        title: 'Client: View client files',
        description: 'Allows to view client files',
        code: 4.7,
    },
    CLIENT_FILE_MANAGE: {
        title: 'Client: Add/edit/delete client files',
        description: 'Allows to add, edit and delete client files',
        code: 4.17,
    },
    CLIENT_STAFF_ASSIGNMENT: {
        title: 'Client: Manage Staff Assignment',
        description: 'Allows to manage staff assignment',
        code: 4.18,
    },
    // Client End

    // Appt
    APPT_READ: {
        title: 'Schedule: View',
        description: 'Allows to view the client schedule',
        code: 5.0,
    },
    APPT_SERVICE_CREATE: {
        title: 'Schedule: Create service appointments',
        description: 'Allows to create service appointments',
        code: 5.1,
    },
    APPT_DRIVE_CREATE: {
        title: 'Schedule: Create drive appointments',
        description: 'Allows to create drive appointments',
        code: 5.2,
    },
    APPT_BREAK_CREATE: {
        title: 'Schedule: Create break appointments',
        description: 'Allows to create break appointments',
        code: 5.3,
    },
    APPT_PTO_CREATE: {
        title: 'Schedule: Create PTO appointments',
        description: 'Allows to create PTO appointments',
        code: 5.4,
    },
    APPT_UNPAID_CREATE: {
        title: 'Schedule: Create Unpaid appointments',
        description: 'Allows to create Unpaid appointments',
        code: 5.13,
    },

    APPT_STAFF_CREATE: {
        title: 'Schedule: Create staff appointments',
        description: 'Allows to create staff appointments',
        code: 5.5,
    },
    APPT_SIGNATURE_TOGGLE_MANAGE: {
        title: 'Schedule: Appointment signature toggle',
        description: 'Allows to turn on or off signature toggle on appointments',
        code: 5.6,
    },

    APPT_CANCEL: {
        title: 'Schedule: Cancel staff appointments',
        description: 'Allows to cancel staff appointments',
        code: 5.7,
    },
    APPT_RENDER_COMPLETE: {
        title: 'Schedule: Render appointments',
        description: 'Allows to render appointments',
        code: 5.8,
    },
    APPT_RECURE: {
        title: 'Schedule: Recure appointments',
        description: 'Allows to recure appointments',
        code: 5.9,
    },
    APPT_CONNECTED_CREATE: {
        title: 'Schedule: Create connected appointments',
        description: 'Allows to create appointments with connected staffs',
        code: 5.11,
    },
    UNRENDERED_APPT_DELETE: {
        title: 'Schedule: Delete unrendered appointments',
        description: 'Allows to delete unrendered appointments',
        code: 5.12,
    },
    APPT_NOTE_TOGGLE_MANAGE: {
        title: 'Schedule: Appointment note toggle',
        description: 'Allows to turn on or off note toggle on appointments',
        code: 5.14,
    },

    APPT_LOCK: {
        title: 'Schedule: Lock and unlock appointments',
        description: 'Allows to lock or unlock appointments',
        code: 5.15,
    },
    LOCKED_APPT_EDIT: {
        title: 'Schedule: Edit locked appointments',
        description: 'Allows to edit locked appointments',
        code: 5.16,
    },
    APPT_SELF_CREATE: {
        title: 'Create self appointments',
        description: 'Allows to create appointments only for yourself',
        code: 5.17,
    },
    APPT_CONNECTED_READ: {
        title: 'Schedule: View connected client Schedule',
        description: 'Allows to view the connected client schedule',
        code: 5.18,
    },
    APPT_SICK_TIME_CREATE: {
        title: 'Schedule: Create Sick Time appointments',
        description: 'Allows to create Sick Time appointments',
        code: 5.19,
    },
    APPT_HISTORY_READ: {
        title: 'Schedule: View appointment history',
        description: 'Allows to view appointment history',
        code: 5.24,
    },
    APPT_FILE_READ: {
        title: 'Schedule: View appointment files',
        description: 'Allows to view appointment files',
        code: 5.25,
    },
    APPT_FILE_MANAGE: {
        title: 'Schedule: Add/edit/delete appointment files',
        description: 'Allows to add, edit and delete appointment files',
        code: 5.26,
    },
    APPT_SELF_DELETE: {
        title: 'Schedule: Delete self appointments',
        description: 'Allows to delete appointments only for yourself',
        code: 5.22,
    },
    APPT_SELF_UPDATE: {
        title: 'Schedule: Update self appointments',
        description: 'Allows to update appointments only for yourself',
        code: 5.23,
    },
    APPT_CREATE_FREQUENCY: {
        title: 'Schedule: Create appt frequency',
        description: 'Allows to create appt with frequency limit',
        code: 5.27,
    },

    // Appt End

    //System
    SYSTEM_SERVICE_TYPES: {
        title: 'System: Manage service types',
        description: 'Allows to add/edit system service types',
        code: 10.0,
    },
    SYSTEM_PAYCODE_TYPES: {
        title: 'System: Manage paycode types',
        description: 'Allows to add/edit system paycode types',
        code: 10.1,
    },
    SYSTEM_CREDENTIALS: {
        title: 'System: Manage credentials',
        description: 'Allows to add/edit system credentials',
        code: 10.2,
    },
    SYSTEM_DEPARTMENTS: {
        title: 'System: Manage depatments',
        description: 'Allows to add/edit system depatments',
        code: 10.3,
    },
    SYSTEM_JOB_TITLES: {
        title: 'System: Manage job titles',
        description: 'Allows to add/edit system job titles',
        code: 10.4,
    },
    SYSTEM_PLACE_OF_SERVICE: {
        title: 'System: Manage place of services',
        description: 'Allows to add/edit system job place of services',
        code: 10.5,
    },
    SYSTEM_MILEAGE: {
        title: 'System: Manage mileage compensation',
        description: 'Allows to add/edit system mileage compensation',
        code: 10.6,
    },
    SYSTEM_OVERTIME: {
        title: 'System: Manage overtime rules',
        description: 'Allows to add/edit system overtime rules',
        code: 10.7,
    },
    // System End

    CLAIMS_READ: {
        title: 'Claim: View',
        description: 'Allows to View claims',
        code: 6.0,
    },
    CLAIMS_CREATE: {
        title: 'Claim: Generate',
        description: 'Allows to generate claims',
        code: 6.1,
    },
    CLAIMS_UPDATE: {
        title: 'Claim: Update',
        description: 'Allows to update claims',
        code: 6.2,
    },
    CLAIMS_CLOSE: {
        title: 'Claim: Close',
        description: 'Allows to close claims',
        code: 6.3,
    },


    // NEW
    //
    TIMESHEET_READ: {
        title: 'Timesheet: View timesheet',
        description: 'Allows to view the timesheet',
        code: 12.1,
    },
    TIMESHEET_CREATE: {
        title: 'Timesheet: Create timesheets',
        description: 'Allows to create timesheets',
        code: 12.2,
    },
    TIMESHEET_UPDATE: {
        title: 'Timesheet: Update timesheets',
        description: 'Allows to update timesheets',
        code: 12.3,
    },
    TIMESHEET_DELETE: {
        title: 'Timesheet: Delete timesheets',
        description: 'Allows to delete timesheets',
        code: 12.4,
    },
    TIMESHEET_PROCESS: {
        title: 'Timesheet: Process timesheets',
        description: 'Allows to process timesheets',
        code: 12.5,
    },
    TIMESHEET_NOTE_READ: {
        title: 'Timesheet: View timesheet notes',
        description: 'Allows to view timesheet notes',
        code: 12.8,
    },
    TIMESHEET_NOTE_MANAGE: {
        title: 'Timesheet: Add/edit/delete timesheet notes',
        description: 'Allows to add, edit and delete timesheet notes',
        code: 12.9,
    },
    TIMESHEET_HISTORY_READ: {
        title: 'Timesheet: View timesheet history',
        description: 'Allows to view timesheet history',
        code: 12.11,
    },
    SELF_TIMESHEET_READ: {
        title: 'Timesheet: View self timesheet',
        description: 'Allows to view self timesheet',
        code: 12.13,
    },

    //  Invoice

    INVOICE_NOTE_READ: {
        title: 'Timesheet: View invoice notes',
        description: 'Allows to view invoice notes',
        code: 7.6,
    },
    INVOICE_NOTE_MANAGE: {
        title: 'Timesheet: Add/edit/delete invoice notes',
        description: 'Allows to add, edit and delete invoice notes',
        code: 7.7,
    },
    INVOICE_HISTORY_READ: {
        title: 'Claim: View invoice history',
        description: 'Allows to view invoice history',
        code: 7.8,
    },

    INVOICE_FILE_READ: {
        title: 'Claim: View invoice files',
        description: 'Allows to view invoice files',
        code: 7.4,
    },

    INVOICE_FILE_MANAGE: {
        title: 'Claim: Add/edit/delete invoice files',
        description: 'Allows to add, edit and delete invoice files',
        code: 7.5,
    },

    // NOT Finilized



    TIMESHEET_FILE_READ: {
        title: 'Timesheet: View timesheet files',
        description: 'Allows to view timesheet files',
        code: 12.6,
    },
    TIMESHEET_FILE_MANAGE: {
        title: 'Timesheet: Add/edit/delete timesheet files',
        description: 'Allows to add, edit and delete timesheet files',
        code: 12.7,
    },



    //




    REMOVE_CLAIM_APPT: {
        title: 'Claim: Remove appt from claim',
        description: 'Allows to remove appointment from claim',
        code: 6.4,
    },
    APPT_UNRENDER: {
        title: 'Schedule: Unrender appointment',
        description: 'Allows to unrender appointment',
        code: 5.21,
    },
    CLAIM_REFRESH: {
        title: 'Claim: Refresh claim data',
        description: 'Allows to refresh claim data',
        code: 6.5,
    },
    CLAIM_HISTORY_READ: {
        title: 'Claim: View claim history',
        description: 'Allows to view claim history',
        code: 6.6,
    },
    CLAIM_NOTE_READ: {
        title: 'Claim: View claim notes',
        description: 'Allows to view claim notes',
        code: 6.7,
    },
    CLAIM_NOTE_MANAGE: {
        title: 'Claim: Add/edit/delete claim notes',
        description: 'Allows to add, edit and delete claim notes',
        code: 6.8,
    },
    CLAIM_FILE_READ: {
        title: 'Claim: View claim files',
        description: 'Allows to view claim files',
        code: 6.9,
    },
    CLAIM_FILE_MANAGE: {
        title: 'Claim: Add/edit/delete claim files',
        description: 'Allows to add, edit and delete claim files',
        code: 6.11,
    },


    // END NEW


    INVOICE_READ: {
        title: 'Invoice: View',
        description: 'Allows to View invoices',
        code: 7.0,
    },
    INVOICE_CREATE: {
        title: 'Invoice: Generate',
        description: 'Allows to generate invoices',
        code: 7.1,
    },
    INVOICE_UPDATE: {
        title: 'Invoice: Update',
        description: 'Allows to update invoices',
        code: 7.2,
    },
    INVOICE_CLOSE: {
        title: 'Invoice: Close',
        description: 'Allows to close invoices',
        code: 7.3,
    },

    CLAIM_PMT_READ: {
        title: 'Claim-pmt: View',
        description: 'Allows to view claim payments',
        code: 8.0,
    },
    CLAIM_PMT_PAY: {
        title: 'Claim-pmt: Apply payment',
        description: 'Allows to pay the claim payments',
        code: 8.1,
    },
    CLAIM_PMT_SEND_APPEAL: {
        title: 'Claim-pmt: Send to appeal',
        description: 'Allows to send claims to appeals',
        code: 8.2,
    },
    CLAIM_PMT_APPEAL_READ: {
        title: 'Claim-pmt: View appeals',
        description: 'Allows to view appeals',
        code: 8.3,
    },
    CLAIM_PMT_APPEAL_PAY: {
        title: 'Claim-pmt: Pay appeal claims',
        description: 'Allows to pay for appeal claims',
        code: 8.4,
    },

    INVOICE_PMT_READ: {
        title: 'Invoice-pmt: View',
        description: 'Allows to view the invoice payments',
        code: 9.0,
    },
    INVOICE_PMT_PAY: {
        title: 'Invoice-pmt: Pay',
        description: 'Allows to pay for invoices',
        code: 9.1,
    },
    INVOICE_PMT_CLOSE: {
        title: 'Invoice-pmt: Close',
        description: 'Allows to close invoices',
        code: 9.2,
    },

    REPORT_MANAGE: {
        title: 'Report: Manage reports',
        description: 'Allows to manage reports',
        code: 11,
    },
};