import { v4 as uuidv4 } from 'uuid';
import { clientStatuses } from 'utils';

export const utilizationReport = ( ) => {

    return {
        title: 'Authorization Utilization Report',
        url: '/reports/billing/auth/utilization',
        modalType: 'client',
        list:
            [
                {
                    type: 'selectMultiple',
                    name: 'clientIds',
                    label: 'Client',
                    loadType: 'GET_CLIENTS',
                    renderValue: (option) => `${option?.lastName} ${option?.firstName}`,
                },
                {
                    type: 'selectMultiple',
                    name: 'clientStatuses',
                    label: 'Client Status',
                    renderValue: (option) => option?.name,
                    selectList: [
                        ...clientStatuses
                    ],
                },
                {
                    type: 'select',
                    name: 'payer',
                    label: 'Payer',
                    loadType: 'GET_FUNDING_SOURCE',
                    renderValue: (option) => option?.name,
                },
                // { type: 'dos', startDate: 'start', endDate: 'end',  label: 'Auth. Start Date ', selectList: [] },
                { type: 'date', name: 'authStartDate', label: 'Auth. Start Date ' },
            ],
    };
};

export const billingReportEnums = () => {

    return [
        {
            id: uuidv4(),
            name: 'Authorization Utilization Report',
            description: 'Client authorizations with utilization of authorized units and hours.',
            generateInfo: utilizationReport(),
        },
    ];
};