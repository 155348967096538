import { v4 as uuidv4 } from 'uuid';
import { credentialTypesEnums } from 'utils';

export const staffCredentialReport = () => {

    return {
        title: 'Staff Credentials Expiration Report',
        url: '/reports/staff/credential/expiration',
        modalType: 'staff',
        list:
            [
                {
                    type: 'select',
                    name: 'staffStatus',
                    label: 'Staff Status',
                    selectList: [
                        { name: 'Active', id: 'ACTIVE' },
                        { name: 'Inactive', id: 'INACTIVE' },
                    ],
                    renderValue: (option) => option?.name,
                },
                { type: 'date', name: 'credentialExpirationDate', label: 'Credential Expiration Date' },
                {
                    type: 'select',
                    name: 'credentialType',
                    label: 'Credential Type',
                    selectList: credentialTypesEnums,
                    renderValue: (option) => option?.name,
                },
            ],
    };
};


export const staffReportEnums = [
    {
        id: uuidv4(),
        name: 'Staff Credentials Expiration Report',
        description: 'Staff credentials nearing expiration to ensure compliance.',
        generateInfo: staffCredentialReport(),
    },

];