import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FileUploader } from 'react-drag-drop-files';
import { DownloadFile, FindLoad, getFileType, Images, renderSize, useModal } from 'utils';
import { CreateChancel, MinLoader, TextRow } from 'components';
import { appointmentActions, UploadServices } from 'store';

const ACTION_TYPE = 'APPEND_SIGNATURE_TO_APPMT';
export const Uploader = ({ appt }) => {
    const dispatch = useDispatch();
    const [currentFile, setCurrentFile] = useState(null);
    const [uploadLoader, setUploadLoader] = useState(false);
    const loader = FindLoad(ACTION_TYPE);
    const { loadDownload, handleDownload } = DownloadFile();
    const { close } = useModal();
    const alreadyUploaded = currentFile?.file?._id;

    useEffect(() => {
        if (appt?.signature?._id) {
            setCurrentFile({ file: appt.signature });
        }
    }, [appt]);

    const handleFileChange = async (e) => {
        const pos = e.type.slice(e.type.search('/'));
        setCurrentFile({ file: e, type: pos.substring(1) });
    };

    const handleAddFile = async () => {
        if (currentFile) {
            let formData = new FormData();
            formData.append('files', currentFile.file);
            try {
                setUploadLoader(true);
                const res = await UploadServices.UploadImageThumb(formData);
                const info = {
                    file: {
                        ...res?.data,
                        name: currentFile.file.name,
                    },
                };
                dispatch(appointmentActions.appendSignatureToAppmt(appt.id, info));
                setUploadLoader(false);
            } catch (e) {
                setUploadLoader(false);
            }
        }
    };

    const handleRemoveUploaded = () => {
        setCurrentFile(appt?.signature?._id ? { file: appt?.signature } : null);
    };

    return (
        <div className="space-between-column">
            <div style={{ height: 268 }}>
                <FileUploader handleChange={handleFileChange} name="file">
                    <div className="drag-drop-signature-component">
                        <div className="flex-align-center" style={{ gap: 8 }}>
                            <img src={Images.uploadCloudBlack} alt="icon" />
                            <label className="custom-file-upload">
                                <input
                                    onClick={(event) => (event.target.value = null)}
                                    onChange={handleFileChange}
                                    type="file"
                                    id="file"
                                />
                                <i className="fa fa-cloud-upload" />
                                <div className="upload">
                                    <p>Drag and drop (e.g., PNG or JPG max size 5MB.)</p>
                                </div>
                            </label>
                        </div>
                        <p className="upload-or"> OR </p>
                        <button className="signature-upload-btn">
                            Upload Signature
                        </button>
                    </div>
                </FileUploader>

                {currentFile &&
                    <div className="uploaded-signature-box">
                        <img src={alreadyUploaded ? Images.alreadyUploadedSignatureIcon : Images.signatureIcon}
                             alt="icon" />
                        <div className="full-width">
                            <div className="full-width">
                                <div className="space-between-align-start">
                                    <p className="uploaded-signature-name full-width">
                                        <TextRow
                                            name={alreadyUploaded ? currentFile?.file?.originalName : currentFile?.file?.name} />
                                    </p>
                                    <div className="flex-align-center" style={{ gap: 10, height: 20 }}>
                                        {alreadyUploaded &&
                                            <button className="reset-btn-no-paddings"
                                                    onClick={() => handleDownload(currentFile?.file)}>
                                                {loadDownload ?
                                                    <MinLoader
                                                        small={true}
                                                        margin={'0'} color={'#172B4D'} position={'relative'} />
                                                    :
                                                    <img style={{
                                                        height: 20,
                                                        filter: 'invert(11%) sepia(82%) saturate(1066%) hue-rotate(194deg) brightness(90%) contrast(92%)',
                                                    }} src={Images.downloadCloudBlue} alt={'download'} />
                                                }
                                            </button>
                                        }
                                        {alreadyUploaded &&
                                            <a href={currentFile?.file?.url} target={'_blank'}>
                                                <img style={{ height: 20 }} src={Images.showEye} alt={'view'} />
                                            </a>
                                        }
                                        {!alreadyUploaded &&
                                            <button className="reset-btn-no-paddings" onClick={handleRemoveUploaded}>
                                                <img style={{ height: 20 }} src={Images.redTrash} alt={'icon'} />
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="flex-align-center uploaded-signature-information"
                                 style={{ margin: '4px 0' }}>
                                <p>{alreadyUploaded ? getFileType(currentFile?.file?.originalName) : currentFile?.file?.type?.replace('image/', '')}</p>
                                {!alreadyUploaded && <p>{renderSize(currentFile?.file)}</p>}
                            </div>

                            {!alreadyUploaded &&
                                <div className="full-loaded">
                                    <div className="full-loaded-line" />
                                    <p>100%</p>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>

            {currentFile &&
                <CreateChancel
                    loader={uploadLoader || !!loader?.length}
                    create={'Submit Signature'}
                    chancel={'Cancel'}
                    onCreate={handleAddFile}
                    onClose={close}
                    buttonWidth="100%"
                />
            }
        </div>
    );
};