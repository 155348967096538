import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';
import { DateTypeSelector, SelectTypeAutocomplete } from 'components';
import { analyticActions } from 'store';
import { FindLoad } from 'utils';
import { checkFilter, DefaultDates, WidgetCategories } from './constants';
import { AddWidget, WidgetCard } from './widgetFragments';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { WidgetDateSelector } from './widgetFragments/widgetDateSelector';
import Carousel from 'nuka-carousel';

const ACTION_TYPE = 'GET_USER_KPIS';
export const Widgets = () => {
    const { userKpis } = useSelector((state) => ({
        userKpis: state.analytics.userKpis,
    }));
    const dispatch = useDispatch();
    const loader = FindLoad(ACTION_TYPE);
    const [isDragging, setIsDragging] = useState(false);
    const history = useHistory();
    const info = history?.location?.state;
    const { allDates } = DefaultDates(info);
    const [inputs, setInputs] = useState({})

    useEffect(() => {
        dispatch(analyticActions.getUserKpis(allDates));
    }, [info]);

    const sensors = useSensors(
        useSensor(PointerSensor, { activationConstraint: { distance: 10 } }),
        useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }),
    );

    function reorderArray(array, fromIndex, toIndex) {
        if (fromIndex === -1 || toIndex === -1) {
            return array;
        }
        const updatedArray = [...array];
        const [movedItem] = updatedArray.splice(fromIndex, 1);
        updatedArray.splice(toIndex, 0, movedItem);
        return updatedArray;
    }

    const handleDragEnd = (event) => {
        setIsDragging(false);
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = userKpis?.findIndex((item) => item.id === active.id);
            const newIndex = userKpis?.findIndex((item) => item.id === over.id);
            const updatedArray = reorderArray(userKpis, oldIndex, newIndex); // Us
            dispatch(analyticActions.reorderKpis(updatedArray, allDates));
        }
    };
    const handleDragStart = () => {
        setIsDragging(true);
    };


    const handleChange = (e) => {
        const params = {
            ...inputs
        };
        if (e.target.value === 'All' || !e.target.value) {
            delete params[e.target.name];
        } else {
            params[e.target.name] = e.target.value;
        }
        setInputs(params)
    };


    return (
        <div>
            <div className="widget-filters-box">
                <div className="flex-align-center" style={{ gap: 16 }}>
                    <SelectTypeAutocomplete
                        placeholder={inputs?.category ? '' : 'All'}
                        name={'category'}
                        handleSelect={handleChange}
                        defaultValue={inputs?.category}
                        list={WidgetCategories}
                        renderValue={(i) => i?.name}
                        uiType={'tableFilter'}
                        outLabel={'Category'}
                        noError={true}
                    />
                    <WidgetDateSelector
                        startName={'startDate'}
                        endName={'endDate'}
                        outLabel={'Date Range'}
                        noError={true}
                    />
                    {/*<DateTypeSelector*/}
                    {/*    noError={true}*/}
                    {/*    name={'from'}*/}
                    {/*    outLabel={'Date Range'} */}
                    {/*    // handleGetDates={handleChange}*/}
                    {/*    // values={inputs}*/}
                    {/*    // max={inputs.to ? moment.utc(inputs.to).format('YYYY-MM-DD') : ''}*/}
                    {/*/>*/}
                    {/*<SelectTypeAutocomplete*/}
                    {/*    // placeholder={pushInfo?.payer ? '' : 'All'}*/}
                    {/*    name={'payer'}*/}
                    {/*    // handleSelect={handleChange}*/}
                    {/*    // defaultValue={pushInfo?.payer}*/}
                    {/*    list={*/}
                    {/*        [{ id: 'All', name: 'All' }]*/}
                    {/*    }*/}
                    {/*    renderValue={(i) => i?.name}*/}
                    {/*    uiType={'tableFilter'}*/}
                    {/*    outLabel={'Comparison '}*/}
                    {/*    noError={true}*/}
                    {/*/>*/}
                </div>
                <AddWidget />
            </div>


            <div className="widget-cards-wrapper">

                {isDragging && (
                    <div className="widget-card-back-drop" />
                )}

                {loader?.length && !userKpis?.length ?
                    <div className="flex-align-center" style={{ gap: 16 }}>
                        <Skeleton variant="rounded" width={250} height={150} />
                        <Skeleton variant="rounded" width={250} height={150} />
                        <Skeleton variant="rounded" width={250} height={150} />
                    </div>
                    :
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}
                    >
                        <SortableContext items={userKpis?.map((item) => item.id)}>
                            {!!userKpis?.length > 0 && userKpis?.map((item, index) => checkFilter(inputs, item) && (
                                <Fragment key={index}>
                                    <WidgetCard item={item} isDragging={isDragging} />
                                </Fragment>
                            ))}
                        </SortableContext>
                    </DndContext>
                }
            </div>
        </div>
    );
};