import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Images, SaveParams, useModal } from 'utils';
import { CustomDelete } from 'components';
import { useDispatch } from 'react-redux';
import { analyticActions } from 'store';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DataTypes, ValueFormatter } from '../constants';
import { RenderWidgetImage } from './constnats';

const DELETE_ACTION_TYPE = 'DELETE_USER_KPI';
export const WidgetCard = ({ item, isDragging }) => {
    const { open, close } = useModal();
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const [currentId, setCurrentId] = useState(null);
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: item.id });

    const pushChart = () => {
        if(info?.activeChart?.id !== item?.id) {
            const params = {
                ...info,
                chartType: item?.chartTypes[0],
            };
            params.activeChart = item;
            SaveParams(history, params);
        }
    };

    const resetChart = () => {
        const params = {
            ...info,
        };
        delete params.activeChart;
        delete params.chartType;
        SaveParams(history, params);
    };

    return (
        <div
            ref={setNodeRef} style={{
            border: info?.activeChart?.id === item?.id ? '2px solid #0C66E4' : 'none',
            transform: CSS.Transform.toString(transform),
            transition,
            cursor: 'grab',
            zIndex: isDragging ? 2001 : 1,
        }} {...attributes} {...listeners}
            className={`widget-card-box`}
            onClick={pushChart}
            onMouseEnter={() => currentId !== item?.id && setCurrentId(item?.id)}
            onMouseLeave={() => setCurrentId(null)}
        >
            <div className="widget-card-info">
                <div className="delete-widget">
                    <button>
                        {currentId === item?.id &&
                            <img src={isDragging ? Images.dragIndicatorBlue : Images.dragIndicator} alt="icon" />
                        }
                    </button>
                    <button onClick={(e) => {
                        e.stopPropagation();
                        open(
                            <CustomDelete
                                info={`Remove ${item?.name} Widget!`}
                                text={`Are you sure you want to remove this ${item?.name} widget? You can add it back anytime.`}
                                handleDel={() => dispatch(analyticActions.deleteUserKpi(item?.id))}
                                afterSuccess={resetChart}
                                handleClose={close}
                                innerText={'Remove'}
                                actionType={DELETE_ACTION_TYPE}
                            />);
                    }}>
                        <img src={Images.closeGray} alt="close" />
                    </button>
                </div>
                <div className="widget-card-icon-name-box">
                    <img className="widget-card-icon" src={RenderWidgetImage(item?.category)} alt="" />
                    <p>{item?.name}</p>
                </div>
            </div>


            <div className="widget-card-rate-box">
                <p className="rate">
                    {ValueFormatter(item?.value, item?.valueFormat)}
                </p>

                {item?.type === DataTypes.PERIODIC && !!item?.comparison &&
                    (item?.comparison > 0 ?
                            <div className="plus-percentage">
                                <img src={Images.trendUp} alt="" />
                                <p>{`+${item?.comparison?.toFixed(2)}%`} </p>
                            </div>
                            :
                            <div className="minuse-percentage">
                                <img src={Images.trendDown} alt="" />
                                <p>{`${item?.comparison?.toFixed(2)}%`} </p>
                            </div>
                    )
                }
            </div>
        </div>
    );
};
