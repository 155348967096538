import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { claimActions } from 'store';
import {
    DateRow,
    Loader,
    PriceRow,
    TableNoInfo,
    SearchAndFilerFull,
    PaginationFillTable,
    LinkRow,
    NoItemsYet,
    DateRowStartEnd,
    BatchFlagRow,
} from 'components';
import { FindLoad, Images, PermissionList, RolePermission, SendPageSave, useModal } from 'utils';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { FormType1500 } from './formType1500';
import {
    billedClaimNotYet, submittedHeader,
} from './constants';
import { Show1500Form } from './show1500Form';

const ACTION_TYPE = 'GET_SUBMITTED_CLAIMS';
export const SubmittedByBatch = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const submittedClaimsData = useSelector((state) => state.claim.submittedClaims);
    const loader = FindLoad(ACTION_TYPE);
    const [expanded, setExpanded] = useState('');
    const [listInfo, setListInfo] = useState(submittedClaimsData?.batches);
    const [searched, setSearched] = useState({ name: '', text: '' });
    const { open } = useModal();

    useEffect(() => {
        if (submittedClaimsData?.batches && !searched?.value) {
            setListInfo(submittedClaimsData?.batches);
        }
    }, [submittedClaimsData]);

    const renderParams = () => {
        const sendInfo = {
            ...info,
        };
        delete sendInfo.page;
        delete sendInfo.tabType;
        sendInfo.skip = info?.skip ? info?.skip : 0;
        sendInfo.limit = 50;
        return sendInfo;
    };

    useEffect(() => {
        dispatch(claimActions.getSubmittedClaims({ ...renderParams() }));
    }, [info]);

    const handleChange = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        const currentId = expanded === id ? '' : id;
        setExpanded(currentId);
    };

    const changePage = (number) => {
        setSearched({ name: '', text: '' });
        SendPageSave(number, info, history);
    };

    const openDownloadModal = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        open(<FormType1500 item={item} batch={true} />);
    };

    return (
        <div>
            <>
                <div className="accordion-head-wrapper" style={{ marginTop: '24px' }}>
                    {submittedHeader?.map((item, i) => (
                        <div className="accordion-head-item" style={{ width: item?.width }} key={i}>
                            <SearchAndFilerFull
                                searched={searched}
                                list={listInfo}
                                setListInfo={setListInfo}
                                item={item}
                                setSearched={setSearched}
                            />
                        </div>
                    ))}
                </div>

                {
                    loader?.length ?
                        <Loader />
                        :
                        listInfo?.length ?
                            <div className="accordion-body-box">
                                {listInfo?.map((item, j) => (
                                    <React.Fragment key={j}>
                                        <Accordion
                                            expanded={expanded === item?.id}
                                            onChange={(e) => handleChange(e, item?.id)}
                                        >
                                            <AccordionSummary
                                                aria-controls="panel1bh-content"
                                                id={item?.id}
                                            >
                                                <div className="accordion-body-wrapper">
                                                    <div className="accordion-body-item" style={{ width: '15%' }}>
                                                        <BatchFlagRow id={item?.displayId} notShowResolve={true}
                                                                      flag={item?.removedFromClaimFlag} />
                                                    </div>
                                                    <div className="accordion-body-item" style={{ width: '20%' }}>
                                                        {item?.submittedDate ?
                                                            <DateRow date={item?.submittedDate} /> : 'N/A'}
                                                    </div>
                                                    <div className="accordion-body-item" style={{ width: '25%' }}>
                                                        <LinkRow
                                                            name={item?.submittedByFullName ? item?.submittedByFullName : 'N/A'}
                                                            href={RolePermission([PermissionList.STAFF_READ.code]) ? `/staff/${item?.submittedById}` : ''}
                                                            checkPermission={item?.submittedById ? RolePermission([PermissionList.STAFF_READ.code]) : null}
                                                            textWidth={8}
                                                        />
                                                    </div>
                                                    <div className="accordion-body-item" style={{ width: '20%' }}>
                                                        <DateRow date={item?.dos?.early} /> - <DateRow
                                                        date={item?.dos?.latest} />
                                                    </div>
                                                    <div className="accordion-body-item action-btn flex-align-center"
                                                         style={{ width: '20%', gap: 12 }}>
                                                        <button
                                                            onClick={(e) => openDownloadModal(e, item)}
                                                            className="download-1500">
                                                            <p>Download</p>
                                                            <img src={Images.download} alt="" />
                                                        </button>
                                                        <Show1500Form item={item} batch={true} />
                                                    </div>

                                                    <div style={{ position: 'absolute', right: 20 }}>
                                                        <img
                                                            className="down-up-icon"
                                                            style={expanded === item?.id ? { transform: 'rotate(-180deg)' } : {}}
                                                            src={Images.dropDown} alt="icon" />
                                                    </div>
                                                </div>
                                            </AccordionSummary>

                                            <AccordionDetails>
                                                <div>
                                                    <div className="batch-claims-head">
                                                        <p style={{ width: '15%' }}>Claim ID</p>
                                                        <p style={{ width: '20%' }}>DOS</p>
                                                        <p style={{ width: '15%' }}>Funding Source</p>
                                                        <p style={{ width: '15%' }}>Client Code</p>
                                                        <p style={{ width: '13%' }}>Billed Amount</p>
                                                    </div>
                                                    {item?.claims?.length ? item?.claims?.map((i) => (
                                                            <div key={i?.id} className="batch-claims-body"
                                                                 onClick={() => history.push(`/billedClaim/${i?.id}`, { from: 'submitted' })}>
                                                                <div style={{ width: '15%' }} className="batch-item">
                                                                    {i?.displayId ? i?.displayId : 'N/A'}
                                                                </div>
                                                                <div style={{ width: '20%' }} className="batch-item">
                                                                    {i?.dos?.early && i?.dos?.latest ?
                                                                        <DateRowStartEnd
                                                                            start={i?.dos?.early}
                                                                            end={i?.dos?.latest}
                                                                        />
                                                                        :
                                                                        'N/A'
                                                                    }
                                                                </div>
                                                                <div style={{ width: '15%' }} className="batch-item">
                                                                    <LinkRow
                                                                        name={i?.funderName ? i.funderName : 'N/A'}
                                                                        href={RolePermission([PermissionList.FS_READ.code]) ? `/fundingSource/${i?.funderId}` : ''}
                                                                        checkPermission={i?.funderId ? RolePermission([PermissionList.FS_READ.code]) : null}
                                                                    />
                                                                </div>
                                                                <div style={{ width: '15%' }} className="batch-item">
                                                                    <LinkRow
                                                                        name={i?.clientCode ? i?.clientCode : 'N/A'}
                                                                        href={RolePermission([PermissionList.CLIENT_READ?.code]) ? `/client/${i?.clientId}` : ''}
                                                                        checkPermission={i?.clientId ? RolePermission([PermissionList.CLIENT_READ?.code]) : null}
                                                                    />
                                                                </div>
                                                                <div style={{ width: '13%' }} className="batch-item">
                                                                    <PriceRow info={i?.billedAmount} />
                                                                </div>
                                                            </div>
                                                        ))
                                                        :
                                                        <TableNoInfo text={`No claims yet`}
                                                                     styles={{ position: 'relative' }} />
                                                    }
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </React.Fragment>
                                ))
                                }
                            </div>
                            :
                            <div style={{ position: 'relative' }}>
                                <NoItemsYet
                                    text={billedClaimNotYet.title}
                                    subTitle={billedClaimNotYet.subTitle}
                                    image={billedClaimNotYet.image}
                                />
                            </div>
                }

                {!!submittedClaimsData?.count > 0 && listInfo?.length > 0 &&
                    <div style={{ height: '76px' }}>
                        <PaginationFillTable
                            listLength={submittedClaimsData?.batches?.length}
                            page={info?.page ? info?.page : 1}
                            handleReturn={(number) => changePage(number)}
                            count={submittedClaimsData?.count}
                        />
                    </div>
                }
            </>
        </div>
    );
};
