import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import moment from 'moment/moment';
import {
    CreateChancel,
    Input,
    SelectAutocompleteMultiple,
    DateCalendarForm, DateTypeSelector,
} from 'components';
import {
    adminActions,
    clientActions,
    fundingSourceActions,
    httpRequestsOnSuccessActions,
    reportActions,
    systemActions,
} from 'store';
import {
    enumValues,
    FindLoad,
    useModal,
    FindSuccessItem,
    Images, DownloadFile,
} from 'utils';
import { authService } from '../../../../store/reports/reports.service';

export const GenerateReport = ({ info }) => {
    const dispatch = useDispatch();
    const { close } = useModal();
    const { handleSubmit, watch, control, setError, clearErrors, reset, setValue } = useForm();
    const { loadDownload, handleDownload } = DownloadFile();
    const [load, setLoad] = useState(false);
    const { clientList, fundingSourceList, adminsList } = useSelector((state) => ({
        clientList: state.client.clientList,
        fundingSourceList: state.fundingSource.fundingSourceList,
        adminsList: state.admins.adminsList,
    }));


    useEffect(() => {
        dispatch(clientActions.getClients());
        dispatch(fundingSourceActions.getFundingSource());
        dispatch(adminActions.getAdmins());
        reset({});
    }, [dispatch]);

    const handleGenerate = (data) => {
        setLoad(true);

        const params = {
            ...data,
        };
        params?.clientStatuses ? params.clientStatuses = params?.clientStatuses?.map((i) => i?.id) : null;
        params?.clientIds ? params.clientIds = params?.clientIds?.map((i) => i?.id) : null;

        try {
            authService.getReportService(info?.url, params).then((res) => {
                handleDownload({
                    ...res?.data,
                    originalName: `${res?.data?.originalName}${res?.data?.format}`,
                });
                close();
            }).catch(() => {
            }).finally(() => {
                setLoad(false);
            });
        } catch (e) {

        }
    };

    return (
        <div style={{ width: 440 }}>
            <p className="modal-header-title">{info?.title}</p>
            <form onSubmit={handleSubmit(handleGenerate)}>
                <div className="modal-body-wrapper">

                    {info?.list?.map((item, index) => (
                        <div key={index}>
                            {item?.type === 'text' ?
                                <Input
                                    name={item?.name}
                                    label={item?.label}
                                    variant="outlined"
                                    control={control}
                                    type={item?.inputType ? item?.inputType : 'text'}
                                />
                                :
                                item?.type === 'select' ?
                                    <SelectAutocompleteMultiple
                                        options={
                                            item?.name === 'clientIds' ? clientList?.clients :
                                                item?.name === 'client' ? clientList?.clients :
                                                    item?.name === 'payer' ? fundingSourceList?.funders :
                                                        item?.name === 'fundingSource' ? fundingSourceList?.funders :
                                                            item?.name === 'staff' ? adminsList?.staffs :
                                                                    item?.selectList
                                        }
                                        renderValue={(option) => item?.renderValue(option)}
                                        label={item?.label}
                                        name={item?.name}
                                        control={control}
                                        loadType={item?.loadType}
                                    />
                                    :
                                    item?.type === 'selectMultiple' ?
                                        <SelectAutocompleteMultiple
                                            loadType={item?.loadType}
                                            renderValue={(option) => item?.renderValue(option)}
                                            options={
                                                item?.name === 'clientIds' ? clientList?.clients :
                                                    item?.name === 'client' ? clientList?.clients :
                                                        item?.name === 'payer' ? fundingSourceList?.funders :
                                                            item?.name === 'fundingSource' ? fundingSourceList?.funders :
                                                                item?.name === 'staff' ? adminsList?.staffs :
                                                                        item?.selectList
                                            }
                                            label={item?.label}
                                            name={item?.name}
                                            control={control}
                                            selectedType="id"
                                            multiple={true}
                                        />
                                        :
                                        item?.type === 'dateRange' ?
                                            <div className="flex-align-start" style={{ gap: 16 }}>
                                                <DateCalendarForm
                                                    name="from"
                                                    label={'From'}
                                                    max={watch('to') ? moment.utc(watch('to')).format('YYYY-MM-DD') : ''}
                                                    control={control}
                                                />
                                                <DateCalendarForm
                                                    name="to"
                                                    label={'To'}
                                                    min={watch('from') ? moment.utc(watch('from')).format('YYYY-MM-DD') : ''}
                                                    control={control}
                                                />
                                            </div>
                                            :
                                            item?.type === 'date' ?
                                                <DateCalendarForm
                                                    name={item?.name}
                                                    label={item?.label}
                                                    control={control}
                                                />
                                                :
                                                item?.type === 'dos' ?
                                                    <DateTypeSelector
                                                        outLabel={item?.label}
                                                        filters={watch()}
                                                        type={'modalInput'}
                                                        handleGetDates={(e) => {
                                                            setValue(item?.startDate, e?.start);
                                                            setValue(item?.endDate, e?.end);
                                                        }}
                                                        startName={item?.startDate}
                                                        endName={item?.endDate}
                                                    />
                                                    :
                                                    <div />
                            }
                        </div>
                    ))}
                </div>

                <div style={{ marginTop: 16 }}>
                    <CreateChancel
                        image={Images.generateReportWhite}
                        type="submit"
                        loader={load || loadDownload}
                        create={'Generate'}
                        chancel={'Cancel'}
                        onClose={close}
                        buttonWidth="212px"
                        gap={'16px'}
                        cancelRight={'0'}
                    />
                </div>
            </form>
        </div>
    );
};