// import { initializeApp } from 'firebase/app';
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import axios from 'axios';
//
// var firebaseConfig = {
//     apiKey: "AIzaSyAryJkyoYgXd-dg7z164_7IPYFjxPLM6Cc",
//     authDomain: "therapylake-dd629.firebaseapp.com",
//     projectId: "therapylake-dd629",
//     storageBucket: "therapylake-dd629.appspot.com",
//     messagingSenderId: "556754511011",
//     appId: "1:556754511011:web:f645b17fb314f27fcc0b16",
//     measurementId: "G-V69QRHZGE3"
// };
// const firebaseApp = initializeApp(firebaseConfig);
// const messaging = getMessaging(firebaseApp);
//
// export const fetchToken = ( ) => {
//      getToken(messaging, {vapidKey: 'BK8ygbjLe3VDgguefoh6053YR-izBEZKF8Zu6uKKQRnEnI8tUEf75WPsVAwow6kfLsSQGHaxwJ3LcUEAJuOSDkY'}).then((currentToken) => {
//         if (currentToken) {
//             console.log('current token for client: ', currentToken);
//             axios.patch('/staff/pnToken', {token: currentToken}, { auth: true}).then((res) => {
//                 console.log(res.data);
//             }).catch((err) => {});
//
//             return currentToken
//             // Track the token -> client mapping, by sending to backend server
//             // show on the UI that permission is secured
//         } else {
//             console.log('No registration token available. Request permission to generate one.');
//             // shows on the UI that permission is required
//         }
//     }).catch((err) => {
//         console.log('An error occurred while retrieving token. ', err);
//         // catch error while creating client token
//     });
// }
//
//
// export const onMessageListener = () =>
//     new Promise((resolve) => {
//         onMessage(messaging, (payload) => {
//             resolve(payload);
//         });
//     });


// firebase.js
import { initializeApp } from 'https://www.gstatic.com/firebasejs/9.0.0/firebase-app.js';
import { getMessaging, getToken, onMessage } from 'https://www.gstatic.com/firebasejs/9.0.0/firebase-messaging.js';

const firebaseConfig = {
    apiKey: "AIzaSyA0ViM3EnVCk8uFespNR3lthZoHpWaCZo0",
    authDomain: "therapylake-edd6d.firebaseapp.com",
    projectId: "therapylake-edd6d",
    storageBucket: "therapylake-edd6d.appspot.com",
    messagingSenderId: "679990278879",
    appId: "1:679990278879:web:206ab9347d4f5c7651076a",
    measurementId: "G-WLMDMD76VD"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };
