import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment/moment';
import { BarChart, LineChart, pieArcLabelClasses, PieChart } from '@mui/x-charts';
import { BarChartData, ChartTypes, LineChartData } from './constants';
import { FindLoad, Images } from 'utils';
import { Loader } from 'components';
import { analyticActions } from 'store';
import { DefaultDates, ValueFormatter } from '../constants';

const ACTION_TYPE = 'GET_CHART_DATA';
export const ChartView = () => {
    const { chartData } = useSelector((state) => ({
        chartData: state.analytics.chartData,
    }));
    const dispatch = useDispatch();
    const load = FindLoad(ACTION_TYPE);
    const history = useHistory();
    const info = history?.location?.state;
    const activeChart = info?.activeChart;
    const { dates, current, prev } = BarChartData(info, chartData?.currentPeriod, chartData?.prevPeriod);
    const { lineChartDates } = LineChartData(info, chartData?.currentPeriod, chartData?.prevPeriod);
    const [chartType, setChartType] = useState();
    const { allDates } = DefaultDates(info);

    useEffect(() => {
        if (activeChart) {
            setChartType(activeChart?.chartTypes[0]);
            dispatch(analyticActions.getChartData(activeChart?.apiPath, allDates));
        }
    }, [activeChart]);

    const changeType = (type) => {
        setChartType(type);
    };

    if (load?.length) {
        return <Loader />;
    }

    return (
        <div className="bar-chart-box">

            <div className="chart-title-action-box">
                <div className="flex-align-center" style={{ gap: 20 }}>
                    <p className="chart-title">{activeChart?.name}</p>
                    {chartType !== ChartTypes.PIE &&
                        <div className="flex-align-center" style={{ gap: 20 }}>
                            <div className="previous-current-box">
                                <p>Previous</p>
                                <div style={{ background: chartType === ChartTypes.LINE ? '#6FD231' : '#8FB9F2' }} />
                            </div>
                            <div className="previous-current-box">
                                <p>Current</p>
                                <div style={{ background: '#0C66E4' }} />
                            </div>
                        </div>
                    }
                </div>

                <div className="chart-types-box">
                    {activeChart?.chartTypes?.map((item, j) => (
                        <button
                            style={activeChart?.chartTypes?.length === 1 ? { borderRadius: '4px' } : {}}
                            key={j}
                            className={chartType === item ? 'active-chart' : 'passive-chart'}
                            onClick={() => changeType(item)}
                        >
                            <img src={
                                item === ChartTypes.BAR ? Images.barChart :
                                    item === ChartTypes.LINE ? Images.lineChart :
                                        item === ChartTypes.PIE ? Images.barChart : null
                            } alt="icon" />
                        </button>
                    ))}
                </div>
            </div>

            {chartType === ChartTypes.BAR ?
                <BarChart
                    borderRadius={7}
                    height={400}
                    grid={{ vertical: true, horizontal: true }}
                    yAxis={[{ valueFormatter: (value) => ValueFormatter(value, activeChart?.valueType) }]}
                    xAxis={[{ scaleType: 'band', data: dates || [] }]}
                    series={[
                        {
                            label: 'Previous',
                            data: prev || [],
                            color: '#90CAF9',
                        },
                        {
                            label: 'Current',
                            data: current || [],
                            color: '#1976D2',
                        },
                    ]}
                />

                :

                chartType === ChartTypes.LINE ?
                    <div className="line-chat">
                        <LineChart
                            dataset={lineChartDates}
                            xAxis={[
                                {
                                    id: 'Years',
                                    dataKey: 'date',
                                    scaleType: 'time',
                                    valueFormatter: (date) => moment(date).format('MMM YY'),
                                    tickCount: lineChartDates?.length,
                                },
                            ]}
                            series={[
                                {
                                    id: 'Previous',
                                    label: 'Previous',
                                    dataKey: 'Previous',
                                    color: '#6FD231',
                                },
                                {
                                    id: 'Current',
                                    label: 'Current',
                                    dataKey: 'Current',
                                    color: '#0C66E4',
                                },
                            ]}
                            margin={{ left: 60, top: 10, right: 20 }}
                            height={400}
                            grid={{ vertical: true, horizontal: true }}
                        />
                    </div>

                    :

                    chartType === ChartTypes.PIE ?
                        <div className="pie-chart-box">
                            <PieChart
                                sx={{
                                    [`& .${pieArcLabelClasses.root}`]: {
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                    },
                                }}
                                series={[
                                    {
                                        outerRadius: 150,
                                        data: chartData ? chartData : [],
                                        highlightScope: { fade: 'global', highlight: 'item' },
                                        arcLabel: (item) => `${item.value ? item.value : ''}`,
                                        faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },

                                    },
                                ]}
                                height={400}
                                width={600}
                            />
                        </div>

                        :
                        null
            }
        </div>
    );
};
